import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { Text, ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';
import Add from '@mui/icons-material/Add';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { ButtonContainerTopRight, ButtonRemove } from './index.styled';

type Props = {
    elementId: string,
    elementName: string,
    parentDirection: Domain.Publisher.Direction,
    rotateMoveIcons: boolean,
    canMoveUp: boolean,
    canMoveDown: boolean,
    canAdd?: boolean,
    onRemoveElement: (elementId: string) => void,
    onMoveElement: (moveUp: boolean) => void,
    onAddElement?: () => void,
    verticalOffset?: number,
    hotizontalOffset?: number,
};

/**
 * Represents a UI component that renders the element actions near an element wrapper.
 */
export const ElementActions: React.FC<Props> = (props): JSX.Element => {
    const [showDeleteControl, setShowDeleteControl] = useState<boolean>(false);

    const getRemoveDialogElement = (showDeleteControl: boolean, onClose: () => void, onConfirm: () => void): JSX.Element => {
        if (!showDeleteControl) {
            return null;
        }

        return (
            <ModalDialog settings={{
                look: "interactive", title: "Element verwijderen", footer: (
                    <ModalDialogFooter
                        leftButtonText='Annuleren'
                        onLeftButtonClick={onClose}
                        rightButtonText='Verwijderen'
                        onRightButtonClick={onConfirm} />
                )
            }}>
                <Text value="U staat op het punt om een element te verwijderen. Als er content aan het element is toegevoegd zal deze verloren gaan. U kunt deze actie niet meer ongedaan maken."></Text>
            </ModalDialog>
        );
    };

    return (
        <>
            <ButtonContainerTopRight
                className='elementActions'
                direction={props.parentDirection}
                buttonCount={props.canAdd ? 4 : 3}
                verticalOffset={props.verticalOffset}
                hotizontalOffset={props.hotizontalOffset}
            >
                <ButtonRemove
                    id="btn-remove"
                    btnbase="iconbuttons"
                    btntype="medium_fabprimary"
                    icon={<DeleteOutlinedIcon />}
                    aria-label={`Verwijder element ${props.elementName}`}
                    onClick={(event) => {
                        event.stopPropagation();
                        setShowDeleteControl(true);
                    }}
                />
                <ButtonRemove
                    id="btn-move-up"
                    btnbase="iconbuttons"
                    btntype="medium_fabghost"
                    icon={props.parentDirection === Domain.Publisher.Direction.Horizontal || props.rotateMoveIcons ? <ArrowBack /> : <ArrowUpward />}
                    disabled={!props.canMoveUp}
                    aria-label={`Verplaats element ${props.elementName} omhoog`}
                    onClick={(event) => {
                        event.stopPropagation();
                        props.canMoveUp && props.onMoveElement(true);
                    }}
                />
                <ButtonRemove
                    id="btn-move-down"
                    btnbase="iconbuttons"
                    btntype="medium_fabghost"
                    icon={props.parentDirection === Domain.Publisher.Direction.Horizontal || props.rotateMoveIcons ? <ArrowForward /> : <ArrowDownward />}
                    disabled={!props.canMoveDown}
                    aria-label={`Verplaats element ${props.elementName} omlaag`}
                    onClick={(event) => {
                        event.stopPropagation();
                        props.canMoveDown && props.onMoveElement(false);
                    }}
                />
                {props.canAdd && props.onAddElement && <ButtonRemove
                    id="btn-add"
                    btnbase="iconbuttons"
                    btntype="medium_fabprimary"
                    icon={<Add />}
                    aria-label={`Nieuwe element ${props.elementName}`}
                    onClick={(event) => {
                        event.stopPropagation();
                        props.onAddElement();
                    }}
                />}
            </ButtonContainerTopRight>
            {getRemoveDialogElement(
                showDeleteControl,
                () => setShowDeleteControl(false),
                () => { setShowDeleteControl(false); props.onRemoveElement(props.elementId); })
            }
        </>
    );
};

