import React, { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Domain from '@liasincontrol/domain';
import { LsMenu } from '@liasincontrol/ui-devextreme';
import { palette } from '@liasincontrol/ui-basics';

type Props = {
    publicationElement: Domain.Publisher.PublicationElement;
    sitemap: Domain.Publisher.Sitemap;
    selectedElementId?: string,
}

/**
 * Represents UI component that renders the primary menu of a publication.
 */
const PrimaryMenuControl: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const onItemClick = useCallback((item) => {
        if (!!props.selectedElementId) {
            return;
        }

        const newPath = location.pathname.replace(/([^/]*)$/, item.id);
        navigate(newPath, { replace: true });
    }, [props.selectedElementId]);

    const selectedPageId = location.pathname.split("/").pop();

    if (!props.publicationElement || !props.sitemap) {
        return null;
    }

    const getMenuDataSource = (pages: Domain.Publisher.SitemapNode[]) => {
        if (pages.length === 0) {
            return [];
        }


        return pages.map(p => ({
            id: p.elementId,
            name: p.elementName,
            items: getMenuDataSource(p.children),
            selected: p.elementId === selectedPageId
        }));
    };

    const homePage = {
        id: props.sitemap.node?.elementId,
        icon: 'home',
        items: [],
        selected: props.sitemap.node?.elementId === selectedPageId
    };

    const dataSource = [homePage, ...getMenuDataSource(props.sitemap.node?.children)];

    const activeStyle: React.CSSProperties = {
        backgroundColor: props.publicationElement.primaryColor ? props.publicationElement.primaryColor : palette.grey4,
        color: props.publicationElement.primaryContrastColor ? props.publicationElement.primaryContrastColor : palette.grey1,
    };

    return (
        <div className="site-primary-menu" aria-label="Hoofdnavigatie">
            <div className="menu-item-list-container level-1" data-title="Programmaplan">
                <LsMenu
                    activeStyle={activeStyle}
                    dataSource={dataSource}
                    onItemClick={onItemClick}
                    showMode='onHover'
                />
            </div>
        </div>
    );
};

export default PrimaryMenuControl;
