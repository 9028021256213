import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

type IChartProps = {
    readonly data: [];
    readonly filterValue?: string | Array<any> | Function;
}

export const CreateDataSource = (props: IChartProps): DataSource => {
    const source = new DataSource({
        paginate: false,
        store: new CustomStore({
            load: () => props.data,
            loadMode: 'raw'
        }),
    });
    if (props.filterValue && props.filterValue !== null && props.filterValue.length > 0) {
        source.filter(props.filterValue);
    }
    return source;
};