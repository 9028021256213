import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, DoughnutGradient, ErrorOverlay, Heading2, IDataItemProps, PageTitle, ResetZIndex, Section, ThumbnailImg, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { MultiSelectElement, SelectElement } from '@liasincontrol/ui-elements';
import { Finance as DataAccess } from '@liasincontrol/data-service';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { FlattenedBudgetDetailsData } from './index.config';
import Styled from './index.styled';

/**
 * Represents a UI component that renders the overview page of the budgets.
 */
export const Overview: React.FC = () => {
    const [baseYears, setBaseYears] = useState<number[]>([]);
    const [structureNames, setStructureNames] = useState<string[]>([]);
    const [elementNames, setElementNames] = useState<string[]>([]);
    const [workflowStates, setWorfklowStates] = useState<IDataItemProps<string>[]>([]);
    const [filterTree, setFilterTree] = useState<Domain.Finance.BudgetOverviewFilterTree>();
    const [overviewFilterData, setOverviewFilterData] = useState<{ year: number, structureName: string, elementName: string, workflowStates: string[] }>(undefined);
    const [overviewData, setOverviewData] = useState<Domain.Finance.BudgetOverviewData[]>(null);
    const [detailsData, setDetailsData] = useState<FlattenedBudgetDetailsData[]>(null);
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);

    const [filterData, setFilterData] = useState<{
        year: number,
        structureName: string,
        elementName: string,
        workflowStates: IDataItemProps<string>[]
    }>({
        year: undefined,
        structureName: undefined,
        elementName: undefined,
        workflowStates: []
    });
    const [pending, setPending] = useState<{ overview: boolean, details: boolean }>({ overview: false, details: false });

    useEffect(() => {
        DataAccess.BudgetOverview.getFilters()
            .then((result) => {
                if (!result || !result.data || !result?.data?.filterValues || !result?.data?.workflowStates) {
                    return;
                }
                const filters = result?.data?.filterValues;
                const workflowStates = result?.data?.workflowStates;

                setFilterTree({ filterValues: filters, workflowStates: workflowStates });
                const workflowStateOptions = workflowStates.map(state => ({
                    disabled: false,
                    value: state,
                    label: state
                }))
                setWorfklowStates(workflowStateOptions);
                setBaseYears(Object.keys(filters).map(year => +year));
            })
            .catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err));
            });
    }, []);

    useEffect(() => {
        if (!filterTree || !filterTree.filterValues || !filterData.year) {
            return;
        }

        const structures = Object.entries(filterTree.filterValues).find(filter => filter[0] === filterData.year?.toString())[1];
        setStructureNames(Object.keys(structures));
    }, [filterTree, filterData.year]);

    useEffect(() => {
        if (!filterTree || !filterTree.filterValues || !filterData.year || !filterData.structureName) {
            return;
        }
        const structures = Object.entries(filterTree.filterValues).find(filter => filter[0] === filterData.year?.toString())[1];
        const elements = Object.entries(structures).find(filter => filter[0] === filterData.structureName)[1];
        setElementNames(Object.values(elements));
    }, [filterTree, filterData.structureName]);

    const onGetOverview = () => {
        if (!filterData.year || !filterData.structureName || !filterData.elementName || filterData.workflowStates.length === 0) {
            return;
        }

        const workflowStateNames = filterData.workflowStates.map(wfState => wfState.value);
        const filter = {
            year: +filterData.year,
            structureName: filterData.structureName,
            elementName: filterData.elementName,
            workflowStates: workflowStateNames
        };

        setOverviewFilterData(filter);
        setPending({ overview: true, details: false });
        setDetailsData(null);

        DataAccess.BudgetOverview.get(filterData.year, filterData.structureName, filterData.elementName, workflowStateNames)
            .then(result => {
                setPending({ overview: false, details: false });

                if (!result.data || result.data.length === 0) {
                    setOverviewData([]);
                    return;
                }

                const rows: Domain.Finance.BudgetOverviewData[] = _.clone(result.data);
                const index1 = _.max([rows.indexOf(rows.find(row => row.recordName === 'Lasten')), rows.indexOf(rows.find(row => row.recordName === 'Baten'))]) as number + 1;
                const index2 = _.max([rows.indexOf(rows.find(row => row.recordName === 'Toevoegingen')), rows.indexOf(rows.find(row => row.recordName === 'Onttrekkingen'))]) as number + 2;

                const newRow1: Domain.Finance.BudgetOverviewData = getSummaryRow(rows, 0);
                rows.splice(index1, 0, { ...newRow1 });

                if (index2 > 1) {
                    const newRow2: Domain.Finance.BudgetOverviewData = getSummaryRow(rows, 1);
                    rows.splice(index2, 0, { ...newRow2 });
                }
                setOverviewData(rows);
            })
            .catch((err) => {
                setPending({ overview: false, details: false });
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, false));

                setOverviewData(null);
            });

    };

    const onDetailsButtonClick = () => {
        setPending({ overview: false, details: true });
        DataAccess.BudgetOverview.getDetails(overviewFilterData.year, overviewFilterData.structureName, overviewFilterData.elementName, overviewFilterData.workflowStates)
            .then(result => {
                setPending({ overview: false, details: false });
                if (!result.data) {
                    setDetailsData(null);
                    return;
                }
                const data = mapBudgetDetailsData(result.data);
                setDetailsData(data);
            })
            .catch((err) => {
                setPending({ overview: false, details: false });
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err));
                setDetailsData(null);
            });
    };

    const getSelectionFormElement = () => {
        const disabledSubmitButton = pending.overview || !filterData.year || !filterData.structureName || !filterData.elementName || filterData.workflowStates.length === 0;

        return (
            <Section look="white">
                <Styled.FormGrid>
                    <Styled.FormInput>
                        <SelectElement<number>
                            id='base-year-select'
                            label='Basisjaar'
                            placeholder='Kies...'
                            optionItems={baseYears}
                            value={filterData.year}
                            clearable={false}
                            searchable={true}
                            editorSettings={{
                                disabled: baseYears.length <= 0,
                                restrictions: { required: false },
                                validationErrors: [],
                                onChange: (val) => setFilterData(prev => ({ ...prev, year: val, structureName: null, elementName: null }))
                            }}
                        />
                    </Styled.FormInput>
                    <Styled.FormInput>
                        <SelectElement<string>
                            id='structure-select'
                            label='Structuur'
                            placeholder='Kies...'
                            optionItems={structureNames}
                            value={filterData.structureName}
                            clearable={false}
                            searchable={true}
                            editorSettings={{
                                disabled: structureNames.length <= 0,
                                restrictions: { required: false },
                                validationErrors: [],
                                onChange: (val) => setFilterData(prev => ({ ...prev, structureName: val, elementName: null }))
                            }}
                        />
                    </Styled.FormInput>
                    <Styled.FormInput>
                        <SelectElement<string>
                            id='element-name-select'
                            label='Onderdeel'
                            placeholder='Kies...'
                            optionItems={elementNames}
                            value={filterData.elementName}
                            clearable={false}
                            searchable={true}
                            editorSettings={{
                                disabled: elementNames.length <= 0,
                                restrictions: { required: false },
                                validationErrors: [],
                                onChange: (val) => setFilterData(prev => ({ ...prev, elementName: val }))
                            }}
                        />
                    </Styled.FormInput>
                    <Styled.FormInput>
                        <MultiSelectElement
                            id='select-004'
                            label='Status'
                            placeholder='Kies...'
                            editorSettings={{
                                disabled: false,
                                validationErrors: undefined,
                                restrictions: undefined,
                                onChange: (selectedOption) => setFilterData({ ...filterData, workflowStates: selectedOption as IDataItemProps<string>[] }),
                            }}
                            searchable={false}
                            clearable={true}
                            optionItems={workflowStates ? workflowStates : []}
                            value={filterData.workflowStates ? filterData.workflowStates : []}
                        />
                    </Styled.FormInput>
                    <Styled.FormButton>
                        <Button
                            id='btn-get-budget-overview-data'
                            disabled={disabledSubmitButton}
                            btnbase={disabledSubmitButton ? 'ghostbuttons' : 'primarybuttons'}
                            btntype='medium_noicon'
                            onClick={onGetOverview}
                        >
                            Ophalen
                        </Button>
                    </Styled.FormButton>
                </Styled.FormGrid>
            </Section>
        );
    };

    const getOverviewTableElement = () => {
        if (!overviewData?.length)
            return null;
        return (
            <Styled.GridWrapper>
                <LsGrid
                    dataSource={overviewData}
                    columns={getOverviewTableColumns(overviewFilterData.year)}
                    enableColumnChooser={false}
                    showRowLines={true}
                    showBorders={true}
                    showColumnLines={true}
                    allowColumnResizing={true}
                    focusedRowKeys={[summaryRowNames[0], summaryRowNames[1]]}
                />
            </Styled.GridWrapper>
        );
    };

    const getDoughnutElements = () => {
        const rows = [
            overviewData.find(cell => cell.recordName === 'Lasten'),
            overviewData.find(cell => cell.recordName === 'Baten'),
        ];
        const doughnutElements = rows.map(data => {
            if (!data) {
                return null;
            }
            return (
                <DoughnutGradient
                    key={data?.recordName || 'no name'}
                    percentage={parseFloat(data?.currentBudgetExhausted?.toFixed(2))}
                    title={data?.recordName || ''}
                />
            );
        });

        return (

            <Styled.DoughnutWrapper>
                {doughnutElements}
            </Styled.DoughnutWrapper>
        );
    };

    const getNoDataThumbnailElement = (pending: boolean, overviewData: Domain.Finance.BudgetOverviewData[]) => {
        const noResults = overviewData && overviewData.length === 0;

        return (
            <Styled.NoDataContainer>
                <Styled.ThumbnailWrapper><ThumbnailImg variant="undrawFileSearching" /></Styled.ThumbnailWrapper>
                <Styled.NoDataContent>
                    {pending ? 'Data wordt geladen' : noResults ? 'Geen resultaat' : 'Voer een zoekopdracht uit om het budgetoverzicht te raadplegen'}
                </Styled.NoDataContent>
            </Styled.NoDataContainer>
        );
    };

    const getOverviewElement = () => {
        let overviewContent;
        if (!overviewFilterData || !overviewData?.length)
            overviewContent = getNoDataThumbnailElement(pending.overview, overviewData);
        else
            overviewContent = (
                <>
                    {getOverviewTableElement()}
                    {getDoughnutElements()}
                </>
            );

        return (
            <Section look="white" fixedWidth={true}>
                <ResetZIndex>
                    <Styled.Overview>
                        {overviewContent}
                    </Styled.Overview>
                </ResetZIndex>
            </Section>
        );
    };

    const getDetailsElement = () => {
        if (!detailsData?.length && !!overviewData?.length) {
            const noResult = detailsData && detailsData.length === 0;

            return (
                <Section look="white" fixedWidth={true}>
                    <Styled.GetDetailsWrapper>
                        <Styled.GetDetailsText>
                            {pending.details ? 'Data wordt geladen' : noResult ? 'Geen resultaat' : 'Bekijk details op boekingscombinatie niveau'}
                        </Styled.GetDetailsText>
                        <Button
                            id='btn-get-budget-details-data'
                            btnbase='primarybuttons'
                            btntype='medium_noicon'
                            disabled={pending.details}
                            onClick={onDetailsButtonClick}
                        >
                            Details Ophalen
                        </Button>
                    </Styled.GetDetailsWrapper>
                </Section>
            );
        }
        if (!!detailsData?.length) {
            return (
                <Section look="white">
                    <Styled.Details>
                        <LsGrid
                            keyExpr={['code1', 'code2']}
                            dataSource={detailsData}
                            columns={getDetailsTableColumns(overviewFilterData.year, detailsData[0])}
                            enableColumnChooser={true}
                            buttonColumnId='code1-code2'
                            showRowLines={true}
                            showBorders={true}
                            showColumnLines={true}
                            allowColumnResizing={true}
                            searching={true}
                            export={false}
                        />
                    </Styled.Details>
                </Section>
            );
        }

        return null;
    };

    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Budgetoverzicht</Heading2>
                </PageTitle>
                <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    {getSelectionFormElement()}
                    {getOverviewElement()}
                    {getDetailsElement()}
                </ErrorOverlay>
            </WrapperContent>
        </Wrapper>
    );
};

const getSummaryRow = (data, index) => {
    let rows = [];
    if (index === 0) {
        rows = [
            data.find(cell => cell.recordName === 'Lasten'),
            data.find(cell => cell.recordName === 'Baten'),
        ];
    }
    else {
        rows = data.filter(item => item.recordName !== summaryRowNames[0]);
    }
    const newRow = new Domain.Finance.BudgetOverviewData();

    rows.forEach(row => {
        if (!row) return;
        Object.keys(row).forEach(column => {
            if (column === 'recordType' || column === 'currentBudgetRemaining' || column === 'currentBudgetExhausted')
                return;
            if (column === 'recordName')
                newRow[column] = summaryRowNames[index];
            else
                newRow[column] = (newRow[column] as number) + (row[column] as number) || row[column];
        });
    });

    return newRow;
}

const getOverviewTableColumns = (currentYear: number): GridColumn<Domain.Finance.BudgetOverviewData>[] => {
    const yearAsNumber = parseInt(currentYear.toString());
    return [
        {
            name: 'recordName',
            title: '',
            width: 220,
            allowSorting: false,
        },
        {
            title: 'Voorgaande jaren',
            columns: [
                {
                    name: 'pastYear2Realization',
                    title: `Realisatie ${yearAsNumber - 2}`,
                    width: 150,
                    allowSorting: false,
                },
                {
                    name: 'pastYear1Budget',
                    title: `Begroting ${yearAsNumber - 1}`,
                    width: 150,
                    allowSorting: false,
                }
            ],
        },
        {
            title: 'Huidig jaar',
            columns: [
                {
                    name: 'currentActualBudget',
                    title: 'Begroting',
                    width: 100,
                    allowSorting: false,
                },
                {
                    name: 'currentRealization',
                    title: 'Realisatie',
                    width: 100,
                    allowSorting: false,
                },
                {
                    name: 'currentObligations',
                    title: 'Verplichtlingen',
                    width: 100,
                    allowSorting: false,
                },
                {
                    name: 'currentBudgetRemaining',
                    title: 'Restant',
                    width: 100,
                    allowSorting: false,
                },
                {
                    name: 'currentBudgetExhausted',
                    title: 'Verbruikt %',
                    width: 100,
                    allowSorting: false,
                },
            ]
        },
        {
            title: 'Komende jaren',
            columns: [
                {
                    name: 'futureYear1Budget',
                    title: `${yearAsNumber + 1}`,
                    width: 75,
                    allowSorting: false,
                },
                {
                    name: 'futureYear2Budget',
                    title: `${yearAsNumber + 2}`,
                    width: 75,
                    allowSorting: false,
                },
                {
                    name: 'futureYear3Budget',
                    title: `${yearAsNumber + 3}`,
                    width: 75,
                    allowSorting: false,
                },
                {
                    name: 'futureYear4Budget',
                    title: `${yearAsNumber + 4}`,
                    width: 75,
                    allowSorting: false,
                },
            ]
        }];

};

const getDetailsTableColumns = (currentYear: number, detailSample: FlattenedBudgetDetailsData): GridColumn<FlattenedBudgetDetailsData>[] => {
    const yearAsNumber = parseInt(currentYear.toString());

    const structureElementsColumns = Object.entries(detailSample)
        .filter(obj => obj[0].includes('kind') && !!obj[1])
        .map((obj, index) => ([
            { name: `code${index + 1}`, title: `${obj[1]} Code`, allowSorting: false, dataType: 'string', align: 'right' } as GridColumn<FlattenedBudgetDetailsData>,
            { name: `name${index + 1}`, title: `${obj[1]} Omschrijving`, allowSorting: false, dataType: 'string', align: 'right' } as GridColumn<FlattenedBudgetDetailsData>,
        ]));

    const correctcolumns: GridColumn<FlattenedBudgetDetailsData>[] = [{ name: 'accountClass', title: 'Soort', allowSorting: false, }];

    structureElementsColumns.forEach(structureElement => {
        correctcolumns.unshift(structureElement[1]);
        correctcolumns.unshift(structureElement[0]);
    });

    const columns: GridColumn<FlattenedBudgetDetailsData>[] = [
        {
            title: 'Boekingscombinatie',
            columns: correctcolumns,
        },
        {
            title: 'Voorgaande jaren',
            columns: [
                {
                    name: 'pastYear2Realisation',
                    title: `Rekening ${yearAsNumber - 2}`,
                    allowSorting: false,
                },
                {
                    name: 'pastYear1Budget',
                    title: `Begroting ${yearAsNumber - 1}`,
                    allowSorting: false,
                },
            ]
        },
        {
            title: 'Huidige jaar',
            columns: [
                {
                    name: 'currentActualBudget',
                    title: 'Begroting',
                    allowSorting: false,
                },
                {
                    name: 'currentRealization',
                    title: 'Realisatie',
                    allowSorting: false,
                },
                {
                    name: 'currentObligations',
                    title: 'Verplichting',
                    allowSorting: false,
                },
                {
                    name: 'currentBudgetRemaining',
                    title: 'Restant budget',
                    allowSorting: false,
                },
                {
                    name: 'currentBudgetExhausted',
                    title: 'Verbruikt %',
                    allowSorting: false,
                },
            ]
        },
        {
            title: 'Komende jaren',
            columns: [
                {
                    name: 'futureYear1Budget',
                    title: `Begroting ${yearAsNumber + 1}`,
                    allowSorting: false,
                },
                {
                    name: 'futureYear2Budget',
                    title: `Begroting ${yearAsNumber + 2}`,
                    allowSorting: false,
                },
                {
                    name: 'futureYear3Budget',
                    title: `Begroting ${yearAsNumber + 3}`,
                    allowSorting: false,
                },
                {
                    name: 'futureYear4Budget',
                    title: `Begroting ${yearAsNumber + 4}`,
                    allowSorting: false,
                }
            ]
        }
    ];

    return columns;
};

// const onExporting = (workbook) => {
//     workbook.xlsx.writeBuffer().then((buffer) => {
//         saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Budgetoverzicht.xlsx');
//     });
// };

const summaryRowNames = [
    'Saldo voor resultaatbestemming',
    'Saldo na resultaatbestemming',
];

const mapBudgetDetailsData = (budgetDetails: Domain.Finance.BudgetDetailData[]): FlattenedBudgetDetailsData[] => (
    budgetDetails.map(row => {
        const budgetDetailRow = new FlattenedBudgetDetailsData();
        Object.entries(row).forEach(cell => {
            if (cell[0] === 'structureElements') {
                for (let i = 0; i <= 7; i++) {
                    if (!cell[1][i]) break;
                    budgetDetailRow[`code${i + 1}`] = cell[1][i].code;
                    budgetDetailRow[`name${i + 1}`] = cell[1][i].name;
                    budgetDetailRow[`kind${i + 1}`] = cell[1][i].kind;
                }
            }
            else
                budgetDetailRow[cell[0]] = cell[1];
        });
        return budgetDetailRow;
    })
);

export { Overview as index };
