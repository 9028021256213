import { px } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

const DialogContainer = styled.div`
    position: relative;
    margin: ${px(-40)} ${px(-40)} ${px(-40)} ${px(-40)};
    border-radius: ${px(6)};
    overflow-y: auto;
    padding: ${px(48)} ${px(56)} ${px(48)} ${px(56)};
    max-height: 60vh;
`;
export { DialogContainer };
