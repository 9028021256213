import styled from 'styled-components';
import { px, palette, cssFontBold, SmallText } from '@liasincontrol/ui-basics';

interface IPresentation {
    readonly isDesigner?: boolean;
    readonly isValid?: boolean;
    readonly padding: boolean;
    readonly selected: boolean;
    readonly background: boolean;
    readonly hover?: boolean;
    readonly disabled?: boolean;
    readonly isLocked?: boolean;
}

const Wrapper = styled.div`
    border: 1px solid transparent;
    transition: outline 0.15s ease;
    padding: ${(p: IPresentation) => (p.padding ? px(12) : null)};
    background: ${(p: IPresentation) => (p.selected && p.background ? palette.primary2semitransparent : null)};    
    position: relative;
    cursor: pointer;
    line-height: initial;
`;

const Outline = styled.div<IPresentation>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
      
    border: ${(p: IPresentation) => (p.isDesigner ? `1px solid ${(p.selected && p.isValid) ? palette.primary2 : (p.hover ? palette.grey2 : !p.isValid ? palette.validationNeg : null )}` : null)};
    border: ${(p: IPresentation) => (!p.isDesigner ? `1px solid ${p.isLocked ? palette.darkOrange : !p.isValid && p.selected ? palette.validationNeg : palette.grey2}` : null)};
    
    border-radius: 0 ${px(4)} ${px(4)} ${px(4)};
    transition: border-color 0.1s, opacity 0.2s 0.1s;  
    pointer-events: ${(p: IPresentation) => (p.selected ? 'none' : null)};
`;

const CaptionWrapper = styled.div`
    ${cssFontBold(11, 13, 100)}
    font-family: Nunito;
    position: absolute;
    display: flex;
    left: -1px; /* wrapper border */
    top: 0;
    transform: translateY(-100%);
    color: ${palette.white};
    text-transform: uppercase;
`;

interface ICaption {
    readonly selected: boolean;
    readonly isDesigner: boolean;
    readonly isValid?: boolean;
    readonly icon: boolean;
    readonly marginLeft: boolean;
    readonly editable: boolean;
    readonly isLocked: boolean;
}

const Caption = styled.span<ICaption>`
    display: inline-block;
    height: ${(p) => (p.isDesigner ? `${px(19)}` : `${px(16)}`)};
    padding: ${(p) => (p.isDesigner ? `${px(4)} ${px(6)}` : (p.icon ? `${px(2)}` : `${px(2)} ${px(4)}`))};
    margin-left: ${(p) => (p.marginLeft ? `${px(4)}` : null)};
    background: ${palette.grey2};
    transition: background-color 0.1s;
    
    background: ${(p) => (p.editable ? (p.selected || p.icon ? palette.primary2 : null) : palette.grey3)};
    background: ${(p) => (!p.isDesigner && p.selected && !p.isValid ? palette.validationNeg : null)};
    background: ${(p) => (!p.isDesigner && p.isLocked ? palette.darkOrange : null)};

    border-radius: ${px(2)} ${px(2)} 0 0;
`;

const LockedText = styled(SmallText)`
    margin-left: ${px(4)};
    color: ${palette.grey3};
`;


export { Wrapper, Outline, CaptionWrapper, Caption, LockedText }