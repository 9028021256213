import React, { useEffect, useMemo, useState } from 'react';
import { InputField } from '@liasincontrol/ui-devextreme';
import { TextProps } from './index.props';
import { getFeedback, getValidationErrorsNode } from '../../shared/validationHelper';
import _ from 'lodash';

/**
 * Represents a UI component that renders a text editor.
 */
export const TextEditor: React.FC<TextProps> = (props) => {
    const [value, setValue] = useState<string>(props.value ? props.value : '');
    const onChangedDebounced = useMemo(() => _.debounce(props.editorSettings.onChange, 300), [props.editorSettings.onChange]);

    useEffect(() => {
        if ((props.value || '') === value) {
            return onChangedDebounced.cancel;
        }

        onChangedDebounced(value);
        return onChangedDebounced.cancel;
    }, [value, onChangedDebounced, props.value]);

    useEffect(() => setValue(props.value ? props.value : ''), [props.value]);

    return (
        <InputField
            id={`input-${props.id}`}
            key={props.id}
            label={props.label}
            disabled={props.editorSettings.disabled}
            maxLength={props.editorSettings.restrictions?.maxLength}
            error={getValidationErrorsNode(props.editorSettings.validationErrors)}
            feedback={getFeedback(value, props.editorSettings.restrictions)}
            value={value}
            helpText={props.helpText}
            mandatory={props.editorSettings.restrictions?.required}
            onChange={setValue}
        />
    );
};
