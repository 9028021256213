import React, { useMemo, useRef, useState } from 'react';
import {
    TreeList,
    Column,
    ITreeListOptions,
    IColumnProps,
    Toolbar,
    Item,
} from 'devextreme-react/tree-list';
import * as Domain from '@liasincontrol/domain';
import { IconSize } from '@liasincontrol/ui-basics';
import { groupCellRender, GroupRenderModeProps } from '../../helpers/renderModes';
import { CustomProps } from '@liasincontrol/ui-devextreme';
import { formatNumber } from 'devextreme/localization';

export type LsTreeListProps = ITreeListOptions & {
    readonly icons?: Record<string, Domain.Shared.SvgIcon>,
    readonly iconSize?: IconSize,
    readonly showExpandCollapse?: boolean,
}

export type LsTreeColumnProps = IColumnProps & GroupRenderModeProps & CustomProps;

export const LsTreeList = (props) => {
    const { columns, ...restProps } = props;
    const [expanded, setExpanded] = useState(false);
    const treeRef = useRef(null);

    const buttonOptions = useMemo(() => {
        return {
            text: (expanded ? 'ALLES INKLAPPEN' : 'ALLES UITKLAPPEN'),
            stylingMode: 'text',
            onClick: (e) => {
                e.event?.stopPropagation();
                setExpanded(prev => !prev);

                treeRef.current.instance.option("expandedRowKeys", []);
                treeRef.current.instance.option("autoExpandAll", !expanded);
            },
        };
    }, [expanded]);

    return <>
        <TreeList {...restProps}
            ref={treeRef}
            searchPanel={{
                visible: true,
                highlightCaseSensitive: false,
                searchVisibleColumnsOnly: true,
                placeholder: 'Zoeken...',
            }}
        >
            <Toolbar visible={true}>
                <Item visible={props.showExpandCollapse} widget="dxButton" options={buttonOptions} />
                <Item name="searchPanel" locateInMenu="auto" location="after" />
            </Toolbar>
            {columns?.map((column) => {
                const mappedColumn = column as LsTreeColumnProps;
                return <Column
                    cellRender={mappedColumn.groupRenderMode ? ((params) => groupCellRender(params.data, column, props.icons, props.iconSize)) : undefined}
                    key={mappedColumn.dataField}
                    {...mappedColumn}
                    minWidth={64}
                    encodeHtml={false}
                    calculateSortValue={mappedColumn.groupRenderMode ? (rowData) => {
                        const sortValue = rowData[`${mappedColumn.propertyGroupName}Name`];
                        return sortValue;
                    } : undefined}
                    // DevExtreme: Do not use this (aka calculateDisplayValue) property to format text in cells. 
                    // Instead, use the format, customizeText, or cellTemplate property.
                    customizeText={mappedColumn.divide && mappedColumn.divide > 1 ? (cellInfo) => {
                        if (!cellInfo.value || !mappedColumn?.divide) {
                            return cellInfo.value?.toString() || '';
                        }

                        if (mappedColumn.divide > 1) {
                            const newValue = Math.round(cellInfo.value / mappedColumn.divide);
                            return (mappedColumn.format ? formatNumber(newValue, mappedColumn.format) : newValue.toString());
                        }
                        return cellInfo.valueText.toString();
                    } : undefined}
                    calculateCellValue={mappedColumn.groupRenderMode
                        ? (rowData) => {
                            const cellValue = rowData[`${mappedColumn.propertyGroupName}Name`];
                            return cellValue;
                        } : undefined
                    }
                />;
            })}
        </TreeList>
    </>
};
