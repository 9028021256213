import React, { useMemo } from 'react';
import HtmlEditor, { Item, TableResizing, Toolbar } from 'devextreme-react/html-editor';
import Styled from './index.style';
import styled from 'styled-components';
import { ContentReadyEvent } from 'devextreme/ui/html_editor_types';

type Props = {
    readonly id: string,
    readonly value: string,
    readonly isToolbarHidden?: boolean,
    readonly className?: string,
    readonly style?: React.CSSProperties,
    readonly locale?: string,
    readonly height?: string | number,
    readonly onChange: (value: string) => void,
    readonly onFocusIn?: ((e: {}) => any),
    readonly onFocusOut?: ((e: {}) => any),
    readonly onContentReady?: ((e: ContentReadyEvent) => any),
    readonly withTextAssistant?: boolean,
    readonly onTextAssistant?: () => void,
    readonly canMaximize?: boolean,
    readonly theme?: 'light' | 'dark',
}

/**
 * Represents a UI component that renders a richtext editor.
 */
export const RichTextEditor: React.FC<Props> = (props) => {
    const textAssistantOptions = useMemo(() => {
        return {
            text: 'Tekstassistent',
            icon: `<svg version="1.1" id="Capa_1"
                       xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                       <g>
                           <path style="fill:none;stroke:rgba(0,0,0,.87);stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" d="M127.467 432.733H111.4c-35.47 0-64.267-28.8-64.267-64.267 0-26.598 21.598-48.2 48.2-48.2H79.267C43.797 320.267 15 291.467 15 256s28.797-64.267 64.267-64.267h16.067c-26.602 0-48.2-21.602-48.2-48.2 0-35.467 28.797-64.267 64.267-64.267h16.067M384.533 432.733H400.6c35.47 0 64.267-28.8 64.267-64.267 0-26.598-21.598-48.2-48.2-48.2h16.067c35.47 0 64.267-28.8 64.267-64.267s-28.797-64.267-64.267-64.267h-16.067c26.602 0 48.2-21.602 48.2-48.2 0-35.467-28.797-64.267-64.267-64.267h-16.067"/>
                           <path style="fill:none;stroke:rgba(0,0,0,.87);stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" d="M159.6 111.4c-17.735 0-32.133-14.396-32.133-32.133C127.467 43.8 156.264 15 191.733 15S256 43.8 256 79.267C256 43.8 284.797 15 320.267 15s64.267 28.8 64.267 64.267c0 17.738-14.398 32.133-32.133 32.133M159.6 400.6c-17.735 0-32.133 14.396-32.133 32.133 0 35.467 28.797 64.267 64.267 64.267S256 468.2 256 432.733C256 468.2 284.797 497 320.267 497s64.267-28.8 64.267-64.267c0-17.738-14.398-32.133-32.133-32.133M175.667 304.2v-64.267a32.118 32.118 0 0 1 9.412-22.718A32.106 32.106 0 0 1 207.8 207.8a32.103 32.103 0 0 1 22.721 9.415 32.118 32.118 0 0 1 9.412 22.718V304.2M175.667 272.067h64.266M288.133 207.8h48.2M288.133 304.2h48.2M312.233 207.8v96.4M256 79.267v64.266M256 432.733v-64.266"/>
                       </g>
                   </svg>`,
            //TODO: Fix error when trying to use function instead of hardcoded string
            //icon: textAssistantSvgIconStringWithColor(fillColor),
            stylingMode: 'text',
            hint: 'Tekstassistent',
            onClick: props.onTextAssistant,
        };
    }, [props.onTextAssistant, props.theme]);

    return (
        <Styled.Wrapper className={props.className} style={props.style}>
            <HtmlEditor
                className='htmlEditor'
                value={props.value}
                defaultValue=''
                height={props.height}
                allowSoftLineBreak={true}
                onFocusIn={props.onFocusIn}
                onFocusOut={props.onFocusOut}
                onValueChanged={(element) => props.onChange(element.value)}
                onContentReady={props.onContentReady}
                onInitialized={(e) => {
                    const toolbar = e.component?.get("modules/toolbar");
                    class extendedToolbar extends toolbar {
                        get toolbarConfig() {
                            return {
                                dataSource: this._prepareToolbarItems(),
                                disabled: this.isInteractionDisabled,
                                menuContainer: "body",
                                multiline: this.isMultilineMode()
                            };
                        }
                    }
                    e.component?.register({ "modules/toolbar": extendedToolbar });
                }}
            >
                <TableResizing enabled={false} />
                <StyledToolbar multiline={false} >
                    <Item name='undo' />
                    <Item name='redo' />
                    <Item name='bold' />
                    <Item name='italic' />
                    <Item name='underline' />
                    <Item name='link' />
                    <Item name='separator' />
                    <Item name='orderedList' />
                    <Item name='bulletList' />
                    <Item name='separator' />
                    <Item name='color' />
                    <Item name='background' />
                    <Item name='separator' />
                    <Item name='alignLeft' />
                    <Item name='alignCenter' />
                    <Item name='alignRight' />
                    <Item name='separator' />
                    <Item name='clear' />
                    <Item name='separator' />
                    <Item name='insertTable' />
                    <Item name='insertHeaderRow' />
                    <Item name='deleteTable' />
                    <Item name='insertRowAbove' />
                    <Item name='insertRowBelow' />
                    <Item name='deleteRow' />
                    <Item name='insertColumnLeft' />
                    <Item name='insertColumnRight' />
                    <Item name='deleteColumn' />
                    <Item name='separator' visible={!!props.withTextAssistant} />
                    <Item name='variable' options={textAssistantOptions} visible={!!props.withTextAssistant} />
                </StyledToolbar>
            </HtmlEditor>
        </Styled.Wrapper>
    );
};

const StyledToolbar = styled(Toolbar)`
    .dx-scrollable-container {
        height: unset !important;
    }

    .dx-scrollable-scroll-content {
        height: unset !important;
    }

    .dx-toolbar-menu-section {
        overflow: auto;
    }   
`;
