import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Dictionary } from 'lodash';
import { DefinitionsHelper, FieldsHelper } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { palette, ImageFocusViewer, SVGIcon, IconSize } from '@liasincontrol/ui-basics';
import Styled from './index.styled';
import SharedStyled from '../../SharedControlElements/index.styled';
import _ from 'lodash';

type Props = {
    readonly: boolean,
    publicationElement: Domain.Publisher.PublicationElement,
    selectedElementId: string,
    element: Domain.Publisher.ElementNode,
    elementList: Dictionary<Domain.Publisher.Element>,
    sitemap: Domain.Publisher.Sitemap,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onLoadAttachment: (id: string, names?: Record<string, string>) => Promise<Blob>,
    icons?: Record<string, Domain.Shared.SvgIcon>,
};

type TileData = {
    page: string,
    //from settings
    description?: string,
    imageUrl?: string,
    imageFocusPoint?: string,
    //from sitemap
    pageImageUrl?: string,
    pageImageFocalPoint?: string,
    pageDescription?: string,
};

const DEFAULT_FOCUS_VALUE = '0.5, 0.5, 1';

enum TileImageKind {
    None = 0,
    Image = 1,
    Icon = 2
}

/**
 * Represents UI component that renders a tile menu control.
 */
const TileMenuControl: React.FC<Props> = (props) => {
    const { pathname } = useLocation();
    const { pageid } = useParams<{ id: string, pageid?: string }>();
    const [tiles, setTiles] = useState<TileData[]>([]);

    const tileMenuElement = props.elementList[props.element.elementId];

    const currentElement: { data: Domain.Publisher.TileMenuControl, definition: Domain.Shared.ElementDefinition } = useMemo(() => {
        if (!tileMenuElement) return;
        const controlSettings = DefinitionsHelper.getControlSettings(tileMenuElement, new Domain.Publisher.TileMenuControl(), props.getElementDefinition);
        const definition = props.getElementDefinition(tileMenuElement.elementDefinitionSystemId, tileMenuElement.elementDefinitionId);
        return {
            data: controlSettings,
            definition
        };
    }, [tileMenuElement]);

    useEffect(() => {
        if (!currentElement || !currentElement.definition) {
            return;
        }

        async function fetchTiles(complexFieldDefinitionId: string,
            tileDescriptionDefinitionId: string,
            tileImageDefinitionId: string,
            tilePageDefinitionId: string,
            tileFocusPointDefinitionId: string) {
            //fetch tiles from old settings
            const tiles = Object.values(tileMenuElement.complexFields)
                .filter((complexField) => Object.keys(complexField.fields).length > 0 && complexField.complexFieldDefinitionId === complexFieldDefinitionId)
                .map(async (complexField) => {
                    const imageSource = complexField.fields[tileImageDefinitionId];
                    const imageAttachment = tileMenuElement.attachments?.find(a => a.id === imageSource);
                    const imageFileName = imageAttachment ? `${imageAttachment.name}.${imageAttachment.fileExtension}` : '';
                    const description = complexField.fields[tileDescriptionDefinitionId];
                    const page = complexField.fields[tilePageDefinitionId];
                    const focusPoint = complexField.fields[tileFocusPointDefinitionId];
                    const fileBlob = await props.onLoadAttachment(imageSource, { [imageSource]: imageFileName });

                    return ({
                        imageUrl: fileBlob ? URL.createObjectURL(fileBlob) : null,
                        description: description,
                        page: page,
                        imageFocusPoint: focusPoint,
                    });
                });

            return Promise.allSettled(tiles);
        }


        const tilesComplexFieldDefinition = currentElement.definition.complexFields.find((complexField) => complexField.systemId === Domain.SystemFieldDefinitions.Pub.TilesComplex);
        const tileDescriptionDefinitionId = tilesComplexFieldDefinition.fields.find((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.TileDescription).id;
        const tileImageDefinitionId = tilesComplexFieldDefinition.fields.find((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.TileImage).id;
        const tilePageDefinitionId = tilesComplexFieldDefinition.fields.find((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.TilePage).id;
        const tileFocusPointDefinitionId = tilesComplexFieldDefinition.fields.find((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.TileFocusPoint).id;

        fetchTiles(tilesComplexFieldDefinition.id, tileDescriptionDefinitionId, tileImageDefinitionId, tilePageDefinitionId, tileFocusPointDefinitionId)
            .then((response) => {
                const tiles = response.reduce<TileData[]>((acc, curr) => {
                    if (curr.status === 'fulfilled') {
                        acc.push(curr.value);
                    }
                    return acc;
                }, []);
                setTiles((prev) => mergeArrays(prev, tiles, 'page'));
            });

    }, [currentElement, tileMenuElement, props.onLoadAttachment])

    useEffect(() => {
        if (!props.sitemap?.node) {
            return;
        }

        async function fetchSitemapTiles(pageList: Domain.Publisher.SitemapNode[]) {
            const tiles = pageList.map(async (page) => {
                const fileBlob = await props.onLoadAttachment(page.image);
                return ({
                    page: page.elementId,
                    pageImageUrl: fileBlob ? URL.createObjectURL(fileBlob) : null,
                    pageImageFocalPoint: page.focalPoint,
                    pageDescription: page.description,
                });
            });

            return Promise.allSettled(tiles);
        }

        const flatArray = [props.sitemap.node, ...props.sitemap.node.children].flatten((item) => item.children);
        const pageNode = flatArray.find((child) => child.elementId === pageid);

        fetchSitemapTiles(pageNode.children).then((response) => {
            const tiles = response.reduce<TileData[]>((acc, curr) => {
                if (curr.status === 'fulfilled') {
                    acc.push(curr.value);
                }
                return acc;
            }, []);
            setTiles((prev) => mergeArrays(prev, tiles, 'page'));
        });
    }, [props.sitemap, props.onLoadAttachment]);

    const buttonBackgroundColor = props.publicationElement.primaryColor ? props.publicationElement.primaryColor : palette.grey4;
    const buttonIconColor = props.publicationElement.primaryContrastColor ? props.publicationElement.primaryContrastColor : palette.grey1;
    const tileIconBackgroundColor = props.publicationElement.secondaryColor ? props.publicationElement.secondaryColor : palette.grey3;
    const tileIconColor = props.publicationElement.secondaryContrastColor ? props.publicationElement.secondaryContrastColor : palette.grey1;

    const tileKindOption = currentElement ? FieldsHelper.mapFieldOption<Domain.Publisher.TileMenuControl>(currentElement.data, 'tileImageKind', currentElement.definition) : null;
    const tileKind = tileKindOption?.value || TileImageKind.Image;

    const renderTileContent = (key: string, tileKind: number, imageUrl?: string, imageFocusPoint?: string, iconId?: string,) => {
        if (tileKind === TileImageKind.Image && imageUrl) {
            return <TileImage imageUrl={imageUrl} imageFocusPoint={imageFocusPoint} />;
        }
        if (tileKind === TileImageKind.Icon && iconId) {
            return <Styled.ImagePlaceholderWrapper key={key}>
                <Styled.ImagePlaceholder color={tileIconBackgroundColor}>
                    <SVGIcon value={props.icons[iconId]?.svg} size={IconSize.extralarge} color={tileIconColor} />
                </Styled.ImagePlaceholder>
            </Styled.ImagePlaceholderWrapper>;
        }
        return <Styled.ImagePlaceholderWrapper key={key}>
            <Styled.ImagePlaceholder>
                <Styled.ImagePlaceholderIcon sx={{ fontSize: 80 }} />
            </Styled.ImagePlaceholder>
        </Styled.ImagePlaceholderWrapper>;
    };

    const getTileMenuItemElement = (tileKind: number, title: string, description: string, index: number, imageUrl?: string, imageFocusPoint?: string, active = false, to?: string, key?: string, iconId?: string,): JSX.Element => {
        const getCardElement = () => (
            <Styled.Card
                active={active}
                clickable={false}
                textColor={props.publicationElement.bodyFontColor}
                textFontSize={props.publicationElement.bodyFontSize}
            >
                <Styled.CardHeader>
                    {renderTileContent(`tile${key || index}`, tileKind, imageUrl, imageFocusPoint, iconId)}
                    <Styled.ArrowIcon
                        backgroundColor={buttonBackgroundColor}
                        iconColor={buttonIconColor}
                    />
                </Styled.CardHeader>
                <Styled.CardContent>
                    <Styled.TileTitle>{title}</Styled.TileTitle>
                    <Styled.TileDescription>{description}</Styled.TileDescription>
                </Styled.CardContent>
            </Styled.Card>);

        if (!to) {
            return getCardElement();
        }

        return (
            <Styled.CustomLink key={index} to={to}>
                {getCardElement()}
            </Styled.CustomLink>
        );
    };

    const getMenuListItemsElements = (): JSX.Element | JSX.Element[] => {
        if (!props.sitemap) {
            return [
                getTileMenuItemElement(0, '# LINK', 'Omschrijving', 1),
                getTileMenuItemElement(0, '# LINK', 'Omschrijving', 2),
                getTileMenuItemElement(0, '# LINK', 'Omschrijving', 3)
            ];
        }

        const flatArray = [props.sitemap.node, ...props.sitemap.node.children].flatten((item) => item.children);
        const pageNode = flatArray.find((child) => child.elementId === pageid);

        return pageNode?.children && pageNode?.children?.length > 0
            ? pageNode.children.map((sitemapNode, index) => {
                const pageUrl = pathname.replace(pageid, sitemapNode.elementId);
                const tile = tiles?.find(t => t.page === sitemapNode.elementId);
                const description = tile?.pageDescription || tile?.description || '';
                const image = tile?.pageImageUrl || tile?.imageUrl || null;
                const focusPoint = tile?.pageImageFocalPoint || tile?.imageFocusPoint || DEFAULT_FOCUS_VALUE;
                return getTileMenuItemElement(
                    tileKind,
                    sitemapNode.elementName,
                    description.trim() !== '' ? description : 'Omschrijving',
                    index,
                    image,
                    focusPoint,
                    sitemapNode.elementId === pageid,
                    pageUrl,
                    sitemapNode.elementId,
                    sitemapNode.iconId,
                );
            })
            : [
                getTileMenuItemElement(0, 'Geen pagina\'s', 'Omschrijving', 0),
                getTileMenuItemElement(0, 'Geen pagina\'s', 'Omschrijving', 1),
                getTileMenuItemElement(0, 'Geen pagina\'s', 'Omschrijving', 2)
            ];
    };

    return (
        <>
            {currentElement?.data && currentElement.data.title && <SharedStyled.Title h3FontSize={props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}>{currentElement.data.title}</SharedStyled.Title>}

            <Styled.Wrapper
                primaryColor={props.publicationElement.primaryColor}
                primaryTextColor={props.publicationElement.primaryContrastColor}
                textColor={props.publicationElement.bodyFontColor}
                textFontSize={props.publicationElement.bodyFontSize}
                editMode={!props.readonly}
            >
                {getMenuListItemsElements()}
            </Styled.Wrapper>
        </>
    );
}

// TODO: Investigate why lodash isEqual doesn't work on the complexFields of an Element.
export default React.memo(TileMenuControl, (prevProps, nextProps) => {
    return _.isEqual(prevProps.elementList[prevProps.element.elementId], nextProps.elementList[nextProps.element.elementId])
        && _.isEqual(Object.values(prevProps.elementList[prevProps.element.elementId].complexFields), Object.values(nextProps.elementList[nextProps.element.elementId].complexFields))
        && _.isEqual(prevProps.sitemap, nextProps.sitemap);
});

type TileImageProps = {
    imageUrl: string,
    imageFocusPoint: string,
};

/**
 * Represents the dimenssions of an image.
 */
type ImageDimensions = {
    width: number;
    height: number;
};

const TileImage: React.FC<TileImageProps> = (props) => {
    const [imageDimensions, setImageDimensions] = useState<ImageDimensions>({ width: 0, height: 0 });

    useEffect(() => {
        if (!props.imageUrl) {
            return;
        }

        const img = new Image();
        img.src = props.imageUrl;
        img.addEventListener('load', () => {
            setImageDimensions({ width: img.naturalWidth, height: img.naturalHeight });
        });

    }, [props.imageUrl]);

    const style = {
        height: '160px',
        width: '100%'
    };
    const focusPoint = mapImageFocusPoint(props.imageFocusPoint);

    return (
        <Styled.ImageWrapper>
            <Styled.ImagePositionWrapper>
                <ImageFocusViewer
                    className="lias_publisher_publication-renderer-tileimage"
                    as="div"
                    role="presentation"
                    src={props.imageUrl}
                    point={focusPoint}
                    dimensions={[imageDimensions.width, imageDimensions.height]}
                    style={style}
                />
            </Styled.ImagePositionWrapper>
        </Styled.ImageWrapper>);
}

const mapImageFocusPoint = (focusPointData: string): [number, number, number] => {
    const coordinates = focusPointData ? focusPointData.split(',') : null;
    const focusPoint: [number, number, number] = coordinates
        ? [Number(coordinates[0]), Number(coordinates[1]), Number(coordinates[2])]
        : [0.5, 0.5, 1];

    return focusPoint;
};

const mergeArrays = (arr1: TileData[], arr2: TileData[], key: keyof TileData): TileData[] => {
    const map1: Dictionary<TileData> = _.keyBy(arr1, key);
    const map2: Dictionary<TileData> = _.keyBy(arr2, key);
    const mergedMap: Dictionary<TileData> = _.merge({}, map1, map2);
    return _.values(mergedMap);
};
