import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

export type BudgetDevelopmentFilter = {
    baseYear?: number;
    showIncidental?: boolean;
    showStructural?: boolean;
    showResult?: boolean;
    showInWorkflow?: boolean;
    showFinal?: boolean;
}

/**
 * Represents a data accessor of budget development.
 */
export class BudgetDevelopmentDataAccessor {

    /**
     * Gets the collection of budget development items.
     * @param query Defines the odata query.
     */
    public static getAll = async (filter: BudgetDevelopmentFilter): Promise<AxiosResponse<Domain.Finance.BudgetDevelopment[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Finance.BudgetDevelopment[]>(`api/finance/budget-development/${filter.baseYear}`, {
            ...config,
            params: {
                includeIncidental: filter.showIncidental,
                includeStructural: filter.showStructural,
                includeResult: filter.showResult,
                includeInWorkflow: filter.showInWorkflow,
                includeFinal: filter.showFinal,
            }
        });
    };
}
