import { IconValue } from '@liasincontrol/ui-basics';

//will be removed!!!
export interface IEDIcon {
    iconId: ElementIconGuid,
    iconValue: IconValue,
    ariaLabel: string,
}

enum ElementIconGuid {
    ED_Clock = 'd906110e-cc3e-44cf-94ed-0bc11fad4c46',
    ED_Comment = 'b3fb29ad-a6ef-4c62-ae0f-5ae0fb03af42',
    ED_CustomerReview = '5bcc9137-fec1-4acd-9380-80587b5a9d6d',
    ED_Customer = 'cb862ed3-e387-4cc9-8cd0-93c0502d4b11',
    ED_Euro = 'f676cf3b-cabe-419c-81b7-aba2a069dc4c',
    ED_Experiment = '7c11ca2d-8c6e-4e8c-9d10-cc94fb7777cd',
    ED_Flag = '8932e6af-330f-4ddb-bef1-552fef8c985b',
    ED_Folder = 'bdb2154e-4f04-4549-9d37-8d07cb85b5d6',
    ED_Gear = '39288086-d720-4ede-84d4-b09f9a3d1c45',
    ED_GraduationCap = 'f9e19a4e-4a2f-401b-8e54-5f31f22f9ad8',
    ED_Handshake = 'ac046aa3-79d6-4ea4-a7e6-19bd5632b238',
    ED_House = '28c37fb3-ad63-477d-a73b-be8c4fca4e1c',
    ED_Innovation = '635c8822-8e14-44a2-a483-70302a099adc',
    ED_Insurance = 'fde5f2c7-306c-411f-b1bd-95b9b10ecf0a',
    ED_Key = '08d82cda-c572-422e-89cd-25e4791c8fbf',
    ED_Law = '830d6459-7a61-464f-b761-c9903917545f',
    ED_Location = 'bfdcaa09-0f53-4992-9a28-4a94c8c75076',
    ED_MagnifyingGlass = 'c4a9751c-550d-4086-bb14-8e1d8114535f',
    ED_Megaphone = 'a24e4099-4a0c-4d59-ae72-badbc659c45b',
    ED_Notification = '91aadd52-1dbe-4c6f-88d4-fb51b1c425a3',
    ED_OfficeBuilding = '9260ac47-62ed-4ddb-813f-30007aa034f3',
    ED_Priority = 'cbd4f986-77a0-40fa-b35e-509674b75d1b',
    ED_Proctection = '8e1d0c41-4f93-4dae-8da7-82646f6db6c9',
    ED_Question = '5cb55d6d-5fa0-4671-8394-21e47ac48f80',
    ED_Servers = '97583ecc-aa27-4f7d-bc24-23db57195d06',
    ED_Speedometer = '6f5be1a0-093e-4608-bc3e-86ab58f663d2',
    ED_Strategy = '05cf215b-ddf8-4c19-b4ce-870429fcbdaf',
    ED_Target = '9a64d8d5-140c-43f7-95c5-69630efc832a',
    ED_TrafficLight = '34384023-b2db-4530-af45-40e81084c9d9',
    ED_Tree = '75b15df9-da5f-4ad4-9602-effb18a8d376',
    ED_Trophy = '2b292618-730d-41ee-966f-868b48617af6',
    ED_Vaccine = '628a4599-ead7-445b-a9f4-bdfe72470a04',
}

export const getEDIconValueForId = (iconGuid: string) => {
    return elementDefinitionIcons.find((icon) => icon.iconId === iconGuid)?.iconValue || IconValue.ED_Clock;
}

export const elementDefinitionIcons: IEDIcon[] = [
    {
        iconId: ElementIconGuid.ED_Clock,
        iconValue: IconValue.ED_Clock,
        ariaLabel: 'Clock-icon'

    }, {
        iconId: ElementIconGuid.ED_Comment,
        iconValue: IconValue.ED_Comment,
        ariaLabel: 'Comment-icon'

    }, {
        iconId: ElementIconGuid.ED_CustomerReview,
        iconValue: IconValue.ED_CustomerReview,
        ariaLabel: 'CustomerReview-icon'

    }, {
        iconId: ElementIconGuid.ED_Customer,
        iconValue: IconValue.ED_Customer,
        ariaLabel: 'Customer-icon'

    }, {
        iconId: ElementIconGuid.ED_Euro,
        iconValue: IconValue.ED_Euro,
        ariaLabel: 'Euro-icon'

    }, {
        iconId: ElementIconGuid.ED_Experiment,
        iconValue: IconValue.ED_Experiment,
        ariaLabel: 'Experiment-icon'

    }, {
        iconId: ElementIconGuid.ED_Flag,
        iconValue: IconValue.ED_Flag,
        ariaLabel: 'Flag-icon'

    }, {
        iconId: ElementIconGuid.ED_Folder,
        iconValue: IconValue.ED_Folder,
        ariaLabel: 'Folder-icon'

    }, {
        iconId: ElementIconGuid.ED_Gear,
        iconValue: IconValue.ED_Gear,
        ariaLabel: 'Gear-icon'

    }, {
        iconId: ElementIconGuid.ED_GraduationCap,
        iconValue: IconValue.ED_GraduationCap,
        ariaLabel: 'GraduationCap-icon'

    }, {
        iconId: ElementIconGuid.ED_Handshake,
        iconValue: IconValue.ED_Handshake,
        ariaLabel: 'Handshake-icon'

    }, {
        iconId: ElementIconGuid.ED_House,
        iconValue: IconValue.ED_House,
        ariaLabel: 'House-icon'

    }, {
        iconId: ElementIconGuid.ED_Innovation,
        iconValue: IconValue.ED_Innovation,
        ariaLabel: 'Innovation-icon'

    }, {
        iconId: ElementIconGuid.ED_Insurance,
        iconValue: IconValue.ED_Insurance,
        ariaLabel: 'Insurance-icon'

    }, {
        iconId: ElementIconGuid.ED_Key,
        iconValue: IconValue.ED_Key,
        ariaLabel: 'Key-icon'

    }, {
        iconId: ElementIconGuid.ED_Law,
        iconValue: IconValue.ED_Law,
        ariaLabel: 'Law-icon'

    }, {
        iconId: ElementIconGuid.ED_Location,
        iconValue: IconValue.ED_Location,
        ariaLabel: 'Location-icon'

    }, {
        iconId: ElementIconGuid.ED_MagnifyingGlass,
        iconValue: IconValue.ED_MagnifyingGlass,
        ariaLabel: 'MagnifyingGlass-icon'

    }, {
        iconId: ElementIconGuid.ED_Megaphone,
        iconValue: IconValue.ED_Megaphone,
        ariaLabel: 'Megaphone-icon'

    }, {
        iconId: ElementIconGuid.ED_Notification,
        iconValue: IconValue.ED_Notification,
        ariaLabel: 'Notification-icon'

    }, {
        iconId: ElementIconGuid.ED_OfficeBuilding,
        iconValue: IconValue.ED_OfficeBuilding,
        ariaLabel: 'OfficeBuilding-icon'

    }, {
        iconId: ElementIconGuid.ED_Priority,
        iconValue: IconValue.ED_Priority,
        ariaLabel: 'Priority-icon'

    }, {
        iconId: ElementIconGuid.ED_Proctection,
        iconValue: IconValue.ED_Proctection,
        ariaLabel: 'Proctection-icon'

    }, {
        iconId: ElementIconGuid.ED_Question,
        iconValue: IconValue.ED_Question,
        ariaLabel: 'Question-icon'

    }, {
        iconId: ElementIconGuid.ED_Servers,
        iconValue: IconValue.ED_Servers,
        ariaLabel: 'Servers-icon'

    }, {
        iconId: ElementIconGuid.ED_Speedometer,
        iconValue: IconValue.ED_Speedometer,
        ariaLabel: 'Speedometer-icon'

    }, {
        iconId: ElementIconGuid.ED_Strategy,
        iconValue: IconValue.ED_Strategy,
        ariaLabel: 'Strategy-icon'

    }, {
        iconId: ElementIconGuid.ED_Target,
        iconValue: IconValue.ED_Target,
        ariaLabel: 'Target-icon'

    }, {
        iconId: ElementIconGuid.ED_TrafficLight,
        iconValue: IconValue.ED_TrafficLight,
        ariaLabel: 'TrafficLight-icon'

    }, {
        iconId: ElementIconGuid.ED_Tree,
        iconValue: IconValue.ED_Tree,
        ariaLabel: 'Tree-icon'

    }, {
        iconId: ElementIconGuid.ED_Trophy,
        iconValue: IconValue.ED_Trophy,
        ariaLabel: 'Trophy-icon'

    }, {
        iconId: ElementIconGuid.ED_Vaccine,
        iconValue: IconValue.ED_Vaccine,
        ariaLabel: 'Vaccine-icon'
    },
];
