import React, { } from 'react';

/**
 * Represents a UI component that renders the footer into the publication.
 */
const FooterControl: React.FC = () => {
    return (
        <footer className='site-footer'>&#169; {new Date().getFullYear().toString()} Inergy | Privacy | Toegankelijkheid | Sitemap</footer>
    );
};

export default FooterControl;
