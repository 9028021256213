import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import _, { Dictionary } from 'lodash';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as Domain from '@liasincontrol/domain';
import { DeviceFrame, EditingToolbar, PreviewToolbar, DeviceType, Button, ErrorOverlay, ModalDialog, IDataItemProps } from '@liasincontrol/ui-basics';
import { State, ActionSource, AttachmentsActionCreator, SvgIconActionCreator, AjaxRequestStatus } from '@liasincontrol/redux-service';
import { Actions, ActionType, UserRightsService } from '@liasincontrol/userrights-service';
import AuthService, { UserIdentity } from '@liasincontrol/auth-service';
import { Publisher as DataAccess, Shared as SharedDataAccess, Performance as PerformanceDataAccess, Studio as StudioDataAccess } from '@liasincontrol/data-service';
import { ApiErrorReportingHelper, FieldsHelper, OperationsHelper, AttachmentsHelper } from '@liasincontrol/core-service';
import { IndicatorSize, LoadIndicator } from '@liasincontrol/ui-devextreme';
//TODO: is a shared component
import { Settings } from '../../../../../Writer/Publications/PublictationItem/PublicationContent/ContentEditor/Settings';
import Renderer from '../../../../../../components/Renderer';
import { TreeUtils } from '../../../../../../helpers';
import Styled from './index.styled';
import { usePublicationSettings } from '../../../../../../helpers/PublicationContext';

enum ContentEditorState {
    Preview = 1,
    Editing = 2,
}

enum PublicationTemplateActionType {
    Initialize = 'initialize',
    TemplateChanged = 'templateChanged',
    ElementMoved = 'elementMoved',
    ElementRemoved = 'elementRemoved',
    FieldsChanged = 'fieldsChanged',
    ComplexFieldsChanged = 'complexFieldsChanged',
}

type PublicationTemplateState = {
    layers?: Domain.Publisher.Layer[],
    pageTemplateRoot?: Domain.Publisher.ElementNode,
    pageTemplateElements?: Record<string, Domain.Publisher.Element>,
}

type PublicationTemplateAction = {
    type: PublicationTemplateActionType,
    payload?: {
        fieldChanges?: Domain.Publisher.FieldPatch[],
        complexFieldChanges?: Domain.Publisher.ComplexFieldPatch[],
        layers?: Domain.Publisher.Layer[],
        templateLayer?: Domain.Publisher.Layer,
        elementDefinitions?: Dictionary<Domain.Shared.ElementDefinition>,
        attachments?: {
            [attachmentId: string]: File
        },
    },
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    pageTemplate: Domain.Publisher.TemplateElement,
    elementDefinitions: Dictionary<Dictionary<Domain.Shared.ElementDefinition>>,
    measureMoments?: Domain.Shared.MeasureMoment[],
    hierarchyDefinitions: Dictionary<Domain.Shared.HierarchyDefinition[]>,
    onClose: (changed: boolean) => void,
}

/**
 * Represents a UI component that renders the edit publication template page.
 */
const Index: React.FC<Props> = (props) => {
    const [isSettingsVisible, setIsSettingsVisible] = useState<boolean>(true);
    const [hasErrorsInSettings, setHasErrorsInSettings] = useState<boolean>(false);
    const [device, setDevice] = useState<DeviceType>('desktop');
    const [editingState, setEditingState] = useState<ContentEditorState>(ContentEditorState.Editing);
    const [selectedElement, setSelectedElement] = useState<Domain.Publisher.Element>();
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [updatedPageTemplate, setUpdatedPageTemplate] = useState<Domain.Publisher.TemplateElement>(undefined);
    const [pendingOperation, setPendingOperation] = useState<boolean>(false);
    const [templateState, templateStateDispatch] = useReducer(publicationTemplateReducer, {});
    const [userIdentity, setUserIdentity] = useState<UserIdentity>();
    const [hierarchies, setHierarchies] = useState<Domain.Shared.HierarchyMap>(
        {
            [ActionSource.Performance]: {},
            [ActionSource.Studio]: {},
        });

    const [types, setTypes] = useState<IDataItemProps<string>[]>([]);
    const publication = usePublicationSettings();

    const [invalidElements, setInvalidElements] = useState<Record<string, { message: string, isValid: boolean, keep?: boolean }>>({});

    useEffect(() => {
        AuthService.getInstance()
            .then(async (auth) => await auth.getUser())
            .then((identity) => {
                if (identity) {
                    setUserIdentity(identity);
                }
            });
    }, []);

    useEffect(() => {
        if (!publication) {
            return;
        }

        DataAccess.Templates.getContent(publication.publication.elementId, props.pageTemplate.id)
            .then((response) => {
                setError(undefined);
                templateStateDispatch({
                    type: PublicationTemplateActionType.Initialize, payload: {
                        layers: response.data.layers
                    }
                });
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
            });
    }, [publication, props.pageTemplate, lastRefresh]);

    if (!props.icons || props.icons.status === AjaxRequestStatus.NotSet) {
        props.fetchIcons();
    }

    //#region event handlers...
    const canPerformAction = (action: Actions, actionType: ActionType): boolean => {
        return UserRightsService.getInstance().canPerformAction(userIdentity, action, actionType);
    };

    const loadAttachment = useCallback((attachmentId: string): Promise<Blob> => {
        return AttachmentsHelper.loadExistingAttachment(attachmentId, props.attachments, props.setAttachment);
    }, [props.attachments, props.setAttachment]);

    /**
     * Represents an event handler that triggers the uploading process of an attachment.
     * 
     * @param file Defines the file.
     * @param abortSignal Defines the cancel token.
     */
    const uploadAttachment = async (file: File, abortSignal: AbortSignal): Promise<string> => {
        const { blobId } = await SharedDataAccess.Attachments.uploadAttachment(file, abortSignal);
        props.setAttachment(blobId, file);
        return blobId;
    };

    /**
     * Represents an event handler that triggers the removing process of an attachment.
     * 
     * @param attachmentId Defines the attachment id.
     */
    const removeAttachment = (attachmentId: string) => {
        props.removeAttachment(attachmentId);
    };

    /**
     * Represents an event handler that triggers the loading process of a complete performance hierarchy.
     * 
     * @param measureMomentId Defines the measure moment id.
     */
    const loadPerformanceHierarchy = (measureMomentId: string): void => {
        if (measureMomentId && !Object.keys(hierarchies[ActionSource.Performance]).includes(measureMomentId)) {
            PerformanceDataAccess.HierarchyDataAccessor.get(measureMomentId, true, true).then((result) => {
                setHierarchies((prevState) => ({
                    ...prevState,
                    [ActionSource.Performance]: {
                        [measureMomentId]: {
                            performance: result.data.hierarchy,
                        }
                    }
                }));
            });
        }
    }

    /**
     * Represents an event handler that triggers the loading process of a complete studio hierarchy.
     * 
     * @param hierarchyId Defines the hierarchy id.
     * @param measureMomentId Defines the measure moment id.
     * @param hierarchyDefinitionId Defines the hierarchy definition id.
     */
    const loadStudioHierarchy = (hierarchyId: string, hierarchyDefinitionId: string, measureMomentId: string): void => {
        if (hierarchyId && hierarchyDefinitionId && measureMomentId) {
            StudioDataAccess.HierarchyDataAccessor.get(hierarchyId, true, true)
                .then((result) => {
                    setHierarchies((prevState) => ({
                        ...prevState,
                        [ActionSource.Studio]: {
                            [measureMomentId]: {
                                ...prevState[measureMomentId],
                                [hierarchyDefinitionId]: result.data.hierarchy,
                            }
                        }
                    }) as Domain.Shared.HierarchyMap);
                });
        }
    }

    // Get available types based on lyerarchy def and element def
    const getAvailableHierarchyTypes = (items: Domain.Shared.HierarchyLinkDefinition[], source: ActionSource) => {
        if (!items || !source) return;
        const usedElementDefinitionIds: string[] = items
            ?.filter((hierarchyDefinitionLink) => !hierarchyDefinitionLink.fromElementDefinitionId)
            ?.map((hierarchyDefinitionLink) => hierarchyDefinitionLink.toElementDefinitionId);

        const availableTypes = Object.values(props.elementDefinitions[source])
            .filter((elementDefinition) => usedElementDefinitionIds.indexOf(elementDefinition.id) >= 0)
            .map((elementDefinition) => {
                return {
                    value: elementDefinition.id,
                    label: elementDefinition.name
                };
            });
        setTypes(availableTypes);
    };

    // Callback from settings component to check if there are any errors in the fields of the settings-form.
    const onErrorsInSettings = (value: boolean, elementId: string) => {
        if (!value) {
            if (Object.values(invalidElements).every(e => e.isValid)) {
                setHasErrorsInSettings(false);
                setInvalidElements({})
            }
            else {
                const invalidElementsClone = _.cloneDeep(invalidElements);
                delete invalidElementsClone[elementId];
                setInvalidElements(invalidElementsClone);
                if (!Object.keys(invalidElementsClone).length) {
                    setHasErrorsInSettings(false);
                }
            }
        }
        else {
            setHasErrorsInSettings(true);
            setInvalidElements(prev => ({ ...prev, [elementId]: { message: '', isValid: false } }))
        }
    };

    const onTemplateChanged = (operations: Domain.Publisher.Operation[], rePosition = false, steps = 0): void => {
        const templateLayer = _.cloneDeep(templateState.layers.find(item => item.kind === Domain.Publisher.LayerKind.PageTemplate));
        const newOperationIndex = getIndexOfNewOperations(templateLayer.operations.find(item => item.element.elementId === operations[0].other), templateLayer.operations);

        templateLayer.operations.splice(newOperationIndex, 0, ...operations);

        const element = operations[operations.length - 1].element;
        if (rePosition && steps > 0) {
            OperationsHelper.swapOperations(templateLayer.operations, newOperationIndex, true, steps, false);
        }

        templateStateDispatch({ type: PublicationTemplateActionType.TemplateChanged, payload: { templateLayer } });
        setSelectedElement(element);
    };

    const onRemoveElement = (elementId: string, isControl: boolean): void => {
        const templateLayer = _.cloneDeep(templateState.layers.find(item => item.kind === Domain.Publisher.LayerKind.PageTemplate));
        const templateOperations = OperationsHelper.removeElement(templateLayer.operations, elementId, isControl);

        const editedTemplateLayer: Domain.Publisher.Layer = {
            ...templateLayer,
            operations: templateOperations
        };
        templateStateDispatch({ type: PublicationTemplateActionType.ElementRemoved, payload: { templateLayer: editedTemplateLayer } });

        // If the element is an invalid one, also update invalidElements
        const isInvalidElement = Object.keys(invalidElements).includes(elementId);
        if (isInvalidElement) {
            const invalidElementsCopy = _.cloneDeep(invalidElements);
            delete invalidElementsCopy[elementId];
            setInvalidElements(invalidElementsCopy);
        }
        setSelectedElement(undefined);
    };

    const onMoveElement = (elementId: string, isControl: boolean, moveUp: boolean, steps?: number): void => {
        let templateLayer = _.cloneDeep(templateState.layers.find(item => item.kind === Domain.Publisher.LayerKind.PageTemplate));
        const otherId = templateLayer.operations.find(item => item.element.elementId === elementId).other;
        const other = templateLayer.operations.find(item => item.element.elementId === otherId);
        const otherOther = templateLayer.operations.find(item => item.element.elementId === other?.other);

        if (other?.element.elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.TabContainer
            || other?.element.elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.AccordionContainer
            || otherOther?.element.elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.StackContainer) {

            for (let index = 0; index < templateLayer.operations.length; index++) {
                if (templateLayer.operations[index].element.elementId !== (isControl ? otherId : elementId)) {
                    continue;
                }

                OperationsHelper.swapOperations(templateLayer.operations, index, moveUp, steps, true);
                break;
            }
        } else {
            const templateOperations = OperationsHelper.moveElement(templateLayer.operations, elementId, isControl, moveUp);
            templateLayer = {
                ...templateLayer,
                operations: templateOperations
            };
        }

        templateStateDispatch({ type: PublicationTemplateActionType.ElementMoved, payload: { templateLayer } });
    };

    const getIndexOfNewOperations = (operation: Domain.Publisher.Operation, templateOperations: Domain.Publisher.Operation[]): number => {
        //Operation is the parent
        const operationIndex = templateOperations.indexOf(operation);
        if (operationIndex === -1) {
            return templateOperations.length;
        }

        //add all child operations, if place to the end
        return operationIndex + OperationsHelper.getChildrenOperations(templateOperations, operation.element.elementId, true).length;
    };

    /**
     * Represents an event handler that creates a patch for the fields that has been changed.
     * @param changes Defines the element, field and value that have been changed.
     */
    const fieldsChanged = (changes: Domain.Publisher.FieldPatch[]) => {
        if (!changes.length) {
            return;
        }

        // Page control changes.
        templateStateDispatch({ type: PublicationTemplateActionType.FieldsChanged, payload: { fieldChanges: changes, elementDefinitions: props.elementDefinitions[ActionSource.Publication], attachments: props.attachments } });

        // Template page name change.
        const pageTemplateChange = changes?.find((change) => change.elementId === props.pageTemplate.id);
        if (pageTemplateChange) {
            setUpdatedPageTemplate(state => {
                if (!state) {
                    state = props.pageTemplate;
                }

                const newTemplatePage = new Domain.Publisher.TemplateElement(); //make sure we construct a proper object so we can use the mapping methods below.
                Object.assign(newTemplatePage, state);

                const templateElementDefinition = getElementDefinition(Domain.SystemElementDefinitions.Pub.PageTemplate);
                FieldsHelper.mapObject<Domain.Publisher.TemplateElement>(newTemplatePage, templateElementDefinition.fields, { [pageTemplateChange.fieldId]: pageTemplateChange.value });
                return newTemplatePage;
            });
        }
    };

    const complexFieldChanged = (change: Domain.Publisher.ComplexFieldPatch) => {
        if (!change) {
            return;
        }

        // Page control changes.
        templateStateDispatch({ type: PublicationTemplateActionType.ComplexFieldsChanged, payload: { complexFieldChanges: [change], elementDefinitions: props.elementDefinitions[ActionSource.Publication], attachments: props.attachments } });
    };

    const getElementDefinition = (systemId: string, elementDefinitionId?: string): Domain.Shared.ElementDefinition => {
        if (!props.elementDefinitions[ActionSource.Publication]) {
            return null;
        }

        if (elementDefinitionId) {
            return props.elementDefinitions[ActionSource.Publication][elementDefinitionId];
        }

        return Object.values(props.elementDefinitions[ActionSource.Publication]).find((definition) => definition.systemId === systemId);
    };

    const getDefinition = (id: string, source: ActionSource): Domain.Shared.ElementDefinition => {
        if (!props.elementDefinitions[source]) {
            return null;
        }

        return Object.values(props.elementDefinitions[source]).find((definition) => definition.id === id);
    };

    const getParentElement = (elementId: string): Domain.Publisher.Element => {
        if (!templateState.pageTemplateRoot) {
            return null;
        }

        const elementNode = TreeUtils.findParent(templateState.pageTemplateRoot, elementId);
        return elementNode ? templateState.pageTemplateElements[elementNode.elementId] : null;
    };

    const saveTemplate = (): void => {
        if (error) setError(undefined);
        setPendingOperation(true);

        const templateLayer = templateState.layers.find(item => item.kind === Domain.Publisher.LayerKind.PageTemplate);

        DataAccess.Templates.savePageTemplate(publication.publication.elementId, props.pageTemplate.id, templateLayer.operations)
            .then(() => {
                if (updatedPageTemplate) {
                    const templatePageDefinition = getElementDefinition(Domain.SystemElementDefinitions.Pub.PageTemplate);
                    return DataAccess.Templates.updatePageTemplate(publication.publication.elementId, updatedPageTemplate.id, FieldsHelper.reverseMapObject(updatedPageTemplate, templatePageDefinition.fields));
                }
            })
            .then(() => {
                setPendingOperation(false);
                props.onClose(true);
            })
            .catch((err) => {
                setPendingOperation(false);
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err));
            });
    };
    //#endregion event handlers...


    //#region JSX.Elements...
    const getSettingsElement = (): JSX.Element => {
        let selection = selectedElement && templateState.pageTemplateElements ? templateState.pageTemplateElements[selectedElement.elementId] : null;
        if (!selection && props.pageTemplate) {
            const definition = getElementDefinition(Domain.SystemElementDefinitions.Pub.PageTemplate);
            // Create a dummy Element definition for our Page template object.
            selection = getDummyPageTemplateElement(props.pageTemplate, definition);
        }

        return (
            <Settings
                publicationId={publication.publication.elementId}
                selectedElement={selection}
                isVisible={isSettingsVisible && !pendingOperation}
                isDesigner={true}
                measureMoments={props.measureMoments}
                getElementDefinition={getElementDefinition}
                onUploadAttachment={uploadAttachment}
                onLoadAttachment={loadAttachment}
                onRemoveAttachment={removeAttachment}
                onFieldsChanged={fieldsChanged}
                onComplexFieldChanged={complexFieldChanged}
                onErrorsInSettings={onErrorsInSettings}
                onToggleVisibility={() => setIsSettingsVisible((prev) => !prev)}
                getParentElement={getParentElement}
                hierarchies={hierarchies}
                loadPerformanceHierarchy={loadPerformanceHierarchy}
                canPerformAction={canPerformAction}
                elementTypes={types}
                getDefinition={getDefinition}
                hierarchyDefinitions={props.hierarchyDefinitions}
                getAvailableHierarchyTypes={getAvailableHierarchyTypes}
                loadStudioHierarchy={loadStudioHierarchy}
                templateName={updatedPageTemplate?.name}
                invalidElements={invalidElements}
                icons={props.icons.items}
            />
        );
    };

    const getPublicationRendererElement = () => {
        if (!templateState.pageTemplateElements || !templateState.pageTemplateRoot || pendingOperation) {
            return <LoadIndicator variant={IndicatorSize.extralarge} align='center' />;
        }

        return (
            <Renderer
                elementdefinitions={props.elementDefinitions}
                pageDesignId={props.pageTemplate.pageDesignId}
                publication={publication}
                publicationRoot={templateState.pageTemplateRoot}
                publicationElements={templateState.pageTemplateElements}
                readonly={editingState !== ContentEditorState.Editing}
                selectedElementId={selectedElement?.elementId}
                onSelectElement={(elementId: string) => {
                    if (selectedElement && selectedElement.elementId === elementId) {
                        setSelectedElement(undefined);
                    } else {
                        setSelectedElement(templateState.pageTemplateElements[elementId]);
                    }
                }}
                onLoadAttachment={loadAttachment}
                onTemplateChanged={onTemplateChanged}
                onRemoveElement={onRemoveElement}
                onMoveElement={onMoveElement}
                hierarchies={hierarchies}
                loadPerformanceHierarchy={loadPerformanceHierarchy}
                loadStudioHierarchy={loadStudioHierarchy}
                inValidElements={invalidElements}
                hasErrorInSettings={(elementId: string, hasError: boolean, message?: string, keepError?: boolean) => {
                    const aux = { ...invalidElements };
                    if (hasError) {
                        aux[elementId] = { message: message || '', isValid: false, keep: keepError };
                    } else {
                        aux[elementId] = { message: '', isValid: true };
                    }
                    setInvalidElements(aux);
                }}
                icons={props.icons.items}
            />
        );
    };

    //#endregion JSX.Elements...

    return (
        <ModalDialog
            customPadding
            edgeToolbar={getSettingsElement()}
            toolbars={
                <Toolbars
                    invalidElements={invalidElements}
                    editingState={editingState}
                    isValid={!hasErrorsInSettings && !pendingOperation && Object.values(invalidElements).every(e => e.isValid)}
                    onClose={props.onClose}
                    onCancel={() => {
                        setDevice('desktop');
                        setSelectedElement(undefined);
                        setEditingState(ContentEditorState.Editing);
                    }}
                    onDeviceChanged={(device: DeviceType) => setDevice(device)}
                    device={device}
                    id={props.pageTemplate.id}
                    saveTemplate={saveTemplate}
                    setPreviewMode={() => setEditingState(ContentEditorState.Preview)}
                />
            }>
            <Styled.Wrapper wide={!isSettingsVisible}>
                <DeviceFrame device={device} position="center" />
                <Styled.DeviceWrapper className={device} pose={device} withParent={false}>
                    <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        {getPublicationRendererElement()}
                    </ErrorOverlay>
                </Styled.DeviceWrapper>
            </Styled.Wrapper>
        </ModalDialog>
    );
};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        attachments: state.attachments,
        icons: state.icons,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAttachment: (attachmentId: string, attachment: File) => {
            dispatch(AttachmentsActionCreator.set({ source: ActionSource.Publication, data: { attachmentId, attachment } }));
        },
        removeAttachment: (attachmentId: string) => {
            dispatch(AttachmentsActionCreator.remove({ source: ActionSource.Publication, data: { attachmentId } }));
        },
        fetchIcons: () => {
            dispatch(SvgIconActionCreator.set());
        }
    };
};

const getDummyPageTemplateElement = (pageTemplate: Domain.Publisher.TemplateElement, definition: Domain.Shared.ElementDefinition): Domain.Publisher.Element => {
    // Make sure the page template is a real Domain.TemplateElement instance.
    const templateInstance = new Domain.Publisher.TemplateElement();
    Object.assign(templateInstance, pageTemplate);

    return {
        elementId: pageTemplate.id,
        elementDefinitionId: definition.id,
        elementDefinitionSystemId: definition.systemId,
        fields: FieldsHelper.reverseMapObject(templateInstance, definition.fields),
        attachments: [],
        complexFields: []
    };
};

const publicationTemplateReducer = (prevState: PublicationTemplateState, action: PublicationTemplateAction) => {
    if (!action || !prevState) return prevState;

    const newState: PublicationTemplateState = {};
    switch (action.type) {
        case PublicationTemplateActionType.Initialize:
            newState.layers = action?.payload?.layers;
            break;
        case PublicationTemplateActionType.TemplateChanged:
        case PublicationTemplateActionType.ElementMoved:
        case PublicationTemplateActionType.ElementRemoved:
            newState.layers = prevState.layers?.map(layer =>
                layer.kind === Domain.Publisher.LayerKind.PageTemplate ? action.payload?.templateLayer : layer);
            break
        case PublicationTemplateActionType.FieldsChanged: {
            /* TODO:  Cases to take into account when altering/generating operations:
              - empty original root or was root element replaced? -> generate patch operation for it
              - New elements => ok, just replace the values
              - existing elements =>
                  * changed elementId (??) -> generate
                  * generate ReplaceWith
          */
            const templateLayer = prevState.layers?.find(item => item.kind === Domain.Publisher.LayerKind.PageTemplate);
            const newOperations = [...OperationsHelper.applyFieldChangesToOperations(
                templateLayer.operations,
                action.payload?.fieldChanges,
                action.payload?.elementDefinitions,
                action.payload?.attachments)];
            const editedTemplateLayer: Domain.Publisher.Layer = {
                ...templateLayer,
                operations: newOperations
            };

            newState.layers = prevState.layers?.map(layer => layer.kind === Domain.Publisher.LayerKind.PageTemplate ? editedTemplateLayer : layer);
        } break;
        case PublicationTemplateActionType.ComplexFieldsChanged: {
            const templateLayer = prevState.layers?.find(item => item.kind === Domain.Publisher.LayerKind.PageTemplate);
            const newOperations = [...OperationsHelper.applyComplexFieldChangesToOperations(
                templateLayer.operations,
                action.payload?.complexFieldChanges,
                action.payload?.elementDefinitions,
                action.payload?.attachments)];
            const editedTemplateLayer: Domain.Publisher.Layer = {
                ...templateLayer,
                operations: newOperations
            };

            newState.layers = prevState.layers?.map(layer => layer.kind === Domain.Publisher.LayerKind.PageTemplate ? editedTemplateLayer : layer);
        } break;
        default:
            break;
    }
    newState.pageTemplateRoot = newState.layers ? OperationsHelper.getElementStructure(newState.layers) : prevState.pageTemplateRoot;
    newState.pageTemplateElements = newState.layers ? OperationsHelper.getElementList(newState.layers) : prevState.pageTemplateElements;
    return newState;

};

type ToolbarsProps = {
    onClose: (changed: boolean) => void;
    invalidElements: Record<string, { message: string, isValid: boolean, keep?: boolean }>;
    isValid: boolean;
    editingState: ContentEditorState;
    id: string;
    saveTemplate: () => void;
    onCancel: () => void;
    device: DeviceType;
    onDeviceChanged: (device: DeviceType) => void;
    setPreviewMode: () => void;
}

const Toolbars: React.FC<ToolbarsProps> = (props) => (
    <>
        <EditingToolbar
            id={props.id}
            look="default"
            isValid={props.isValid}
            isVisible={props.editingState === ContentEditorState.Editing}
            endNode={<Button btnbase="iconbuttons" btntype="medium_background" icon={<VisibilityIcon />} disabled={!props.isValid} onClick={props.setPreviewMode} />}
            onSave={props.saveTemplate}
            onCancel={() => props.onClose(false)}
        />
        <PreviewToolbar
            look="editor"
            isVisible={props.editingState === ContentEditorState.Preview}
            device={props.device}
            onCancel={props.onCancel}
            onDeviceChanged={props.onDeviceChanged}
        />
    </>
);


const TemplateEditor = connect(mapStateToProps, mapDispatchToProps)(Index);
export { TemplateEditor, ContentEditorState };
