import styled from 'styled-components';
import { px } from '@liasincontrol/ui-basics';

const FilterContainer = styled.div`
    display: flex;
    gap: ${px(16)};
`;

const FilterItem = styled.div<{ grow: number }>`
    flex-basis: 0;
    flex-grow: ${( { grow }) => grow};
`;

const FilterItemButton = styled.div<{ grow: number }>`
    display: flex;
    align-items: center;
    flex-basis: 0;
    flex-grow: ${( { grow }) => grow};
`;

const styledComponents = {
    FilterContainer, FilterItem, FilterItemButton
};

export default styledComponents;