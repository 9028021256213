import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseControlElement } from '../../BaseControlElement';

class DataTableControl extends BaseControlElement {

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.TableColumnSettings)
    columnSettings: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Filter)
    filter: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSource)
    dataSourceId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Title)
    title: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataTableDescription)
    description: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.TablePanelIsVisible)
    gridPanelIsVisible = false;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.GrandSummaryText)
    grandSummaryText: string;
}

export default DataTableControl;