import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { oDataResponse, oDataResponseStructured } from '../../Shared/oData';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents a data accessor of budget journal items.
 */
export class BudgetJournalDataAccessor {

    /**
     * Creates a budget Journal item.
     * @param budgetJournal Defines the budget Journal that has to be created.
     */
    public static create = async (budgetJournal: Domain.Dto.Finance.CreateBudgetJournal): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<string>('/api/finance/budgetjournal', budgetJournal, config);
    };

    /**
     * Updates a budget Journal item.
     * @param budgetJournal Defines the budget Journal that has to be updated.
     */
    public static update = async (budgetJournal: Domain.Finance.BudgetJournalDetail): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<string>(`/api/finance/budgetjournal/${budgetJournal.id}`, budgetJournal, config);
    };

    /**
     * Gets the collection of budget Journal items.
     * @param query Defines the odata query.
     */
    public static getAll = async (query = ''): Promise<AxiosResponse<oDataResponse<Domain.Finance.BudgetJournal[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<oDataResponse<Domain.Finance.BudgetJournal[]>>(`/api/finance/budgetjournal${query}`, config);
    };

    /**
     * Gets the collection of budget Journal items.
     * @param budgetJournalId Defines the budget journal id (budgetJournal).
     */
    public static get = async (budgetJournalId: string): Promise<AxiosResponse<Domain.Finance.BudgetJournalDetail>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<Domain.Finance.BudgetJournalDetail>(`/api/finance/budgetjournal/${budgetJournalId}`, config);
    };

    /**
     * Delete a budget journal item.
     * @param budgetJournalId Defines the budget journal that has to be deleted.
     */
    public static delete = async (budgetJournalId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.delete<void>(`/api/finance/budgetjournal/${budgetJournalId}`, config);
    };

    /**
     * Get a budget summary rows.
     * @param budgetJournalId  Defines the budget journal id (budgetJournal).
     * @returns 
     */
    public static getSummary = async (budgetJournalId: string): Promise<oDataResponseStructured<Domain.Finance.BudgetJournalSummaryValue>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const response = await HttpClient.get<oDataResponseStructured<Domain.Finance.BudgetJournalSummaryValue>>(`/api/finance/budgetjournal/${budgetJournalId}/budgetline/summary`, config);
        return response.data;
    };
}
