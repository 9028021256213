import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { palette, px } from '@liasincontrol/ui-basics';
import { lineHeightCoefficient } from '../../SharedControlElements/index.styled';

const Wrapper = styled.ul<{
    primaryColor: string,
    primaryTextColor: string,
    textColor: string,
    textFontSize?: string,
    editMode: boolean
}>`
    padding: 0;
    list-style-type: none;
    border-bottom: 1px solid ${palette.grey3b};    

    pointer-events: ${({ editMode }) => (editMode ? `none` : `auto`)};   
    font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px` : 'inherit')};
    line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px` : 'inherit')};
    color: ${({ textColor }) => textColor};
`;

const SncListItem = styled.li<{ active: boolean, color: string }>`
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: ${px(40)};
    padding: 0 ${px(16)};
    border-top: 1px solid ${palette.grey3b};
    border-left: ${(p) => p.active ? `3px solid ${p.color}` : `none`};
    background: ${(p) => p.active ? palette.grey4 : `none`};

    &:hover {
        background-color: ${palette.grey4};
    }
    span {
        overflow: hidden;
    }

    svg {
        min-width: ${px(24)};
    }
`;

const SncNonLinkListItem = styled.div`
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    user-select: none;
    color: ${palette.grey3};
`;

const CustomLink = styled(Link) <{
    textColor: string,
    textFontSize?: string,
}>`
    font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px` : 'inherit')};
    line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px` : 'inherit')};
    color: ${({ textColor }) => textColor}!important;
    text-decoration: none;
    display: flex;
    width: 100%;
    cursor: inherit;
    justify-content: space-between;
    align-items: center;

    &:link, &:visited, &:focus, &:hover, &:active {
        color: inherit!important;
        text-decoration: none;
        line-height: inherit;
        cursor: pointer;
    }
`;

const styledComponents = { Wrapper, SncListItem, SncNonLinkListItem, CustomLink };
export default styledComponents;
