import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Add from '@mui/icons-material/Add';
import * as Domain from '@liasincontrol/domain';
import { ActionSource, AjaxRequestStatus, ElementDefinitionsActionCreator, HierarchyDefinitionsActionCreator, MeasureMomentsActionCreator, ModulesActionCreator, State, SvgIconActionCreator, UsersActionCreator, WorkflowTemplateActionCreator } from '@liasincontrol/redux-service';
import { Bar, Button, DropdownButton, ErrorOverlay, Heading2, LeaseWrapper, ModalDialog, ModalDialogFooter, PageTitle, ResetZIndex, Section, Text, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { ApiErrorReportingHelper, DateUtils, FormMode, isMomentOpen, ValueType } from '@liasincontrol/core-service';
import { Studio, Shared } from '@liasincontrol/data-service';
import { Actions, ActionType, UserRightsService } from '@liasincontrol/userrights-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { SelectElement } from '@liasincontrol/ui-elements';
import { IndicatorSize, LoadIndicator } from '@liasincontrol/ui-devextreme';
import { typeInputCustomItem } from '../../../_shared/OptionItem/ElementDefinitionOptionItem';
import { HierarchyTree } from '../../../StudioElements/HierarchyTree';
import { HierarchyItem } from '../../../StudioElements/HierarchyItem';
import { MissingWorkflowDialog } from '../../../StudioElements/MissingWorkflowDialog';
import { createNewDetailCard } from '../../../ElementDefinitions';

/**
 * Defines the props of the Studio elements overview component.
 */
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

type MomentType = Domain.Shared.MeasureMoment & {
    closed: boolean;
}

/**
 * Represents a UI component that renders a list of Studio elements.
 */
const StudioHierarchyListItem: React.FC<Props> = (props) => {
    const { hierarchyDefinitionId, momentId, hierarchyId } = useParams<{ hierarchyDefinitionId: string, momentId: string, hierarchyId: string }>();

    const [selectedHierarchyDefinition, setSelectedHierarchyDefinition] = useState<Domain.Shared.HierarchyDefinition>(null);
    const [selectedMoment, setSelectedMoment] = useState<MomentType>(null);
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [types, setTypes] = useState<{
        values: Domain.Shared.ElementDefinition[],
        selectedType: Domain.Shared.ElementDefinition,
    }>({ values: [], selectedType: undefined });
    const [hierarchyItems, setHierarchyItems] = useState<Domain.Studio.HierarchyItem[]>();
    const [hierarchySettings, setHierarchySettings] = useState<Domain.Studio.StudioHierarchySettings>();
    const [showResults, setShowResults] = useState<{ visible: boolean, isBusy: boolean }>({ visible: false, isBusy: false });
    const [editPanelControls, setEditPanelControls] = useState<{
        elementId?: string,
        parentElementId?: string,
        elementDefinitionId?: string,
        isVisible: boolean,
        audit: Domain.Dto.Shared.AuditEvent[],
    }>({ isVisible: false, elementId: null, elementDefinitionId: null, audit: [] });
    const [elementInstance, setElementInstance] = useState<Domain.Studio.HierarchyItem>(null);
    const [showMissingWorkflowDialog, setShowMissingWorkflowDialog] = useState<boolean>(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const [leaseInfo, setLeaseInfo] = useState<Domain.Shared.AcquireLease>();
    const [forceSaveHierarchyItem, setForceSaveHierarchyItem] = useState<boolean>(false);
    const [showNoLeaseDialog, setShowNoLeaseDialog] = useState<{ visible: boolean, message?: string }>({ visible: false });
    const navigate = useNavigate();
    const [historyDelta, setHistoryDelta] = useState(-1);

    //#region hierarchy tree
    const fetchHierarchy = (id: string) => {
        if (!id || !props.elementDefinitions?.items) {
            return;
        }

        setShowResults({ visible: true, isBusy: true });
        Studio.HierarchyDataAccessor.get(id)
            .then((result) => {
                setHierarchyItems(result.data.hierarchy);
                setHierarchySettings(result.data.settings);
                setShowResults({ visible: true, isBusy: false });
            }).catch((exception) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
            }).finally(() => {
                setShowResults({ visible: true, isBusy: false });
            });
    };

    useEffect(() => {
        if (props.measureMoments.status !== AjaxRequestStatus.Done || !props.measureMoments.items) {
            return;
        }

        const selectedMomentObject = props.measureMoments.items.find(m => m.id === momentId);

        setSelectedMoment({
            ...selectedMomentObject,
            closed: !isMomentOpen(selectedMomentObject.status)
        });
    }, [props.measureMoments]);

    useEffect(() => {
        if (!props.hierarchyDefinitions || !props.hierarchyDefinitions.items || props.hierarchyDefinitions.status !== AjaxRequestStatus.Done) {
            return;
        }

        const selectedHierarchyDef = Object.values(props.hierarchyDefinitions.items).find(hd => hd.hierarchyDefinitionId === hierarchyDefinitionId);
        setSelectedHierarchyDefinition(selectedHierarchyDef);
    }, [props.hierarchyDefinitions]);

    useEffect(() => {
        fetchHierarchy(hierarchyId);
    }, [hierarchyId, props.elementDefinitions?.items]);
    //#endregion

    const getLease = (elementId: string) => {
        if (!canEdit) {
            return;
        }

        if (!elementId) {
            return;
        }
        Shared.Leases.acquireLease(elementId)
            .then((response) => {
                setLeaseInfo(response.data);
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
            });
    };

    const deleteLease = () => {
        //delete lease if exist a lease, and it's owned by logged in user
        if (leaseInfo && leaseInfo?.ownerId === props.userIdentity.profile.sub) {
            Shared.Leases.deleteLease(leaseInfo.subjectId)
                .then(() => {
                    setLeaseInfo(undefined);
                });
            return true;
        }

        return false;
    };

    const availableUsers = useMemo(() => {
        if (!props.users || props.users.status !== AjaxRequestStatus.Done) {
            return [];
        }

        return props.users.items.filter(user => user.enabled);
    }, [props.users]);

    const workflowDictionary = useMemo(() => {
        const workflowDictionary = {};
        if (hierarchySettings && props.workflowTemplates?.items?.length > 0) {
            const tryAddWorkflowDefinition = (workflowId: string, hierarchyElementType: string) => {
                if (workflowId) {
                    const workflow = props.workflowTemplates.items.find(workflow => workflow.id === workflowId);
                    if (workflow) {
                        workflowDictionary[hierarchyElementType] = workflow;
                    }
                }
            };

            Object.entries(hierarchySettings.elementDefinitionIdToWorkflowId).forEach(([key, value]) => {
                tryAddWorkflowDefinition(value, key);
            });
        }
        return workflowDictionary as Record<string, Domain.Shared.WorkflowTemplateWithStates>;
    }, [hierarchySettings, props.workflowTemplates]);

    const selectedElementDefinition = useMemo(() => {
        if (!props.elementDefinitions?.items || !editPanelControls.elementDefinitionId) {
            return null;
        }

        return Object.values(props.elementDefinitions.items).find((item) => editPanelControls.elementDefinitionId === item.id);
    }, [props.elementDefinitions, editPanelControls.elementDefinitionId]);

    useEffect(() => {
        if (!selectedElementDefinition || !hierarchyItems) {
            setElementInstance(null);
            setLeaseInfo(undefined);
            return;
        }

        if (editPanelControls.elementId) {
            if (canEdit) {
                getLease(editPanelControls.elementId);
            }
            Studio.HierarchyItemDataAccessor.getHierarchyItem(editPanelControls.elementId)
                .then((response) => {
                    const instance = hierarchyItems?.find(item => item.element.elementId === editPanelControls.elementId);
                    setElementInstance({ ...instance, element: response.data });
                }).catch((exception) => {
                    setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
                });
        } else {
            const newElement = new Domain.Studio.HierarchyItem();
            newElement.element = {
                fields: selectedElementDefinition.fields.reduce(
                    (collection, item) => ({ ...collection, [`${item.id}`]: '' }),
                    {}
                ),
                elementDefinitionId: selectedElementDefinition.id,
                elementDefinitionSystemId: selectedElementDefinition.systemId,
                elementId: undefined,
                attachments: [],
                complexFields: [],
            };

            setElementInstance(newElement);
        }
    }, [selectedElementDefinition, hierarchyItems, editPanelControls.elementId]);

    useEffect(() => {
        // Check if (default) workflow is set
        if (props.workflowTemplates.items.length === 0 && props.workflowTemplates.status === AjaxRequestStatus.Done) {
            setShowMissingWorkflowDialog(true);
        }
    }, [props.workflowTemplates]);

    useEffect(() => {
        if (!props.elementDefinitions?.items || !selectedHierarchyDefinition) {
            return;
        }

        // Hierarchy dependent data
        const usedElementDefinitions: string[] = selectedHierarchyDefinition?.items
            ?.filter((hierarchyDefinitionLink) => !hierarchyDefinitionLink.fromElementDefinitionId)
            ?.map((hierarchyDefinitionLink) => hierarchyDefinitionLink.toElementDefinitionId);
        const types = getHierarchyElementDefinitions(props.elementDefinitions.items, usedElementDefinitions);

        setTypes({
            values: types,
            selectedType: null,
        });

    }, [props.elementDefinitions, selectedHierarchyDefinition]);

    useEffect(() => {
        if (!elementInstance) {
            return;
        }

        setHistoryDelta(prev => prev - 1);
    }, [elementInstance]);

    useEffect(() => {
        if (!selectedElementDefinition) {
            return;
        }

        if (selectedElementDefinition.detailcards?.length === 0) {
            Shared.ElementDefinitions.getDetailCards(selectedElementDefinition.id).then(res=>{
                if(res.data.length === 0){
                    const defaultCard = createNewDetailCard("defaultCard", true, selectedElementDefinition.id);
                    Shared.ElementDefinitions.createDetailCard(defaultCard).then(() => {
                        props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
                        return;
                    });
                }else{
                    props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
                    return;
                }
            });
            
        }
    }, [selectedElementDefinition, props.modules]);

    const canEdit = useMemo(() =>
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_GlobalStudioContributor, ActionType.Update)
        , [props.userIdentity]);

    if (props.measureMoments.status === AjaxRequestStatus.NotSet) {
        props.fetchMeasureMoments();
        return null;
    }

    if (props.users.status === AjaxRequestStatus.NotSet) {
        props.fetchUsers();
        return null;
    }

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
        return null;
    }
    //refetch if no detailcard
    if (props.elementDefinitions.status === AjaxRequestStatus.Done && !props.elementDefinitions.includeDetailCards) {
        props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
        return null;
    }

    if (!props.hierarchyDefinitions || props.hierarchyDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchHierarchyDefinitions(ActionSource.Studio, props.modules[Domain.SystemModuleDefinitions.Studio], true);
        return null;
    }

    if (props.workflowTemplates.status === AjaxRequestStatus.NotSet) {
        props.fetchWorkflowTemplates();
        return null;
    }

    if (!props.icons || props.icons.status === AjaxRequestStatus.NotSet) {
        props.fetchIcons();
    }

    const canViewHierarchyItem = editPanelControls.isVisible && selectedElementDefinition && !!elementInstance;
    const canEditHierarchyItem = canViewHierarchyItem && editPanelControls.elementId && canEdit;

    //#region event handlers
    const renderNewButton = (isDisabled: boolean) => {
        const rootLevelElementDefinitions = selectedHierarchyDefinition?.items
            ?.filter((hierarchyDefinitionLink) => !hierarchyDefinitionLink.fromElementDefinitionId)
            ?.map((hierarchyDefinitionLink) => Object.values(props.elementDefinitions.items).find((item) => hierarchyDefinitionLink.toElementDefinitionId === item.id))
            ?.filter((elementDefinition) => elementDefinition && !elementDefinition.deleted);

        return (
            <DropdownButton text='Nieuw' icon={<Add />} disabled={isDisabled}>
                {rootLevelElementDefinitions &&
                    rootLevelElementDefinitions.map((elementDefinition) => {
                        return (
                            <li key={elementDefinition.id}>
                                <Button
                                    id={`btn-add-new-item-${elementDefinition.id}`}
                                    btnbase='textbuttons'
                                    btntype='flat_noicon'
                                    onClick={() => {
                                        setEditPanelControls({
                                            isVisible: true,
                                            elementId: null,
                                            elementDefinitionId: elementDefinition.id,
                                            parentElementId: null,
                                            audit: [],
                                        });
                                    }}
                                    style={{ maxWidth: "calc(100vw - 30rem)" }}
                                >
                                    {elementDefinition.name}
                                </Button>
                            </li>
                        );
                    })}
            </DropdownButton>
        );
    };

    const onEditEntity = (entityId: string) => {
        if (!hierarchyItems || !hierarchyId) {
            return;
        }

        const hierarchyItem = hierarchyItems.find(item => item.element.elementId === entityId);
        if (!hierarchyItem) {
            return;
        }

        const editData = {
            isVisible: true,
            elementId: hierarchyItem.element.elementId,
            elementDefinitionId: hierarchyItem.element.elementDefinitionId,
            parentElementId: hierarchyItem.parentHierarchyItemId,
            audit: []
        };

        if (hierarchyItem.element.elementId) {
            Studio.HierarchyItemDataAccessor.getAuditData(hierarchyId, hierarchyItem.element.elementId)
                .then((response) => {
                    setEditPanelControls({
                        ...editData,
                        audit: response.data || []
                    });
                })
                .catch(() => {
                    setEditPanelControls(editData);
                });
        } else {
            setEditPanelControls(editData);
        }
    };

    const onDeleteEntity = (entityId: string) => {
        if (!hierarchyItems || !hierarchyId) {
            return;
        }

        Studio.HierarchyItemDataAccessor.delete(hierarchyId, entityId)
            .then(() => {
                fetchHierarchy(hierarchyId);
            }).catch((exception) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, exception);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.StudioNoValidLeaseOnDelete) ||
                    errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.StudioDeleteItemLeaseInvalid)) {
                    setShowNoLeaseDialog({ visible: true, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.StudioNoValidLeaseOnDelete] });
                } else {
                    setError(errorInfo);
                }
            });
    };

    const onReparentEntity = (hierarchyItemId: string, parentHierarchyItemId: string) => {
        if (!hierarchyId) {
            return;
        }

        Studio.HierarchyItemDataAccessor.reparent(hierarchyId, hierarchyItemId, parentHierarchyItemId)
            .then(() => {
                fetchHierarchy(hierarchyId);
            }).catch((exception) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception));
            });
    };

    const onReorderEntities = (moveAfterItemId: string, movedItemId: string) => {
        Studio.HierarchyItemDataAccessor.reorderChildren(hierarchyId, moveAfterItemId, movedItemId)
            .then(() => {
                fetchHierarchy(hierarchyId);
            }).catch((exception) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception);

                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.StudioMoveItemError) ||
                    errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.StudioReorderItemLeaseInvalid)) {
                    setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.StudioMoveItemError] });
                } else {
                    setError(errorInfo);
                }
            });
    };

    const onCancelChanges = () => {
        const newHierarchyItems = [...hierarchyItems];
        const instanceId = newHierarchyItems?.findIndex(item => item.element.elementId === editPanelControls.elementId);
        if (instanceId >= 0) {
            newHierarchyItems.splice(instanceId, 1, elementInstance);
            setHierarchyItems(newHierarchyItems);
        }

        setEditPanelControls({
            isVisible: false,
            elementId: null,
            elementDefinitionId: null,
            parentElementId: null,
            audit: [],
        });

        setForceSaveHierarchyItem(false);
    };

    const onSaveChanges = (
        fields: Record<string, ValueType>,
        workflowState: Domain.Shared.AbstractElementWorkflowStatus,
        attachments: Domain.Shared.Attachment[],
        bypassThen = false) => {
        if (!hierarchyId) {
            onCancelChanges();
            return;
        }

        const item: Domain.Studio.HierarchyItem = {
            ...elementInstance,
            parentHierarchyItemId: editPanelControls.parentElementId,
            workflowStateId: workflowState?.workflowState,
            assignedUserId: workflowState?.assignedUser,
        };

        item.element = {
            ...item.element,
            fields: Object.keys(fields).reduce(
                (collection, field) => ({ ...collection, [field]: formatValueByType(fields[field]) }),
                {}
            ),
            attachments: attachments.filter(attachment => attachment.blobId || attachment.deleted),
        };

        if (editPanelControls.elementId) {
            Studio.HierarchyItemDataAccessor.update(hierarchyId, item)
                .then(() => {

                    if (bypassThen) {
                        return;
                    }
                    setLeaseInfo(undefined);
                    onCancelChanges();
                    fetchHierarchy(hierarchyId);
                }).catch((exception) => {
                    const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception);
                    if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.StudioNoValidLeaseForSave)) {
                        setShowNoLeaseDialog({ visible: true, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.StudioNoValidLeaseForSave] });
                    } else {
                        onCancelChanges();
                        setError(errorInfo);
                    }
                });
            return;
        }

        Studio.HierarchyItemDataAccessor.create(hierarchyId, item)
            .then(() => {
                if (bypassThen) {
                    return;
                }
                onCancelChanges();
                fetchHierarchy(hierarchyId);
            }).catch((exception) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, exception);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.StudioCreateLeaseInvalid)) {
                    setShowNoLeaseDialog({ visible: true, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.StudioCreateLeaseInvalid] });
                } else {
                    onCancelChanges();
                    setError(errorInfo);
                }
            });
    };

    const handleDeleteLease = () => {
        deleteLease();
    };

    const handleStillHere = () => {
        getLease(editPanelControls.elementId);
    };

    const onActive = () => {
        getLease(editPanelControls.elementId);
    }

    const onIdle = () => {
        setForceSaveHierarchyItem(canEdit);
    };

    const handleGone = () => {
        onCancelChanges();
        fetchHierarchy(hierarchyId);
    };

    const onRowExpanding = ({ key }: { key: string }) => {
        setExpandedRowKeys((currentKeys) => currentKeys.concat(key));
    };
    const onRowCollapsing = ({ key }: { key: string }) => {
        setExpandedRowKeys((currentKeys) => currentKeys.filter((expandedKey) => expandedKey !== key));
    };
    //#endregion

    return (
        <>
            <Wrapper>
                <WrapperContent>
                    <PageTitle>
                        <Heading2>
                            <Button btnbase="iconbuttons" btntype="medium_transparentmain" icon={<ArrowBack />} onClick={() => navigate(historyDelta)}></Button>
                            {selectedHierarchyDefinition?.name}, {selectedMoment?.name}
                        </Heading2>
                    </PageTitle>
                    <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        {showResults.visible && selectedMoment?.id && selectedHierarchyDefinition?.hierarchyDefinitionId && (
                            showResults.isBusy
                                ? <LoadIndicator variant={IndicatorSize.large} align='center' />
                                : <ResetZIndex>
                                    <Bar look='toolbar'>
                                        <Bar start>{renderNewButton(selectedMoment?.closed || !UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_CreateStudioTopLevelEntity, ActionType.Create))}</Bar>
                                        <Bar end>
                                            <div style={{ width: '250px' }}>
                                                <SelectElement<Domain.Shared.ElementDefinition>
                                                    id='select-studio-hierarchy-type'
                                                    displayExpr='name'
                                                    optionItems={types.values}
                                                    value={types.selectedType}
                                                    clearable={true}
                                                    customOptions={(customProps) => {
                                                        const iconId = props.elementDefinitions.items[customProps.id].icon;
                                                        return typeInputCustomItem({
                                                            ...customProps,
                                                            color: props.elementDefinitions.items[customProps.id].color,
                                                            icon: props.elementDefinitions.items[customProps.id].icon,
                                                            iconContent: props.icons.items[iconId]?.svg,
                                                        });
                                                    }}
                                                    customSingleValue={(customProps) => {
                                                        const iconId = props.elementDefinitions.items[customProps?.id]?.icon;
                                                        return typeInputCustomItem({
                                                            ...customProps,
                                                            color: props.elementDefinitions.items[customProps?.id]?.color,
                                                            icon: props.elementDefinitions.items[customProps?.id]?.icon,
                                                            iconContent: props.icons.items[iconId]?.svg,
                                                        }, { isFieldTemplate: true, placeholder: 'Alles' });
                                                    }}
                                                    editorSettings={{
                                                        onChange: (selectedItem) => {
                                                            setTypes(prev => ({
                                                                ...prev,
                                                                selectedType: selectedItem ? prev.values.find((item) => item.id === selectedItem.id) : undefined,
                                                            }));
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Bar>
                                    </Bar>
                                    <Section look='white'>
                                        <HierarchyTree
                                            userIdentity={props.userIdentity}
                                            elementDefinitions={props.elementDefinitions.items}
                                            hierarchyDefinition={selectedHierarchyDefinition}
                                            hierarchyItems={hierarchyItems}
                                            selectedType={types.selectedType}
                                            isMeasureMomentClosed={selectedMoment?.closed}
                                            availableTypes={types.values}
                                            users={availableUsers}
                                            onCreateEntity={(elementType: string, parentHierarchyItemId: string) => {
                                                setEditPanelControls({
                                                    isVisible: true,
                                                    elementDefinitionId: elementType,
                                                    parentElementId: parentHierarchyItemId,
                                                    elementId: null,
                                                    audit: [],
                                                });
                                            }}
                                            onEditEntity={onEditEntity}
                                            onReparentEntity={onReparentEntity}
                                            onReorderEntities={onReorderEntities}
                                            onDeleteEntity={onDeleteEntity}
                                            workflowDictionary={workflowDictionary}
                                            onRowExpanding={onRowExpanding}
                                            onRowCollapsing={onRowCollapsing}
                                            expandedRowKeys={expandedRowKeys}
                                            icons={props.icons.items}
                                        />
                                    </Section>
                                </ResetZIndex>
                        )}
                    </ErrorOverlay>
                </WrapperContent>
            </Wrapper>
            {showMissingWorkflowDialog && (
                <MissingWorkflowDialog onClose={() => setShowMissingWorkflowDialog(false)} />
            )}
            {canViewHierarchyItem &&
                <HierarchyComponent
                    elementId={elementInstance.element.elementId}
                    canEditHierarchyItem={canEditHierarchyItem}
                    audit={editPanelControls.audit}
                    fields={elementInstance.element.fields || {}}
                    attachments={elementInstance.element.attachments || []}
                    elementDefinition={selectedElementDefinition}
                    workflowDictionary={workflowDictionary}
                    userIdentity={props.userIdentity}
                    users={availableUsers}
                    leaseInfo={leaseInfo}
                    forceSaveHierarchyItem={forceSaveHierarchyItem}
                    isMeasureMomentClosed={selectedMoment?.closed}
                    workflowTemplate={elementInstance.element.elementId ? workflowDictionary[selectedElementDefinition.id] : null}
                    workflowState={elementInstance.element.elementId
                        ? {
                            workflowState: elementInstance.workflowStateId,
                            assignedUser: elementInstance.assignedUserId,
                            workflow: workflowDictionary[selectedElementDefinition.id]?.id
                        }
                        : null}
                    handleDeleteLease={handleDeleteLease}
                    handleGone={handleGone}
                    handleStillHere={handleStillHere}
                    onIdle={onIdle}
                    onActive={onActive}
                    onSaveChanges={onSaveChanges}
                    onCancelChanges={onCancelChanges}
                    deleteLease={deleteLease}
                    icons={props.icons?.items}
                />}
            {showNoLeaseDialog.visible &&
                <ModalDialog
                    id='no-lease-dialog'
                    settings={{
                        look: 'message',
                        title: '',
                        footer: <ModalDialogFooter
                            rightButtonText='Sluiten'
                            onRightButtonClick={() => setShowNoLeaseDialog({ visible: false })} />
                    }}
                >
                    <Text value={showNoLeaseDialog.message} />
                </ModalDialog>
            }

        </>
    );
};

const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Studio],
        elementDefinitions: state.elementdefinitions[ActionSource.Studio],
        users: state.users,
        workflowTemplates: state.workflowtemplates,
        measureMoments: state.measuremoments,
        hierarchyDefinitions: state.hierarchydefinitions[ActionSource.Studio],
        icons: state.icons,
    };
};

type HierarchyComponentProps = {
    canEditHierarchyItem: boolean;
    leaseInfo: Domain.Shared.AcquireLease;
    userIdentity: UserIdentity;
    audit: Domain.Dto.Shared.AuditEvent[];
    users: Domain.Shared.User[];
    isMeasureMomentClosed: boolean;
    workflowDictionary: Record<string, Domain.Shared.WorkflowTemplateWithStates>;
    elementDefinition: Domain.Shared.ElementDefinition;
    forceSaveHierarchyItem: boolean;
    fields: Record<string, string>;
    attachments: Domain.Shared.Attachment[],
    workflowTemplate: Domain.Shared.WorkflowTemplateWithStates;
    workflowState: Domain.Shared.AbstractElementWorkflowStatus;
    elementId: string;
    handleGone: () => void;
    onIdle: () => void;
    onActive: () => void,
    handleStillHere: () => void;
    handleDeleteLease: () => void;
    onSaveChanges: (fields: Record<string, ValueType>,
        workflowState: Domain.Shared.AbstractElementWorkflowStatus,
        attachments: Domain.Shared.Attachment[],
        bypassThen: boolean) => void;
    onCancelChanges: () => void;
    deleteLease: () => void;
    icons?: Record<string, Domain.Shared.SvgIcon>;
}

const HierarchyComponent: React.FC<HierarchyComponentProps> = (props) => {
    const {
        canEditHierarchyItem,
        leaseInfo,
        userIdentity,
        fields,
        attachments,
        audit,
        users,
        isMeasureMomentClosed,
        workflowTemplate,
        workflowState,
        elementDefinition,
        forceSaveHierarchyItem,
        elementId,
        handleGone,
        onIdle,
        onActive,
        handleStillHere,
        handleDeleteLease,
        onSaveChanges,
        onCancelChanges,
        deleteLease,
        icons
    } = props;

    const commonProps = {
        userIdentity,
        users,
        fields,
        attachments,
        audit,
        isMeasureMomentClosed,
        workflowTemplate,
        workflowState,
        elementDefinition,
        icons,
    };

    if (canEditHierarchyItem) {
        return (
            <LeaseWrapper leaseInfo={leaseInfo} handleGone={handleGone} onActive={onActive} onIdle={onIdle} handleStillHere={handleStillHere} handleDeleteLease={handleDeleteLease}>
                <HierarchyItem
                    {...commonProps}
                    leaseInfo={leaseInfo}
                    forceSaveHierarchyItem={forceSaveHierarchyItem}
                    mode={FormMode.Edit}
                    onSaveChanges={onSaveChanges}
                    onCancelChanges={() => {
                        onCancelChanges();
                        deleteLease();
                    }}
                />
            </LeaseWrapper>
        );
    }

    return (
        <HierarchyItem
            {...commonProps}
            mode={elementId ? FormMode.View : FormMode.AddNew}
            onSaveChanges={onSaveChanges}
            onCancelChanges={onCancelChanges}
        />);
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Studio, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Studio, data: { moduleId: module?.id, includeDetailCards: true, includeDeleted: true } }));
        },
        fetchUsers: () => {
            dispatch(UsersActionCreator.set());
        },
        fetchWorkflowTemplates: () => {
            dispatch(WorkflowTemplateActionCreator.set());
        },
        fetchMeasureMoments: () => {
            dispatch(MeasureMomentsActionCreator.set());
        },
        fetchHierarchyDefinitions: (source: ActionSource, module: Domain.Shared.Module, includeLinkDefinitions: boolean) => {
            dispatch(HierarchyDefinitionsActionCreator.set({ source: source, data: { moduleId: module.id, includeLinkDefinitions: includeLinkDefinitions } }));
        },
        fetchIcons: () => {
            dispatch(SvgIconActionCreator.set());
        }
    };
};

const getHierarchyElementDefinitions = (elementDefinitions: Record<string, Domain.Shared.ElementDefinition>, requestedIds: string[]) => {
    if (!elementDefinitions) return null;
    return Object.values(elementDefinitions).filter((elementDefinition) => requestedIds.indexOf(elementDefinition.id) >= 0);
};

const formatValueByType = (fieldValue: ValueType): string => {
    // Format all values in the way the backend expects them. 
    // That would imply InvariantCulture format for all non-string values, but so far only the Date seems to be affected.

    if (fieldValue === undefined || fieldValue === null) {
        return null;
    }

    if (fieldValue instanceof Date) {
        return DateUtils.toInvariantString(fieldValue);
    }

    const ret = wrapTablesWithDiv(fieldValue.toString());

    return ret;
};

const wrapTablesWithDiv = (html: string) => {
    return html.replace(/<table([^>]*)>/g, '<div class="table-wrapper"><table$1>').replace(/<\/table>/g, '</table></div>');
};

const Component = connect(mapStateToProps, mapDispatchToProps)(StudioHierarchyListItem);
export { Component as StudioHierarchyListItem };
