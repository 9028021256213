import DataSource from 'devextreme/data/data_source';
import { ILsColumnProps } from '../..';
import { processDataSourceData } from '../helper/datasource';

export const createCustomDataSource = (data: {}[], filter: string, columns?: ILsColumnProps[]): Promise<{}[]> => {
    data = processDataSourceData(data, columns);
    const source = new DataSource(data);
    source.paginate(false);

    if (filter && filter !== '[]') {
        source.filter(JSON.parse(filter));
    }

    if (columns) {
        source.select(columns.map(c => c.dataField));
    }

    return source.load();
};