import styled from 'styled-components';
import { Heading1, px } from '@liasincontrol/ui-basics';

const PageTitle = styled(Heading1) <{ contrastColor: string }>`
    color: ${(p) => p.contrastColor};
    font-weight: 400;
    font-size: ${px(34)};
`;

const styledComponents = { PageTitle };
export default styledComponents;