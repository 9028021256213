import React, { useCallback, useRef } from 'react';
import { withField } from '@liasincontrol/ui-basics';
import Styled from './index.styled';
import { DxDataSource } from '../..';

export const ListField = withField<{
    displayExpr: string | ((item) => string),
    placeholder?: string,
    value?: any,
    dataSource?: DxDataSource<any, any> | any[],
    items?: any[],
    itemKey?: string,
    isDisabled?: boolean,
    noDataText?: string,
    selectedLabel?: string,
    enableSearch?: boolean,
    customHeaderComponent?: React.ReactNode,
    itemTemplate?: (...params: any) => React.ReactNode,
    searchExpr?: string,
}>(
    ({ id,
        onChange,
        value: {
            displayExpr, value, items, itemKey, dataSource, placeholder, isDisabled, noDataText, selectedLabel, enableSearch, customHeaderComponent,
            itemTemplate, searchExpr
        },
    }) => {
        const builder = useRef(null);

        const onChangeCallBack = useCallback((args) => {
            // left here for debugging purposes
            //const scrollTop = builder.current.instance.scrollTop();
            onChange?.({
                displayExpr, value: args, dataSource, items, itemKey, placeholder, isDisabled, noDataText, selectedLabel, enableSearch,
                customHeaderComponent, itemTemplate, searchExpr
            });
            // builder.current.instance.scrollTo(scrollTop);
        }, [onChange, displayExpr, value, dataSource, items, itemKey, placeholder, isDisabled, noDataText, selectedLabel, enableSearch,
            customHeaderComponent, itemTemplate, searchExpr]);

        return (
            <>
                <Styled.StyledHeader>
                    {customHeaderComponent}
                </Styled.StyledHeader>
                <Styled.StyledSelected>
                    <span className="caption">{selectedLabel}: </span>
                    <span>{value.map(v => typeof displayExpr === 'string' ? v[displayExpr] : displayExpr(v))?.join(', ')}</span>
                </Styled.StyledSelected>
                <Styled.StyledList
                    ref={builder}
                    repaintChangesOnly={true}
                    id={id}
                    height={250}
                    displayExpr={displayExpr}
                    disabled={isDisabled}
                    showSelectionControls={true}
                    selectionMode='multiple'
                    selectAllMode='allPages'
                    selectedItemKeys={value}
                    items={items}
                    dataSource={dataSource}
                    pageLoadMode='scrollBottom'
                    pageLoadingText='Laden...'
                    indicateLoading={true}
                    scrollByContent={true}
                    scrollByThumb={true}
                    useNativeScrolling={true}
                    onSelectionChanged={onChangeCallBack}
                    noDataText={noDataText}
                    searchEnabled={enableSearch}
                    searchMode='contains'
                    searchTimeout={500}
                    searchEditorOptions={{
                        placeholder: 'Zoeken',
                    }}
                    searchExpr={searchExpr}
                    itemRender={itemTemplate}
                />
            </>
        )
    }
);
