import React, { useEffect, useState } from 'react';
import { ValidationUtils, FormData, ValueType, FieldsHelper, TextValidator, BasicValidator, ValidatorsDictionary, FormHelper } from '@liasincontrol/core-service';
import { LayoutField, LayoutForm, ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';
import { TemplateSelectElement, TextElement } from '@liasincontrol/ui-elements';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import * as Domain from '@liasincontrol/domain';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import * as Styled from './index.styled';
import { usePublicationSettings } from '../../../../../../helpers/PublicationContext';

type Props = {
    publicationElementDefinition: Domain.Shared.ElementDefinition,
    templateElementDefinition: Domain.Shared.ElementDefinition,
    onSave: (template: Domain.Publisher.TemplateElement) => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders the dialog for adding a new template.
 */
const TemplateAdd: React.FC<Props> = (props) => {
    const [form, setForm] = useState<FormData<string>>(initForm());
    const [publicationElement, setPublicationElement] = useState<Domain.Publisher.PublicationElement>();
    const [pageDesigns, setPageDesigns] = useState<Domain.Publisher.PageDesign[]>([]);
    const publication = usePublicationSettings();

    useEffect(() => {
        if (props.publicationElementDefinition && publication) {
            const settings = new Domain.Publisher.PublicationElement();
            FieldsHelper.mapObject<Domain.Publisher.PublicationElement>(settings, props.publicationElementDefinition.fields, publication.publication.fields);
            setPublicationElement(settings);
        }
    }, [publication, props.publicationElementDefinition]);

    useEffect(() => {
        if (publicationElement) {
            DataAccess.SiteDesigns.getPageDesigns(publicationElement.siteDesignId).then((response) => {
                setPageDesigns(response.data);
            });
        }
    }, [publicationElement]);

    const storeFormValue = (value: ValueType, systemId: keyof typeof validators) => {
        setForm((prevForm) => FormHelper.validateAndStoreFormValue<FormData<string>>(prevForm, value, validators, systemId));
    };

    const onSave = (): void => {
        const fields = {
            [templateFieldDefinitions[SystemFieldDefinitions.Pub.Name].id]: form.values[SystemFieldDefinitions.Pub.Name],
            [templateFieldDefinitions[SystemFieldDefinitions.Pub.PageDesignId].id]: form.values[SystemFieldDefinitions.Pub.PageDesignId],
        };
        const templateElement = { elementDefinitionId: props.templateElementDefinition.id, fields: fields } as Domain.Publisher.Element;
        DataAccess.Templates.createPageTemplate(publication.publication.elementId, templateElement)
            .then(response => {
                const newTemplate = new Domain.Publisher.TemplateElement();
                newTemplate.id = response.data;
                newTemplate.pageDesignId = form.values[SystemFieldDefinitions.Pub.PageDesignId]; 
                newTemplate.name = form.values[SystemFieldDefinitions.Pub.Name];
                props.onSave(newTemplate);
            });
    };

    const templateFieldDefinitions = props.templateElementDefinition?.fields?.reduce(
        (collection, item) => ({ ...collection, [item.systemId]: item }),
        {}
    ) as Record<string, Domain.Shared.FieldDefinition>;

    const nameFieldDefinition = templateFieldDefinitions[SystemFieldDefinitions.Pub.Name];
    const pageDesignFieldDefinition = templateFieldDefinitions[SystemFieldDefinitions.Pub.PageDesignId];
    const validators = getValidators(templateFieldDefinitions);

    const dialogFooter: JSX.Element = (
        <ModalDialogFooter
            leftButtonText='Annuleren'
            onLeftButtonClick={props.onCancel}
            rightButtonText='Aanmaken'
            onRightButtonClick={onSave}
            rightButtonDisabled={!form.isValid || Object.keys(form.touched).length === 0} />
    );

    return (
        <ModalDialog settings={{ look: 'interactive', title: 'Sjabloon aanmaken', width: 1024, footer: dialogFooter }}>
            <Styled.DialogContainer>
                <LayoutForm>
                    <LayoutField left={1} top={1} width={7} height={1} key={nameFieldDefinition.id}>
                        <TextElement
                            id={nameFieldDefinition.id}
                            label={nameFieldDefinition.label ? nameFieldDefinition.label : nameFieldDefinition.name}
                            editorSettings={ValidationUtils.getEditorSettings(true, false, validators, form, (val: string) => { storeFormValue(val, SystemFieldDefinitions.Pub.Name) }, SystemFieldDefinitions.Pub.Name)}
                            value={form.values[SystemFieldDefinitions.Pub.Name]} />
                    </LayoutField>
                    <LayoutField left={1} top={2} width={7} height={1} key={pageDesignFieldDefinition.id}>
                        <TemplateSelectElement
                            id={pageDesignFieldDefinition.id}
                            label={pageDesignFieldDefinition.label ? pageDesignFieldDefinition.label : pageDesignFieldDefinition.name}
                            editorSettings={ValidationUtils.getEditorSettings(true, false, validators, form, (val: string) => { storeFormValue(val, SystemFieldDefinitions.Pub.PageDesignId) }, SystemFieldDefinitions.Pub.PageDesignId)}
                            items={pageDesigns}
                            size='small'
                            value={form.values[SystemFieldDefinitions.Pub.PageDesignId]}
                        />
                    </LayoutField>
                </LayoutForm>
            </Styled.DialogContainer>
        </ModalDialog>
    );
};

const initForm = (): FormData<string> => {
    return {
        values: {
            [SystemFieldDefinitions.Pub.Name]: '',
            [SystemFieldDefinitions.Pub.PageDesignId]: '',
        },
        touched: {},
        validationErrors: {},
        isValid: true
    };
};

const getValidators = (fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>): ValidatorsDictionary => {
    if (!fieldDefinitions) return {};
    return {
        [SystemFieldDefinitions.Pub.Name]: new TextValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.Name].required,
            stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringMaxLength,
            stringMinLength: 2,
            stringType: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringType
        }),
        [SystemFieldDefinitions.Pub.PageDesignId]: new BasicValidator<string>({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.PageDesignId].required
        })
    }
};

export { TemplateAdd };
