import React, { useState } from "react";
import { Heading2, PageTitle, Section, Wrapper, WrapperContent, ErrorOverlay } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { Finance as FinanceDataAccess } from '@liasincontrol/data-service';
import { Filter } from "./Filter";
import { BudgetDevelopmentGrid } from "./BudgetDevelopmentGrid";

const BudgetDevelopment = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [data, setData] = useState<Domain.Finance.BudgetDevelopment[]>();
    const [showGrid, setShowGrid] = useState(false);

    const filterChanged = (filter: FinanceDataAccess.BudgetDevelopmentFilter) => {
        if (!filter.baseYear) return;
        setLoading(true);
        FinanceDataAccess.BudgetDevelopmentDataAccessor.getAll(filter).then((response) => {
            // problem: pivot grid allows sorting only by visible fields, we need to order by measureMomentOrder but show only measureMomentName
            // workaround: concatenate order + name into a single field that is sorted as text by the grid and also override the displayed name 
            // where we remove the concatenated prefix
            const newData = response.data.map(item => ({
                ...item,
                measureMomentName: `${item.measureMomentOrder.toString().padStart(5, '0')}___${item.measureMomentName}`
            }));
            setData(newData);
        }).catch((error) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))
        }).finally(() => {
            setShowGrid(true);
            setLoading(false);
        });
    };

    return (<Wrapper>
        <WrapperContent>
            <PageTitle>
                <Heading2>Financiële ontwikkeling</Heading2>
            </PageTitle>
            <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                <Section look='white'>
                    <Filter onFilter={filterChanged} disabled={loading} />
                </Section>
                {showGrid &&
                    <BudgetDevelopmentGrid data={data} />
                }
            </ErrorOverlay>
        </WrapperContent>
    </Wrapper>);
};

export { BudgetDevelopment as index };