import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import _ from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { UserIdentity } from '@liasincontrol/auth-service';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { ActionSource, AjaxRequestStatus, ElementDefinitionsActionCreator, ModulesActionCreator, State, UsersActionCreator, WorkflowTemplateActionCreator } from '@liasincontrol/redux-service';
import { Section, ErrorOverlay, ResetZIndex, MultiSelectItem, Bar, Button, WarningWrapper, WarningLook } from '@liasincontrol/ui-basics';
import { LoadPanel } from '@liasincontrol/ui-devextreme';
import { ConnectingDialog, SelectionDialog } from '@liasincontrol/ui-elements';
import { PublicationContext } from '../../../../../helpers/PublicationContext';
import { AssignmentsTreeList } from './AssignmentsTreeList';
import { SetWorkflowStateDialog } from './SetWorkflowStateDialog';
import { SetWorkflowDialog } from './SetWorkflowDialog';
import Helper from '../../../../_shared/PublicationItem/PublicationInformation/index.helper';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

export const enum AssigmentActions {
    'change',
    'add',
    'remove'
}

/**
 * Represents a UI component that renders the list of publication pages with workflow state for assignments.
 */
const Index: React.FC<Props> = (props) => {
    const { id: publicationId } = useParams<{ id: string }>();
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [assignDialog, setAssignDialog] = useState<{ isVisible: boolean; component?: { componentId: string; elementId: string; pageId: string; statusId: string } }>({ isVisible: false, component: undefined });
    const [redactorsDialog, setRedactorsDialog] = useState<{
        isVisible: boolean;
        progress: boolean;
        possible: MultiSelectItem[];
        assigned: MultiSelectItem[];
    }>({ isVisible: false, progress: false, assigned: [], possible: [] });

    const [assigned, setAssigned] = useState<{ loaded: boolean; userIds: string[] }>({ loaded: false, userIds: [] });
    const { settings: publication, workflowStates, setWorkflowStates } = useContext(PublicationContext);
    const [componentsWithoutTasks, setComponentsWithoutTasks] = useState<string[]>([]);
    const [workflowStateDialogInfo, setWorkflowStateDialogInfo] = useState<{ visible: boolean, pageId?: string, componentId?: string, currentStateId?: string, pageComponentId?: string }>({ visible: false });
    const [showSetWorkflowDialog, setShowSetWorkflowDialog] = useState(false);
    const [loadPanelVisible, setLoadPanelVisible] = useState(false);

    const treeElement = useRef(null);
    const possibleContributors: MultiSelectItem[] = useMemo(() => {
        return _.orderBy(props.users.items.filter(user => user.isInGroup),
            [user => _.toLower(user.name)])?.map((user) => ({ label: user.name || user.email, value: false, id: user.id }))
    }, [props.users]);

    const assignedContributors: MultiSelectItem[] = useMemo(() => {
        return _.orderBy(props.users.items.filter(user => user.isInTenant),
            [user => _.toLower(user.name)])?.map((user) => ({ label: user.name || user.email, value: false, id: user.id, disabled: !user.isInGroup }))
    }, [props.users]);

    const isAlreadyAssigned = (userId: string, userIds: string[]): boolean => {
        return (userIds.find(assigned => assigned === userId) ? true : false);
    };

    const [bulkMode, setBulkMode] = useState(false);
    const [bulkAssigmentsDialog, setbulkAssigmentsDialog] = useState<{
        isVisible: boolean;
        forDelete: boolean;
        progress: boolean;
        possible: MultiSelectItem[];
        assigned: MultiSelectItem[];
    }>({ isVisible: false, forDelete: false, progress: false, assigned: [], possible: [] });
    const [bulkAssigment, setBulkAssigment] = useState<{ elementIds: Record<string, string[]>, statusIds: string[] }>({ elementIds: {}, statusIds: [] });

    useEffect(() => {
        if (!publication) return;
        const elemDef = Object.values(props.elementDefinitions.items).find(ed => ed.systemId === Domain.SystemElementDefinitions.Pub.Publication);
        const isClosed = Helper.getPublicationIsClosed(publication, elemDef);
        if (isClosed) {
            setError({ message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed] });
        }
    }, [props.elementDefinitions, publication]);

    useEffect(() => {
        setAssigned({ loaded: false, userIds: [] });
        if (assignDialog.isVisible && assignDialog.component) {
            DataAccess.TasksManagement.getPublicationTaskUsersByPageComponent(publication.publication.elementId, assignDialog.component.pageId, assignDialog.component.statusId, assignDialog.component.componentId)
                .then((response) => {
                    const userIds = response.data;
                    setAssigned({ loaded: true, userIds: userIds });
                })
                .catch((err) => {
                    setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
                    setAssigned({ loaded: true, userIds: [] });
                });
        }
    }, [possibleContributors, assignDialog, lastRefresh]);

    useEffect(() => {
        DataAccess.TasksManagement.getWorkflowStatesForPublication(publicationId).then(async (workflowStatesResponse) => {
            const sortedStates = workflowStatesResponse.data?.sort((a, b) => a.order - b.order) || [];
            try {
                const response = await DataAccess.WorkflowTask.getComponentsWithNoOpenTasks(publicationId);
                setComponentsWithoutTasks(response.data);
            } catch (error) {
                setComponentsWithoutTasks([]);
            }
            setWorkflowStates(sortedStates);
        });
    }, [lastRefresh]);

    if (props.users.status === AjaxRequestStatus.NotSet) {
        props.fetchUsers();
        return null;
    }

    if (props.workflowTemplates.status === AjaxRequestStatus.NotSet) {
        props.fetchWorkflowTemplates();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(ActionSource.Publication, props.modules[Domain.SystemModuleDefinitions.Publisher],);
        return null;
    }

    const updateInvalidComponents = async (id: string) => {
        try {
            const response = await DataAccess.WorkflowTask.getComponentsWithNoOpenTasks(publicationId);
            setComponentsWithoutTasks(response.data);
            treeElement.current.updateInvalidComponents(id, response.data);
        } catch (error) {
            setComponentsWithoutTasks([]);
            treeElement.current.updateInvalidComponents(id, []);
        }
    };

    /**
     * Represents an event handler that triggers when assigning user to element.
     */
    const assignUsers = async (users: MultiSelectItem[], componentId, pageId, stateId, id) => {
        const postedData: Domain.Dto.Publisher.UpdatePublicationTask = {
            publicationWorkflowStateId: stateId,
            pageId: pageId,
            componentId: componentId,
            userIds: users.map((e: MultiSelectItem) => e.id),
        };

        try {
            const response = await DataAccess.TasksManagement.assignUserToPageComponent(publication.publication.elementId, postedData);
            treeElement.current.updateCell(users.length || 0, id, stateId, response.data.workflowStateId);
            setAssignDialog({ isVisible: false });
        } catch (error) {
            ApiErrorReportingHelper.generateCustomErrorMessage(error, setError, Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed, ApiErrorReportingHelper.GenericMessages.Adding);
        }

        await updateInvalidComponents(id);
    };

    /** 
     * Represents an event handlert that triggers when the user confirms changing the workflow state to a different one.
     * */
    const setWorkflowState = (workflowStateId: string) => {
        const asyncWrapper = async () => {
            try {
                setLoadPanelVisible(true);
                if (workflowStateDialogInfo.componentId && workflowStateDialogInfo.pageComponentId) {
                    await DataAccess.TasksManagement.moveComponentStatusForward(publicationId, workflowStateDialogInfo.pageId, workflowStateDialogInfo.componentId, workflowStateId);
                    treeElement.current.updateStepCell(workflowStateDialogInfo.pageComponentId, workflowStateId);
                    await updateInvalidComponents(workflowStateDialogInfo.pageComponentId);
                } else {
                    await DataAccess.TasksManagement.setPublicationWorkflowState(publicationId, workflowStateId);
                    setLastRefresh(Date.now());
                }
            } catch (error) {
                ApiErrorReportingHelper.generateCustomErrorMessage(error, setError, Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed, ApiErrorReportingHelper.GenericMessages.Saving)
            } finally {
                setLoadPanelVisible(false);
                setWorkflowStateDialogInfo({ visible: false });
            }
        }
        asyncWrapper();
    };

    /** 
     * Represents an event handlert that triggers when the user confirms changing the workflow to a different one.
     * */
    const setWorkflow = (workflowId: string) => {
        setLoadPanelVisible(true);
        DataAccess.TasksManagement.setPublicationWorkflow(publicationId, workflowId)
            .then(() => {
                setLastRefresh(Date.now());
            })
            .catch((error) => ApiErrorReportingHelper.generateCustomErrorMessage(error, setError, Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed, ApiErrorReportingHelper.GenericMessages.Saving))
            .finally(() => {
                setShowSetWorkflowDialog(false);
                setLoadPanelVisible(false);
            });
    };

    const showAssignRedactorsModal = () => {
        setRedactorsDialog((prev) => ({ ...prev, progress: true }));
        if (redactorsDialog.progress) return;

        DataAccess.Publications.getPublicationEditors(publicationId).then((response) => {
            const assigned = response.data.map(id => ({
                id: id,
                label: props.users.items.find(u => u.id === id).name,
                value: false,
            }));

            const assignedIds = assigned.map(a => a.id);
            const possible = possibleContributors.filter(u => !assignedIds.includes(u.id));

            setRedactorsDialog((prev) => ({
                ...prev,
                assigned: assigned,
                possible: possible,
                isVisible: true,
            }));
        }).catch((err) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
        }).finally(() => {
            setRedactorsDialog((prev) => ({ ...prev, progress: false }));
        });
    };

    const assignRedactors = (users: MultiSelectItem[]) => {
        setRedactorsDialog((prev) => ({ ...prev, progress: true }));
        DataAccess.Publications.setPublicationEditors(publicationId, users.map(u => u.id))
            .catch((error) => ApiErrorReportingHelper.generateCustomErrorMessage(error, setError, Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed, ApiErrorReportingHelper.GenericMessages.Adding))
            .finally(() => {
                setRedactorsDialog((prev) => ({
                    ...prev,
                    isVisible: false,
                    progress: false
                }));
            });
    };

    const assignBulkContributors = (users: MultiSelectItem[]) => {
        setbulkAssigmentsDialog((prev) => ({ ...prev, progress: true }));
        const postedData: Domain.Dto.Publisher.UserAssignmentsToPageComponents = {
            publicationWorkflowStateIds: bulkAssigment.statusIds,
            componentPerPage: bulkAssigment.elementIds,
            userIds: users.map((e: MultiSelectItem) => e.id),
        };
        const worker = bulkAssigmentsDialog.forDelete
            ? DataAccess.TasksManagement.removeUserAssignmentsFromPageComponents
            : DataAccess.TasksManagement.addUserAssignmentsToPageComponents;

        setLoadPanelVisible(true);
        worker(publication.publication.elementId, postedData)
            .catch((error) => ApiErrorReportingHelper.generateCustomErrorMessage(error, setError, Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed, ApiErrorReportingHelper.GenericMessages.Adding))
            .finally(() => { setLoadPanelVisible(false) });
        setbulkAssigmentsDialog({ isVisible: false, forDelete: false, progress: false, assigned: [], possible: [] });
    };

    const updateComponentWorkflowState = (pageId: string, componentId: string, currentStateId: string, pageComponentId: string) => {
        setWorkflowStateDialogInfo({ visible: true, pageId: pageId, componentId: componentId, currentStateId: currentStateId, pageComponentId: pageComponentId });
    };

    const getNextStatesForComponent = (): Domain.Publisher.TaskManagerWorkflowState[] => {
        const current = workflowStates.find(wfs => wfs.id === workflowStateDialogInfo.currentStateId);
        const newList = workflowStates.filter(wfs => wfs.order > current.order);
        return newList;
    }

    const forwardWorkflowStates: Domain.Publisher.TaskManagerWorkflowState[] = !workflowStateDialogInfo.componentId
        ? workflowStates?.filter(state => state.category !== Domain.Shared.WorkflowCategoryType.New)
        : getNextStatesForComponent();

    return (
        <>
            <Bar look="toolbar">
                <Bar start>
                    {!error && !bulkMode && <>
                        <Button
                            id="btn-set-workflowstate"
                            btnbase="primarybuttons"
                            btntype="medium_transparent"
                            // TODO: Handle this when we have permissions for publication workflow states management
                            //disabled={!UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions., ActionType.)}
                            onClick={() => setWorkflowStateDialogInfo({ visible: true })}>
                            WORKFLOWSTATUS WIJZIGEN
                        </Button>
                        <Button
                            id="btn-set-workflow"
                            btnbase="primarybuttons"
                            btntype="medium_transparent"
                            // TODO: Handle this when we have permissions for publication workflow states management
                            //disabled={!UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions., ActionType.)}
                            onClick={() => setShowSetWorkflowDialog(true)}>
                            WORKFLOW WIJZIGEN
                        </Button>
                        <Button
                            id="btn-set-redactors"
                            btnbase="primarybuttons"
                            btntype="medium_transparent"
                            onClick={showAssignRedactorsModal}>
                            Redacteuren
                        </Button>
                        <Button
                            id="btn-set-bulk"
                            btnbase="primarybuttons"
                            btntype="medium_transparent"
                            onClick={() => setBulkMode(true)}>
                            Bulk acties
                        </Button>
                    </>}
                    {!error && bulkMode && <>
                        <Button
                            id="btn-set-users-bulk"
                            btnbase="primarybuttons"
                            btntype="medium_transparent"
                            disabled={Object.keys(bulkAssigment.elementIds).length === 0 || bulkAssigment.statusIds.length === 0}
                            onClick={() => setbulkAssigmentsDialog((prev) => ({ ...prev, isVisible: true, forDelete: false }))}>
                            Gebruikers toevoegen
                        </Button>
                        <Button
                            id="btn-remove-users-bulk"
                            btnbase="primarybuttons"
                            btntype="medium_transparent"
                            disabled={Object.keys(bulkAssigment.elementIds).length === 0 || bulkAssigment.statusIds.length === 0}
                            onClick={() => setbulkAssigmentsDialog((prev) => ({ ...prev, isVisible: true, forDelete: true }))}>
                            Gebruikers verwijderen
                        </Button>
                        <Button
                            id="btn-set-bulk"
                            btnbase="primarybuttons"
                            btntype="medium_transparent"
                            onClick={() => {
                                setBulkAssigment({ elementIds: {}, statusIds: [] });
                                setBulkMode(false);
                                setLastRefresh(Date.now());
                            }}>
                            Bulk acties stoppen
                        </Button>
                    </>}
                </Bar>
                {!bulkMode && componentsWithoutTasks?.length > 0 &&
                    <Bar start>
                        <WarningWrapper
                            look={WarningLook.warning}
                            icon={<WarningAmberIcon />}
                            className='pt-050 pl-050'
                            messageText='Let op: Er zijn elementen in een workflowstap zonder openstaande taken voor de desbetreffende workflowstap' />
                    </Bar>
                }
            </Bar>
            <Section look='white'>
                <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    {publication && workflowStates && <ResetZIndex>
                        <AssignmentsTreeList
                            ref={treeElement}
                            bulkMode={bulkMode}
                            {...props}
                            elementDefinitions={props.elementDefinitions.items}
                            workflowStates={workflowStates}
                            publication={publication}
                            setError={setError}
                            toogleModal={
                                (componentId, elementId, pageId, statusId) =>
                                    setAssignDialog({
                                        isVisible: true,
                                        component: { componentId: componentId, elementId: elementId, pageId: pageId, statusId: statusId },
                                    })
                            }
                            initialComponentsWithoutTasks={componentsWithoutTasks}
                            setAssigments={(action: AssigmentActions, pageId: string, elementIds: string[]) => {
                                setBulkAssigment((prev) => {
                                    const pageElements = prev.elementIds;
                                    switch (action) {
                                        case AssigmentActions.change: {
                                            pageElements[pageId] = elementIds;
                                            break;
                                        }
                                        case AssigmentActions.add: {
                                            pageElements[pageId] = (pageElements[pageId] || []).concat(elementIds);
                                            break;
                                        }
                                        case AssigmentActions.remove: {
                                            pageElements[pageId] = (pageElements[pageId] || []).filter(elemId => !elementIds.includes(elemId));
                                            break;
                                        }
                                    }
                                    return { ...prev, elementIds: pageElements };
                                });
                            }}
                            setState={(selected: boolean, statusId: string) => {
                                setBulkAssigment((prev) => ({ ...prev, statusIds: selected ? _.concat(prev.statusIds, statusId) : _.pull(prev.statusIds, statusId) }));
                            }}
                            updateComponentWorkflowState={updateComponentWorkflowState}
                        />
                    </ResetZIndex>}
                    {assignDialog.isVisible && assigned.loaded && <ConnectingDialog
                        title={`Gebruikers koppelen`}
                        disableSaveButton={false}
                        listItems={possibleContributors?.filter((user) => !isAlreadyAssigned(user.id, assigned.userIds)) || []}
                        selectedItems={assignedContributors?.filter((user) => isAlreadyAssigned(user.id, assigned.userIds)) || []}
                        onCancelled={() => setAssignDialog({ isVisible: false })}
                        onConfirmed={(users) => {
                            assignUsers(users, assignDialog.component.elementId, assignDialog.component.pageId, assignDialog.component.statusId, assignDialog.component.componentId);
                        }}
                    />}
                    {workflowStateDialogInfo.visible && <SetWorkflowStateDialog
                        workflowStates={forwardWorkflowStates}
                        componentId={workflowStateDialogInfo.componentId}
                        onCancel={() => setWorkflowStateDialogInfo({ visible: false })}
                        onConfirm={setWorkflowState}
                    />}
                    {showSetWorkflowDialog && <SetWorkflowDialog
                        workflows={props.workflowTemplates.items}
                        onCancel={() => setShowSetWorkflowDialog(false)}
                        onConfirm={setWorkflow}
                    />}
                    {redactorsDialog.isVisible && redactorsDialog.possible && <ConnectingDialog
                        title={`Gebruikers koppelen`}
                        disableSaveButton={redactorsDialog.progress}
                        listItems={redactorsDialog.possible}
                        selectedItems={redactorsDialog.assigned}
                        onCancelled={() => setRedactorsDialog((prev) => ({ ...prev, isVisible: false }))}
                        onConfirmed={assignRedactors}
                    />}

                    {bulkAssigmentsDialog.isVisible && possibleContributors && <SelectionDialog
                        title={bulkAssigmentsDialog.forDelete ? 'Gebruikers verwijderen' : 'Gebruikers toevoegen'}
                        listItems={possibleContributors}
                        onCancelled={() => {
                            setbulkAssigmentsDialog((prev) => ({ ...prev, isVisible: false }));
                        }}
                        onConfirmed={assignBulkContributors}
                    />}
                </ErrorOverlay>
                <LoadPanel visible={loadPanelVisible} />
            </Section>
        </>
    );
};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Publication],
        users: state.users,
        elementDefinitions: state.elementdefinitions[ActionSource.Publication],
        workflowTemplates: {
            items: state.workflowtemplates.items,
            status: state.workflowtemplates.status,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchUsers: () => {
            dispatch(UsersActionCreator.set());
        },
        fetchElementDefinitions: (actionSource: ActionSource, module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: actionSource, data: { moduleId: module?.id } }));
        },
        fetchWorkflowTemplates: () => {
            dispatch(WorkflowTemplateActionCreator.set());
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
