import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { EdgeTab, EdgeTabs, EdgeToolbar, IDataItemProps, ToolbarNav } from '@liasincontrol/ui-basics';
import { Actions, ActionType } from '@liasincontrol/userrights-service';
import CreateIcon from '@mui/icons-material/Create';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import * as Styled from './index.styled';
import { Information } from './Information';
import { Hierarchy } from './Hierarchy';
import { HierarchyChanges } from '../..';

enum Tabs { Main = 1, Advanced = 2, }

type Props = {
    readonly publicationId: string,
    readonly sitemapNode: Domain.Publisher.SitemapNode,
    readonly selectedTemplateName: string,
    readonly selectedPublishProfiles: IDataItemProps<string>[],
    readonly availablePublishProfiles: IDataItemProps<string>[],
    readonly elementdefinitions: Record<string, Domain.Shared.ElementDefinition>,
    readonly attachment: { [x: string]: Domain.Shared.Attachment },
    readonly childTemplate?: { id: string, name: string },
    readonly rootId: string,
    readonly isVisible: boolean,
    readonly maxDeptReached: boolean,
    readonly pageDesigns: Domain.Publisher.PageDesign[],
    readonly hasAvailableTemplates: boolean,
    readonly icons: Record<string, Domain.Shared.SvgIcon>,
    readonly onToggleVisibility?: () => void,
    readonly onChangeRequest: (changes: HierarchyChanges) => void,
    readonly onNavigate: (to: string) => void,
    readonly onCreate?: (ownerId: string, name: string) => void,
    readonly onChangeTemplate: (newTemplateId: string, newTemplateName: string, pageIds: string[], parentId?: string) => void,
    readonly onChangePagePublish: (publishProfiles: IDataItemProps<string>[]) => void,
    readonly onMove?: (ownerId: string, movedPageId: string, beforePageId?: string) => void,
    readonly onBack: () => void,
    readonly onRefresh: () => void,
    readonly canPerformAction?: (action: Actions, actionType: ActionType) => boolean,
    readonly onLoadAttachment: (id: string) => Promise<Blob>,
    readonly onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    readonly onRemoveAttachment: (id: string) => void,
};

/**
 * Represents a UI component that renders the sitemap settings form.
 */
export const SitemapSettings: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Main);
    const tabCount = 2;

    return (
        <EdgeToolbar edge="left" open={props.isVisible} inModal onToggle={() => props.onToggleVisibility()}>
            <Styled.LeftSideBarPanel hasPadding={false} className="slide-panel-section">
                <EdgeTabs tabCount={tabCount}>
                    <EdgeTab title={props.sitemapNode.elementName} icon={<CreateIcon />} active={selectedTab === Tabs.Main} onClick={() => (setSelectedTab(Tabs.Main))} tabCount={tabCount}>
                        <Styled.EdgeToolbarContent>
                            {props.sitemapNode.elementId !== props.rootId && <ToolbarNav onClick={() => props.onBack()}>Terug</ToolbarNav>}
                            <Information {...props} />
                        </Styled.EdgeToolbarContent>
                    </EdgeTab>
                    <EdgeTab title="Onderliggende pagina's" icon={<AccountTreeIcon />} active={selectedTab === Tabs.Advanced} onClick={() => (setSelectedTab(Tabs.Advanced))} tabCount={tabCount} >
                        <Styled.EdgeToolbarContent>
                            {!props.maxDeptReached && <Hierarchy {...props} />}
                        </Styled.EdgeToolbarContent>
                    </EdgeTab>
                </EdgeTabs>
            </Styled.LeftSideBarPanel>
        </EdgeToolbar>
    );
};
