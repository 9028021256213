import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';
import _, { Dictionary } from 'lodash';
import { FieldsHelper, JsonUtils } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { CreateCustomDataSource, ILsColumnProps, LsTreeList } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { DataSourceControlsUtils, DataSourceSchemaData, PublicationContext } from '../../../../../../helpers';
import Styled from './index.styled';
import SharedStyled from '../../SharedControlElements/index.styled';
import { getIconSize } from '../../SharedControlElements/helper';

type Props = {
    element: Domain.Publisher.ElementNode,
    selectedElementId: string,
    elementList: Dictionary<Domain.Publisher.Element>,
    publicationElement: Domain.Publisher.PublicationElement,
    getElementDefinition: (systemElementId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onLoadAttachment: (ElementId: string) => Promise<Blob>,
    hasErrorInSettings?: (elementId: string, hasError: boolean, message?: string, keepError?: boolean) => void,
    readonly: boolean,
    icons?: Record<string, Domain.Shared.SvgIcon>,
}

type DataSourceElement = Domain.Publisher.DataSourceElement;

const mockedData: { data: any[], expanded: string[], fields: any[], isDefault: boolean } = {
    data: [
        { ElementId: '1', name: '#Veldnaam', text: '#Veldnaam', description: '#Veldnaam' },
        { ElementId: '2', name: '#Veldnaam', text: '#Veldnaam', description: '#Veldnaam' },
        { ElementId: '2_1', name: '#Veldnaam', text: '#Veldnaam', description: '#Veldnaam', ParentElementId: '2' },
        { ElementId: '2_2', name: '#Veldnaam', text: '#Veldnaam', description: '#Veldnaam', ParentElementId: '2' },
        { ElementId: '3', name: '#Veldnaam', text: '#Veldnaam', description: '#Veldnaam' },
        { ElementId: '4', name: '#Veldnaam', text: '#Veldnaam', description: '#Veldnaam' },
        { ElementId: '4_1', name: '#Veldnaam', text: '#Veldnaam', description: '#Veldnaam', ParentElementId: '4' },
        { ElementId: '4_2', name: '#Veldnaam', text: '#Veldnaam', description: '#Veldnaam', ParentElementId: '4' },
        { ElementId: '5', name: '#Veldnaam', text: '#Veldnaam', description: '#Veldnaam' },
        { ElementId: '5_1', name: '#Veldnaam', text: '#Veldnaam', description: '#Veldnaam', ParentElementId: '5' },
        { ElementId: '5_2', name: '#Veldnaam', text: '#Veldnaam', description: '#Veldnaam', ParentElementId: '5' },
    ],
    expanded: ['2'],
    fields: [
        { dataField: 'name', caption: '#Kolomnaam', dataType: "string", width: '40%' },
        { dataField: 'text', caption: '#Kolomnaam', dataType: "string" },
        { dataField: 'description', caption: '#Kolomnaam', dataType: "string" }
    ],
    isDefault: true,
};

/**
 * Represents a UI component that renders a TreeViewControl.
*/
const TreeViewControl: React.FC<Props> = (props) => {
    const { element, elementList, onLoadAttachment, getElementDefinition } = props;
    const [dataSource, setDataSource] = useState<DataSourceElement>();
    const [dataSourceData, setDataSourceData] = useState<{ data: any[], expanded: string[], fields: any[], isDefault: boolean, schema?: DataSourceSchemaData }>(mockedData);
    const pubContext = useContext(PublicationContext);
    const expandFirstRow = useRef(true);
    const treeViewElement = elementList?.[element.elementId];
    const [hasChildren, setHasChildren] = useState(false);

    const controlSettings = useMemo(() => {
        if (!treeViewElement) return;
        const definition = getElementDefinition(treeViewElement.elementDefinitionSystemId, treeViewElement.elementDefinitionId);
        const controlSettings = new Domain.Publisher.TreeViewControl();
        FieldsHelper.mapObject<Domain.Publisher.TreeViewControl>(controlSettings, definition.fields, treeViewElement.fields);
        return controlSettings;
    }, [treeViewElement]);

    useEffect(() => {
        const loadDataSourceAsync = async () => {
            if (controlSettings?.dataSourceId) {
                const dsElement = await pubContext.loadDataSourceElement(controlSettings.dataSourceId)
                setDataSource(dsElement);
            } else {
                setDataSource(undefined);
            }
        };
        loadDataSourceAsync();
    }, [controlSettings?.dataSourceId]);

    useEffect(() => {
        props.hasErrorInSettings?.(element.elementId, false);
        if (!dataSource?.id || !dataSource?.dataFileId || !dataSource?.schemaFileId || dataSource?.failedRefresh) {
            if (dataSource?.failedRefresh) props.hasErrorInSettings?.(element.elementId, true, 'Fout opgetreden bij het verversen.', true);
            setDataSourceData(mockedData);
        } else {
            const loadBlobsAsync = async () => {
                try {
                    const responses = await Promise.all([
                        onLoadAttachment(dataSource.dataFileId),
                        onLoadAttachment(dataSource.schemaFileId)
                    ]);
                    const blobs = await Promise.all([
                        responses[0].text(),
                        responses[1].text()
                    ]);

                    const schemaData = JSON.parse(blobs[1]);
                    const validColumns = DataSourceControlsUtils.hasDataSourceRequiredColumns(schemaData, ['ElementId', 'ParentElementId']);

                    if (validColumns) {
                        setDataSourceData({
                            data: JSON.parse(blobs[0]),
                            expanded: [],
                            fields: JsonUtils.toJson(controlSettings?.columnSettings, []),
                            isDefault: false,
                            schema: schemaData,
                        });
                    } else {
                        setDataSourceData(mockedData);
                        props.hasErrorInSettings?.(element.elementId, true, 'Verplichte Velden \'ElementId\' en/of \'ParentElementId\' zijn niet aanwezig in de databron.');
                    }
                } catch (e) {
                    props.hasErrorInSettings?.(element.elementId, true, 'Databron fout.');
                }
            };
            loadBlobsAsync();
        }
    }, [element.elementId, dataSource?.id, dataSource?.dataFileId, dataSource?.schemaFileId, onLoadAttachment]);

    const columns = dataSourceData?.isDefault ? dataSourceData?.fields : JsonUtils.toJson<ILsColumnProps[]>(controlSettings?.columnSettings, []);
    const filter = dataSourceData?.isDefault ? [] : DataSourceControlsUtils.getFilter(controlSettings.filter);
    const customSource = CreateCustomDataSource(dataSourceData?.data, columns, pagingData, filter);
    const iconSize = getIconSize(+props.publicationElement.bodyFontSize);

    const onContentReady = (e) => {
        if (!dataSourceData?.isDefault) {
            if (!props.readonly && expandFirstRow.current) {
                expandFirstRow.current = false;
                const rowKey = e.component.getKeyByRowIndex(0);
                if (rowKey) e.component.expandRow(rowKey);
            }

            const items = e.component.getDataSource().items();
            setHasChildren(items?.some(x => x.children.length));
        }
    }

    return (<>
        {controlSettings.title && <SharedStyled.Title h3FontSize={props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}>{controlSettings.title}</SharedStyled.Title>}
        <Styled.HierarchyWrapper
            primaryColor={props.publicationElement.primaryColor}
            primaryTextColor={props.publicationElement.primaryContrastColor}
            textColor={props.publicationElement.bodyFontColor}
            textFontSize={props.publicationElement.bodyFontSize}
            editMode={false}
        >
            <LsTreeList
                showExpandCollapse={hasChildren}
                dataStructure="plain"
                dataSource={customSource}
                keyExpr='ElementId'
                parentIdExpr='ParentElementId'
                showBorders={true}
                showColumnLines={true}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                noDataText='Geen gegevens beschikbaar'
                scrolling={{ mode: 'standard' }}
                columns={columns}
                expandNodesOnFiltering={false}
                onContentReady={onContentReady}
                icons={props.icons}
                iconSize={iconSize}
            />
        </Styled.HierarchyWrapper>
    </>
    );
}

const pagingData = { enabled: false, currentPage: 0, pageSize: AppSettingsService.getAppSettings().Publisher.Controls.DataTable.PageSize, totalCount: 0 };

export default React.memo(TreeViewControl, (prevProps, nextProps) => {
    return _.isEqual(prevProps.elementList[prevProps.element.elementId], nextProps.elementList[nextProps.element.elementId])
        && prevProps.readonly === nextProps.readonly && prevProps.icons === nextProps.icons;
});
