import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { ILsPivotTableProps } from "../pivottable";

export const CreateDataSource = (props: ILsPivotTableProps): PivotGridDataSource => {
    const source = new PivotGridDataSource({
        fields: props.fields,
        store: props.data,
    });

    if (props.filterValue && props.filterValue !== null && props.filterValue.length > 0) {
        source.filter(props.filterValue);
    }

    return source;
}
