import React, { useState } from 'react';
import * as DxBarChart from 'devextreme-react/chart';
import DataGrid from 'devextreme-react/data-grid';
import {
    IChartTooltip,
    IChartTitle,
    IChartLoadingIndicator,
    CreateDataSource,
    getLiasPalette,
} from '../shared';

import { IMargin } from '../shared';

import { LsdxBarChart, LsdxElement } from '../../..';
import { defaultBarChartSettings } from './defaults';
import { ChartPanel, Views } from '../shared/chartPanel';

/**
 * Defines the props of the PieChart component.
 */
export interface ILsBarChartProps {
    readonly adaptiveLayout?: {
        height?: number,
        keepLabels?: boolean,
        width?: number,
    },
    readonly size?: {
        width?: number;
        height?: number;
    };
    readonly palette?: string;

    readonly redrawOnResize?: boolean;
    readonly chartTitle?: string;
    readonly pieChartData: DxBarChart.ISeriesProps;
    readonly animationData?: DxBarChart.IAnimationProps;
    readonly legendData?: DxBarChart.ILegendProps;

    readonly tooltipData?: IChartTooltip;
    readonly titleData?: IChartTitle;
    readonly loadingIndicator?: IChartLoadingIndicator;
    readonly margin?: IMargin,

    readonly minDiameter?: number,

    readonly type: 'bar',
    /**
     * Defines the piechart series data collection.
     */
    readonly data: [];

    /**
     * Defines the filter object. 
     */
    readonly filterValue?: string | Array<any> | Function;

    readonly centerText?: string;

    readonly onInitialized?: (e: { component?: LsdxBarChart | undefined; element?: LsdxElement | undefined; }) => any,

    readonly textColor?: string;

    readonly textFontSize?: string;

    readonly orientation?: boolean;

    readonly primaryColor?: string;

}

export const LsBarChart = (props: ILsBarChartProps) => {
    const [view, setView] = useState<Views>(Views.Chart);
    const chartProps = { ...defaultBarChartSettings, ...props };
    const usedPalette = getLiasPalette(chartProps.palette, chartProps.primaryColor);

    const dataSource = CreateDataSource(props);
    const columns = [{
        dataField: chartProps.pieChartData.argumentField,
        key: chartProps.pieChartData.argumentField,
        minWidth: 32,
        encodeHtml: false,
    },
    {
        dataField: chartProps.pieChartData.valueField,
        key: chartProps.pieChartData.valueField,
        minWidth: 32,
        encodeHtml: false,
    }];

    return (<>
        <ChartPanel view={view} onSwitch={setView} />
        {view === Views.Chart &&
            <DxBarChart.Chart
                adaptiveLayout={chartProps.adaptiveLayout}
                rotated={!!chartProps.orientation}
                title={chartProps.titleData}
                palette={usedPalette.simpleSet}
                dataSource={dataSource}
                redrawOnResize={chartProps.redrawOnResize}
                onInitialized={chartProps.onInitialized}
            >
                <DxBarChart.Legend {...chartProps.legendData} />
                <DxBarChart.Animation {...chartProps.animationData} />
                <DxBarChart.Series {...chartProps.pieChartData} type={chartProps.type} />
                <DxBarChart.CommonAxisSettings>
                    <DxBarChart.Label
                        wordWrap="none"
                        overlappingBehavior="stagger"
                    />
                </DxBarChart.CommonAxisSettings>
                <DxBarChart.Tooltip {...chartProps.tooltipData} />
                <DxBarChart.Size {...chartProps.size} />
            </DxBarChart.Chart>
        }
        {view === Views.Table &&
            <DataGrid
                dataSource={dataSource}
                noDataText='Geen gegevens beschikbaar'
                columnAutoWidth={true}
                showBorders={true}
                wordWrapEnabled={true}
                repaintChangesOnly={true}
                allowColumnReordering={false}
                columns={columns}
                paging={{ enabled: false }}
            />
        }
    </>);
};
