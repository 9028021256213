import React, { useEffect } from 'react';
import { StyledMenu } from './index.styled';
import { ItemClickEvent } from 'devextreme/ui/menu';
import '../../assets/dx.omnia-scheme.custom.css';

type Props = {
    activeStyle: React.CSSProperties,
    displayExpr?: string,
    dataSource: any,
    showMode: 'onHover' | 'onClick'
    onItemClick: (item: any) => void
}

export const LsMenu: React.FC<Props> = (props) => {
    
    useEffect(() => {
        document.documentElement.style.setProperty('--submenuBackgroundColor', props.activeStyle.backgroundColor);
        document.documentElement.style.setProperty('--submenuFontColor', props.activeStyle.color);
    }, []);

    const defaultOnItemClick = (e: ItemClickEvent) => {
        props.onItemClick(e.itemData);
    };

    return (
        <StyledMenu
            activeStyle={props.activeStyle}
            displayExpr={props.displayExpr || "name"}
            dataSource={props.dataSource}
            cssClass="lias-menu-navigation"
            onItemClick={defaultOnItemClick}
            hideSubmenuOnMouseLeave={props.showMode === 'onHover'}
            showFirstSubmenuMode={props.showMode === 'onHover' ? { name: 'onHover', delay: { show: 0, hide: 300 } } : { name: 'onClick', delay: { show: 0, hide: 300 } }}
            selectedExpr='selected'
        />
    );
};
