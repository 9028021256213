import React, { useEffect, useState } from 'react';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { Finance } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import { ErrorOverlay, Heading2, PageTitle, Section, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';


/**
 * Represents a UI component that renders the page with the list of JournalElementKinds.
 */
export const JournalElementKinds: React.FC = () => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [journalElementKinds, setJournalElementKinds] = useState<Domain.Finance.JournalElementKind[]>([]);

    useEffect(() => {
        Finance.JournalElementKindDataAccessor.getAll()
            .then((result) => {
                setJournalElementKinds(result.data);
            }).catch((exception) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
            });
    }, [])

    return (
        <>
            <Wrapper>
                <WrapperContent>
                    <PageTitle>
                        <Heading2>Elementsoorten</Heading2>
                    </PageTitle>
                    <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        <Section look='white'>
                            <LsGrid
                                showRowLines={true}
                                columns={availableColumns}
                                dataSource={journalElementKinds}
                                paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                                onDataError={(exception) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception))}
                            />
                        </Section>
                    </ErrorOverlay>
                </WrapperContent>
            </Wrapper>
        </>
    );
};

const availableColumns: GridColumn<Domain.Finance.JournalElementKind>[] =
    [{
        name: 'order',
        title: 'Volgorde',
        align: 'left'
    },
    {
        name: 'name',
        title: 'Naam',
    }];

export { JournalElementKinds as index };
