import { palette, px } from '@liasincontrol/ui-basics';
import styled from 'styled-components';
import { lineHeightCoefficient } from '../../SharedControlElements/index.styled';

const AttachmentList = styled.ul<{
    primaryColor?: string,
    primaryTextColor?: string,
    textColor?: string,
    textFontSize?: string,
    editMode: boolean
}>`
    padding: 0;
    list-style-type: none;   
    pointer-events: ${({ editMode }) => (editMode ? `none` : `auto`)};   
    font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px` : 'inherit')};
    line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px` : 'inherit')};
    color: ${({ textColor }) => textColor};
`;

const AttachmentItem = styled.li`
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: ${px(40)};
    padding: 0;
`;

const DownloadLink = styled.button`
    background: none;
    border: none;
    padding: ${px(8)} 0;
    display: flex;
    cursor: inherit;
    justify-content: start;
    align-items: left;
    color: ${palette.primary2};
    border-radius: ${px(8)} !important;
    width:100%;

    span {
        word-wrap: break-word;
    }

    svg {
        min-width: ${px(24)};
        margin-right: ${px(8)};
        color: ${palette.grey3b};
    }

    &:link, &:visited, &:focus, &:hover, &:active {
        color: inherit!important;
        text-decoration: none;
        line-height: inherit;
        cursor: pointer;
        outline: 0;
        background-color: ${palette.grey4};       
    }
`;

const AttachmentText = styled.span<{ nameIsALongWord: boolean }>`
    word-break: ${(p) => p.nameIsALongWord ? 'break-all' : 'break-word' };
`;

const ThumbnailWrapper = styled.div`
    max-width: ${px(320)};
    height: ${px(250)};
    margin: ${px(10)} auto;
    text-align: center;
`;

const styledComponents = {
    ThumbnailWrapper, AttachmentList, AttachmentItem, DownloadLink, AttachmentText
};

export default styledComponents;
