import React from 'react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { IconSize } from '@liasincontrol/ui-basics';
import { DxTabsIconPosition } from '../..';

type Props<T> = {
    readonly data: T[];

    readonly selectedTab?: number;

    readonly renderTitle?: (data: T, index: number) => React.ReactNode;

    readonly getContainerItemContent?: () => JSX.Element;

    readonly tabsPosition?: TabPositions;

    readonly tabIconsPosition?: DxTabsIconPosition;

    readonly iconSize?: IconSize,

}

export type TabPositions = 'top' | 'left' | 'bottom' | 'right';

export const LsTabPanel = <T extends { title: string, text?: string, icon?: string }>(props: Props<T>) => {
    return (
        <TabPanel
            id="tabPanel"
            selectedIndex={props.selectedTab}
            repaintChangesOnly={false}
            loop={true}
            animationEnabled={false}
            swipeEnabled={false}
            showNavButtons={true}
            itemTitleRender={props.renderTitle}
            tabsPosition={props.tabsPosition}
            iconPosition={props.tabIconsPosition}
        >
            {props.data.map((d, index) => {
                return (<Item
                    title={d.title}
                    icon={d.icon}
                    text={String(index)}
                    render={() => props.getContainerItemContent()}
                />
                );
            })}
        </TabPanel>
    );
};
