import styled from 'styled-components';
import { px, palette } from '@liasincontrol/ui-basics';
import { Lookup } from 'devextreme-react/lookup';

const StyledLookup = styled(Lookup)`
    width: 100%;
    max-width: 100%;
    margin: 0;
    color: ${palette.grey2};
    text-overflow: ellipsis;
    border-radius: 4px;
    background: 0 none;
    outline: 0;
    transition: border-color 0.3s ease;
    background: ${palette.white};
    white-space: nowrap;
    letter-spacing: 0.06em;
    ::-ms-clear {
        display: none;
    }

    // Override the DevExtreme default properties.
    &.dx-editor-outlined {
      background: ${palette.white};
      border: 2px solid ${palette.grey4} !important;
      border-radius: 4px;
    }

    &.dx-state-hover {
      border: 2px solid  rgba(75, 85, 246, 0.15) !important;
    }

    &.dx-state-focused, &.dx-dropdowneditor-active  {
      border: 2px solid rgba(75, 85, 246, 1) !important;
    }

    .dx-lookup-field {
        padding: ${px(12)} ${px(16)};
        min-height: ${px(20)};
    }
    
    .dx-texteditor-input {
        padding: ${px(12)} ${px(16)};
        min-height: ${px(20)}; 
    }

    .dx-lookup-arrow:before {
        color: #4b55f6;
        font-size: 24px;
        margin-right: -12px;
    }

    &.dx-state-disabled {
        opacity: 1 ;
        background-color: ${palette.grey4};
        border: 2px solid transparent !important;
        border-bottom-color: ${palette.grey4} !important;
        &:hover {
            border: 2px solid rgba(75, 85, 246, 0.15);
        }
        &:focus {
            border: 2px solid rgba(75, 85, 246, 1);
        }
        .dx-lookup-arrow::before {
            color: ${palette.grey2} !important;
        }
    }

    &.dx-lookup-empty {
        padding: 0px ${px(12)};
        min-height: ${px(20)};
        color: #999999;
    }    
`;

const styledComponents = { StyledLookup };

export default styledComponents;
