import _ from 'lodash';
import { AnyFormData, ValidatorsDictionary, ValueType } from './Types';
import { EditorSettings } from '@liasincontrol/ui-elements';

/**
 * Helper class with misc. generic validation-related methods.
 */
export class ValidationUtils {

    /**
     * Checks if a value is empty or can be considered empty.
     * @param value value to check. 
     */
    public static isEmpty(value: any): boolean {
        return _.isNull(value) || _.isUndefined(value) || !this.hasValue(value);
    }

    /**
     * 
     * @param guid Checks if the guid value can be considered empty.
     */
    public static isEmptyGuid(guid: string | null | undefined): boolean {
        const zero = `00000000-0000-0000-0000-000000000000`;
        return !guid || guid === zero;
    }

    /**
     * Gets the editor settings for a form element.
     * 
     * @param isEditing Determines if the editor is in edit mode.
     * @param validators Defines all the editors validators
     * @param form Defines the form.
     * @param onChange Defines a callback triggered when the editor is changed.
     * @param systemId Defines the field definition system id.
     */
    public static getEditorSettings =
        (isEditing: boolean, disabled: boolean, validators: ValidatorsDictionary, form: AnyFormData, onChange: (value: ValueType, fieldDefinitionId: string) => void, systemId: string): EditorSettings<ValueType> => {
            return isEditing ? {
                disabled: disabled,
                restrictions: validators[systemId] ? validators[systemId].getRestrictions() : undefined,
                validationErrors: form.touched[systemId] || !form.isValid ? form.validationErrors[systemId] : [],
                onChange: (val: ValueType) => onChange(val, systemId)
            } : null;
        };

    /**
     * Validate if string is a valid HTML.
     * @param html Defines the HTML string value to be parsed.
     * @param allowStyleAttribute Deternubes if the style attribute should be allowed.
     */
    public static validateHtml(html: string, allowStyleAttribute = false): { isValid: boolean, errors?: string[] } {
        if (ValidationUtils.isEmpty(html)) {
            return { isValid: true };
        }

        const getDescendants = (node: ChildNode, descendants?: ChildNode[]): ChildNode[] => {
            descendants = descendants || [];
            for (let i = 0; i < node.childNodes.length; i++) {
                descendants.push(node.childNodes[i])
                getDescendants(node.childNodes[i], descendants);
            }
            return descendants;
        };

        const htmlElement = document.createElement('div');
        htmlElement.innerHTML = html;

        const universalAttributes = ["class", "id"];
        if (allowStyleAttribute) {
            universalAttributes.push("style");
        }

        const allowedElements: Record<string, string[]> = {
            "": universalAttributes,
            "a": ["href", "rel", "target"],
            "br": [],
            "caption": [],
            "col": [],
            "colgroup": ["span"],
            "div": ["class"],
            "em": [],
            "h1": [],
            "h2": [],
            "h3": [],
            "h4": [],
            "h5": [],
            "h6": [],
            "li": [],
            "ol": [],
            "p": [],
            "span": [],
            "strong": [],
            "u": [],
            "table": [],
            "tbody": [],
            "td": ["colspan", "rowspan", "data-row"],
            "tfoot": [],
            "th": ["scope", "colspan"],
            "thead": [],
            "tr": [],
            "ul": [],
        };

        let descendants: ChildNode[] = [];
        htmlElement.childNodes.forEach((childNode) => {
            descendants.push(childNode);
            descendants = descendants.concat(getDescendants(childNode));
        });

        const errors: string[] = [];
        descendants.forEach((node) => {
            if (node.nodeType !== Node.ELEMENT_NODE) {
                return;
            }

            const tag = node.nodeName.toLowerCase();
            if (!_.isArray(allowedElements[tag])) {
                errors.push(`Tag '${tag}' is not allowed.`);
            } else {
                const attributeNames = (node as Element).getAttributeNames();
                attributeNames.forEach((attributeName) => {
                    if (!universalAttributes.includes(attributeName) && !allowedElements[tag].includes(attributeName)) {
                        errors.push(`Attribute '${attributeName}' is not allowed on element '${tag}'.`);
                    }
                });
            }
        });

        return { isValid: errors.length < 1, errors: errors };
    }

    /**
     * Validate if string is a valid JSON.
     * @param json Defines the JSON string value to be parsed.
     */
    public static validateJson(json: string): { isValid: boolean, errors?: string[] } {
        if (ValidationUtils.isEmpty(json)) {
            return { isValid: true };
        }

        try {
            JSON.parse(json);
        } catch (ex) {
            return { isValid: false, errors: [ex.message] };
        }

        return { isValid: true };
    }

    /**
     * Validate if string is a valid hyperLink.
     * @param json Defines the hyperLink string value to be parsed.
     */
    public static validateHyperlink(url: string): { isValid: boolean, errors?: string[] } {
        if (ValidationUtils.isEmpty(url)) {
            return { isValid: true };
        }

        const regex = /^(https?:\/\/([-A-Z0-9.]+)(\/[-A-Z0-9+&@#/%=~_|!:,.;]*)?(?:\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?(\?[A-Z0-9+&@#/%=~_|!:,.;]*=[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}(?:&[A-Z0-9+&@#/%=~_|!:,.;]*=[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})*)?)?$/i;
        const isMatched = regex.test(url);
        return { isValid: isMatched, errors: isMatched ? null : ['De opgegeven url is ongeldig. Zorg ervoor dat u de volledige url opgeeft, inclusief http:// of https://.'] };
    }

    /**
     * Checks if a value is empty based on it's detected type.
     * Workaround for issue of _.isEmpty() method, which fails to work correctly on Date, Number and Boolean data types.
     * @param value value to check. 
     */
    private static hasValue(value: any): boolean {
        if (_.isArray(value) || _.isString(value)) return value.length > 0;
        if (_.isDate(value)) return _.isNumber(value.getTime());
        if (_.isNumber(value)) return true;
        if (_.isBoolean(value)) return true;
        return !_.isEmpty(value);
    }
}
