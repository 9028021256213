import { cssFontNormal, px } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

const FormGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 3fr 2fr 1fr;
    grid-template-rows: repeat(2, auto);
    grid-auto-flow: row;
    grid-gap: ${px(16)} ${px(16)};
`;

const FormButton = styled.div`
    grid-row-start: 2;
`;

const FormInput = styled.div``;

const NoDataContainer = styled.div`
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    align-items: center; 
`;

const ThumbnailWrapper = styled.div`
    display: block;
    margin: 0 auto;
    grid-column: 1 / -1;
    width: ${px(240)};
    height: ${px(240)};
    text-align: center; 
`;

const NoDataContent = styled.div`
    grid-column: 1 / -1;
    margin: ${px(32)} 0 ${px(16)} 0;
    ${cssFontNormal(18, 26, 30)};
    font-style: italic;
    display: block;
`;

const Overview = styled.div`
    display: grid;
    grid-template-columns: 90% 10%;
    grid-template-rows: min-content auto ;
    grid-gap: ${px(16)} ${px(16)};
`;

const GridWrapper = styled.div`
    position: relative;
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
`;

const Details = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: minmax(${px(250)}, ${px(500)}) ;
    grid-gap: ${px(16)} ${px(16)};
`;

const DoughnutWrapper = styled.div`
    position: relative;
    grid-row: 1 / span 2;
    grid-column: 2 / span 1;
    display: flex;
    flex-direction: column;
    > svg {
        padding: ${px(10)};
        width: ${px(120)};
        height: ${px(120)};
    }
`;

const CustomSearchField = styled.div`
    width: 100%;
    display: block;
    div {
        float: right;
    }
`;

const StringCell = styled.div`
    text-align: left;
`;

const GetDetailsText = styled.div`
    ${cssFontNormal(14, 20, 30)};
    font-style: italic;
    display: block;
    margin-bottom: ${px(15)};
`;

const GetDetailsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const styledComponents = {
    FormGrid, FormButton, NoDataContainer, NoDataContent, Overview, DoughnutWrapper, CustomSearchField, Details, FormInput, StringCell,
    GetDetailsWrapper, GetDetailsText, ThumbnailWrapper, GridWrapper
};

export default styledComponents;
