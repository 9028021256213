import styled, { css, keyframes } from 'styled-components';
import { px, Button } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';

// Animations for slide- and fade-in from the top or from the right
const aniSlideInRight = keyframes`
    0% { transform: translate(200%, ${px(-8)}); opacity: 0; }
    100% { transform: translate(100%, ${px(-8)}); opacity: 1; }
`;

const aniSlideInTop = keyframes`
    0% { transform: translate(0, -200%); opacity: 0; }
    100% { transform: translate(0, -100%); opacity: 1; }
`;

const slideAnimationRight = css`
    animation: ${aniSlideInRight} 0.2s ease forwards;
`;

const slideAnimationTop = css`
    animation: ${aniSlideInTop} 0.2s ease forwards;
`;

const ButtonContainerTopRight = styled.div<{ direction?: number, buttonCount: number, verticalOffset?: number, hotizontalOffset?: number, }>`
    position: absolute;
    display: grid;
    grid-auto-flow: ${(p) => (p.direction === Domain.Publisher.Direction.Horizontal ? 'column' : 'row')};
    grid-gap: ${px(8)};
    right: ${(p) => (p.direction === Domain.Publisher.Direction.Horizontal ? '0' : `calc(-${px(36)} - 0.5rem)`)};
    top: ${(p) => (p.direction === Domain.Publisher.Direction.Horizontal ? '0' : `calc(${p.buttonCount} * ${px(36)} + ${px(16)})`)}; 
    padding: ${(p) => (p.direction === Domain.Publisher.Direction.Horizontal ? `0 0 ${px(12)} 0` : `${px(7)} 0 0 ${px(12)}`)};
    z-index: 999;
    ${(p) => (p.direction ? slideAnimationTop : slideAnimationRight)};
    margin-top: ${(p) => (p.verticalOffset ? `${p.verticalOffset}px` : '')}; 
    margin-left: ${(p) => (p.hotizontalOffset ? `${p.hotizontalOffset}px` : '')}; 
`;

const ButtonRemove = (styled(Button)`
        > span {
            padding: 0;
            height: ${px(40)};
            width: ${px(40)};
        }` ) as typeof Button;

export { ButtonContainerTopRight, ButtonRemove }