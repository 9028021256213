import React, { useEffect, useState } from 'react';
import { Dictionary } from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { FieldsHelper } from '@liasincontrol/core-service';
import { ActionSource } from '@liasincontrol/redux-service';
import { palette } from '@liasincontrol/ui-basics';
import { Features, License } from '@liasincontrol/userrights-service';
import Element from './Element';
import '../../assets/index.less';

type RendererProps = {
    readonly: boolean,
    selectedElementId?: string,
    elementdefinitions: { [service: string]: { [id: string]: Domain.Shared.ElementDefinition } },
    sitemap?: Domain.Publisher.Sitemap,
    pageDesignId: string,
    publication: Domain.Publisher.Publication,
    publicationRoot: Domain.Publisher.ElementNode,
    publicationElements: Dictionary<Domain.Publisher.Element>,
    editableElementIds?: Record<string, { editable: boolean, isLockedByOther: boolean, lockedByUser?: any }>,
    validElement?: { message: string, isValid: boolean },
    hierarchies: Domain.Shared.HierarchyMap,
    onSelectElement?: (elementId: string) => void,
    onLoadAttachment: (id: string, names?: Record<string, string>) => Promise<Blob>,
    onFieldInlineChanged?: (elementId: string, fieldId: string, value: string) => void,
    onTemplateChanged?: (operations: Domain.Publisher.Operation[]) => void,
    onRemoveElement?: (elementId: string, isControl: boolean) => void,
    onMoveElement?: (elementId: string, isControl: boolean, moveUp: boolean, steps?: number) => void,
    loadPerformanceHierarchy?: (measureMomentId: string) => void,
    loadStudioHierarchy?: (hierarchyId: string, hierarchyDefinitionId: string, measureMomentId: string) => void,
    //list of invalid elements on page
    inValidElements?: { [key: string]: { message: string, isValid: boolean, keep?: boolean } },
    hasErrorInSettings?: (elementId: string, hasError: boolean, message?: string, keepError?: boolean) => void,
    featureIsAvailable?: (feature: Features) => boolean,
    hasLicense?: (license: License) => boolean,
    icons?: Record<string, Domain.Shared.SvgIcon>,
};

/**
 * Represents a UI component that renders a publication based on layers.
 */
const Renderer: React.FC<RendererProps> = (props) => {
    const [publicationElement, setPublicationElement] = useState<Domain.Publisher.PublicationElement>();

    useEffect(() => {
        if (props.publication && props.elementdefinitions && props.elementdefinitions[ActionSource.Publication]) {
            const publicationElementDefinition = props.elementdefinitions[ActionSource.Publication][props.publication.publication.elementDefinitionId];
            const settings = mapToPublicationElement(publicationElementDefinition.fields, props.publication.publication.fields);
            setPublicationElement(settings);
        }

    }, [props.publication, props.elementdefinitions]);

    const getElementDefinition = (systemId: string, elementDefinitionId?: string): Domain.Shared.ElementDefinition => {
        if (!props.elementdefinitions) {
            return null;
        }

        if (elementDefinitionId) {
            return props.elementdefinitions[ActionSource.Publication][elementDefinitionId];
        }

        return Object.values(props.elementdefinitions[ActionSource.Publication]).find(item => item.systemId === systemId);
    };

    // get definition based on Id and element definition list (to be able to change it)
    const getDefinition = (id: string, source: ActionSource): Domain.Shared.ElementDefinition => {
        if (!props.elementdefinitions[source]) {
            return null;
        }

        return Object.values(props.elementdefinitions[source]).find((definition) => definition.id === id);
    };

    return (
        <div id='zion-html'>
            <div className={`_${props.pageDesignId}`}>
                <Element
                    key={props.publicationRoot.elementId}
                    readonly={props.readonly}
                    element={props.publicationRoot}
                    icons={props.icons}
                    publicationId={props.publication.publication.elementId}
                    publicationRoot={props.publicationRoot}
                    elementList={props.publicationElements}
                    sitemap={props.sitemap}
                    publicationElement={publicationElement}
                    editableElementIds={props.editableElementIds}
                    selectedElementId={props.selectedElementId}
                    validElement={props.validElement}
                    getElementDefinition={getElementDefinition}
                    onLoadAttachment={props.onLoadAttachment}
                    onSelect={props.onSelectElement}
                    onFieldInlineChanged={props.onFieldInlineChanged}
                    onTemplateChanged={props.onTemplateChanged}
                    onRemoveElement={props.onRemoveElement}
                    onMoveElement={props.onMoveElement}
                    hierarchies={props.hierarchies}
                    loadPerformanceHierarchy={props.loadPerformanceHierarchy}
                    getDefinition={getDefinition}
                    loadStudioHierarchy={props.loadStudioHierarchy}
                    inValidElements={props.inValidElements}
                    hasErrorInSettings={props.hasErrorInSettings}
                    featureIsAvailable={props.featureIsAvailable}
                    hasLicense={props.hasLicense}
                />
            </div>
        </div>
    );
};

/**
 * Maps the publication field definitions and values to an Publication element object.
 */
const mapToPublicationElement = (fieldDefinitions: Domain.Shared.FieldDefinition[], fieldValues: Record<string, string>): Domain.Publisher.PublicationElement => {
    const settings = new Domain.Publisher.PublicationElement();
    FieldsHelper.mapObject<Domain.Publisher.PublicationElement>(settings, fieldDefinitions, fieldValues);

    settings.bodyFontColor = settings.bodyFontColor ? settings.bodyFontColor : palette.grey2;
    settings.h1FontColor = settings.h1FontColor ? settings.h1FontColor : palette.grey2;
    settings.h2FontColor = settings.h2FontColor ? settings.h2FontColor : palette.grey2;
    settings.h3FontColor = settings.h3FontColor ? settings.h3FontColor : palette.grey2;
    settings.primaryColor = settings.primaryColor ? settings.primaryColor : palette.grey4;
    settings.primaryContrastColor = settings.primaryContrastColor ? settings.primaryContrastColor : palette.grey1;
    settings.secondaryColor = settings.secondaryColor ? settings.secondaryColor : palette.grey4;
    settings.secondaryContrastColor = settings.secondaryContrastColor ? settings.secondaryContrastColor : palette.grey1;
    settings.bodyFontSize = settings.bodyFontSize ? settings.bodyFontSize : fieldDefinitions.find(f => f.systemId === Domain.SystemFieldDefinitions.Pub.TextSize).integerStartValue.toString();
    settings.h1FontSize = settings.h1FontSize ? settings.h1FontSize : fieldDefinitions.find(f => f.systemId === Domain.SystemFieldDefinitions.Pub.H1TextSize).integerStartValue.toString();
    settings.h2FontSize = settings.h2FontSize ? settings.h2FontSize : fieldDefinitions.find(f => f.systemId === Domain.SystemFieldDefinitions.Pub.H2TextSize).integerStartValue.toString();
    settings.h3FontSize = settings.h3FontSize ? settings.h3FontSize : fieldDefinitions.find(f => f.systemId === Domain.SystemFieldDefinitions.Pub.H3TextSize).integerStartValue.toString();
    return settings;
};

export default Renderer;