
/**
 * Represents the possible filter values for the Budget overview screen.
 */
type BudgetOverviewFilterValues = {
    // Hierarchy of [base year] to [structure name] to [element name].
    filterValues: {
        [baseYear: number]: {
            [structure: string]: string
        },
    },

    // possible workflow states to filter on
    workflowStates: string[],
};

/**
 * Represents the type of the Budget Overview row being returned.
 */
enum BudgetOverviewRowType {
    Unknown="Unknown",
    Expenses="Expenses",
    Incomes="Incomes",
    Additions="Additions",
    Withdrawals="Withdrawals",
}

/**
 * Represents a row of Budget overview data.
 */
class BudgetOverviewData {

    recordName: string;

    recordType: BudgetOverviewRowType;

    pastYear2Realization: number;

    pastYear1Budget: number;

    currentActualBudget : number;

    currentRealization : number;

    currentObligations : number;

    currentBudgetRemaining : number;

    currentBudgetExhausted : number;

    futureYear1Budget : number;

    futureYear2Budget : number;

    futureYear3Budget : number;

    futureYear4Budget : number;
}

/**
 * Represents a Journal combination structure element, associated with a detail row.
 */
class StructureElement {

    code: string;

    name: string;

    kind: string;
}

/**
 * Represents a row of Budget Detail data.
 */
 class BudgetDetailData {

    accountClass: string;
    
    pastYear2Realisation: number;
    
    pastYear1Budget: number;
    
    currentActualBudget: number;
    
    currentRealization: number;
    
    currentObligations: number;
    
    currentBudgetRemaining: number;
    
    currentBudgetExhausted: number;
    
    futureYear1Budget: number;
    
    futureYear2Budget: number;
    
    futureYear3Budget: number;
    
    futureYear4Budget: number;
    
    structureElements: StructureElement[];
}

export { BudgetOverviewFilterValues as BudgetOverviewFilterTree, BudgetOverviewData, BudgetDetailData, BudgetOverviewRowType };