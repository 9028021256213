import { SystemFieldDefinitions } from '../../../Shared/FieldDefinition';
import { fieldDefinitionDecorator } from '../../../Shared/FieldDefinition/FieldDefinitionDecorator';

/**
 * Represents a server-side object describing a publication template.
 */
class TemplateElement {

    //A non-standard 'id' field is really returned by the API.
    id: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Name)
    name: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PageDesignId)
    pageDesignId: string;

    pageDesignName: string;
}

export default TemplateElement;