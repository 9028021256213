import React, { useState } from "react";
import { MultiSelectElement, SelectElement } from '@liasincontrol/ui-elements';
import { Button, IDataItemProps } from "@liasincontrol/ui-basics";
import { Finance as FinanceDataAcess } from "@liasincontrol/data-service";
import { useBaseYears } from "../../shared/hooks";
import Styled from './index.styled';

export type FilterProps = {
    disabled?: boolean;
    onFilter?: (filterState: FinanceDataAcess.BudgetDevelopmentFilter) => void;
}

type FilterUIState = {
    baseYear?: number,
    workflowStatus: IDataItemProps<string>[],
    incidental: IDataItemProps<string>[],
    showResult: string,
}

const workflowStatusItems: IDataItemProps<string>[] = [{
    label: 'Workflow',
    value: 'Workflow',
}, {
    label: 'Afgerond',
    value: 'Afgerond',
}];

const incidentalItems: IDataItemProps<string>[] = [{
    label: 'Incidenteel',
    value: 'Incidenteel',
}, {
    label: 'Structureel',
    value: 'Structureel',
}];

const resultItems: string[] = ['Inclusief', 'Exclusief'];

export const Filter: React.FC<FilterProps> = (props: FilterProps) => {
    const baseYears = useBaseYears();
    const [filterData, setFilterData] = useState<FilterUIState>({
        workflowStatus: [workflowStatusItems[1]],
        incidental: incidentalItems,
        showResult: resultItems[1],
    });

    const onFilterChanged = () => {

        //map from UI filter state to data accessor filter state
        const filterState: FinanceDataAcess.BudgetDevelopmentFilter = {
            baseYear: filterData.baseYear,

            showInWorkflow: !!filterData.workflowStatus.find(item => item.value === 'Workflow'),
            showFinal: !!filterData.workflowStatus.find(item => item.value === 'Afgerond'),

            showIncidental: !!filterData.incidental.find(item => item.value === 'Incidenteel'),
            showStructural: !!filterData.incidental.find(item => item.value === 'Structureel'),

            showResult: filterData.showResult === 'Inclusief',
        };

        props.onFilter(filterState);
    };

    return (
        <Styled.FilterContainer>
            <Styled.FilterItem grow={15}>
                <SelectElement<number>
                    id='filter-base-year'
                    label='Basisjaar'
                    placeholder='Kies...'
                    optionItems={baseYears.items}
                    value={filterData.baseYear}
                    clearable={false}
                    searchable={true}
                    editorSettings={{
                        disabled: baseYears.items?.length <= 0,
                        onChange: (val) => setFilterData(prev => ({
                            ...prev,
                            baseYear: val,
                        }))
                    }}
                />

            </Styled.FilterItem>

            <Styled.FilterItem grow={25}>
                <MultiSelectElement
                    id='filter-workflow-status'
                    label='Status'
                    placeholder='Kies...'
                    editorSettings={{
                        disabled: false,
                        onChange: (val: IDataItemProps<string>[]) => setFilterData(prev => ({
                            ...prev,
                            workflowStatus: val,
                        })),
                    }}
                    searchable={false}
                    clearable={true}
                    optionItems={workflowStatusItems}
                    value={filterData.workflowStatus}
                />
            </Styled.FilterItem>

            <Styled.FilterItem grow={25}>
                <MultiSelectElement
                    id='filter-incidental-structural'
                    label='I/S'
                    placeholder='Kies...'
                    editorSettings={{
                        disabled: false,
                        onChange: (val: IDataItemProps<string>[]) => setFilterData(prev => ({
                            ...prev,
                            incidental: val,
                        })),
                    }}
                    searchable={false}
                    clearable={true}
                    optionItems={incidentalItems}
                    value={filterData.incidental}
                />
            </Styled.FilterItem>

            <Styled.FilterItem grow={20}>
                <SelectElement<string>
                    id='filter-isresult'
                    label='Resultaat'
                    placeholder='Kies...'
                    optionItems={resultItems}
                    value={filterData.showResult}
                    clearable={false}
                    searchable={true}
                    editorSettings={{
                        disabled: false,
                        onChange: (val) => setFilterData(prev => ({
                            ...prev,
                            showResult: val,
                        }))
                    }}
                />
            </Styled.FilterItem>

            <Styled.FilterItemButton grow={15}>
                <Button disabled={!filterData.baseYear || props.disabled} btnbase="textbuttons" btntype="medium_noicon" onClick={onFilterChanged}>Ophalen</Button>
            </Styled.FilterItemButton>
        </Styled.FilterContainer>
    );
};