import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';
import { oDataResponse } from '../../Shared/oData/oDataResponse';

/**
 * Represents a data accessor of Studio hierarchies.
 */
export class HierarchyDataAccessor {

    /**
     * Gets the collection of Studio hierarchy items.
     * 
     * @param hierarchyId Defines the hierarchy unique identifier.
     * @param includeSettings True if the Studio tree settings should also be retrieved along with the tree data.
     * @param includeWorkflowState True if the Studio tree items should also contain workflow state information.
     */
    public static get = async (hierarchyId: string, includeSettings = true, includeWorkflowState = true): Promise<AxiosResponse<{ hierarchy: Domain.Studio.HierarchyItem[], settings?: Domain.Studio.StudioHierarchySettings }>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        const extraParams = includeSettings || includeWorkflowState ? `?includeSettings=${includeSettings}&includeWorkflowStates=${includeWorkflowState}` : '';
        return HttpClient.get<{ hierarchy: Domain.Studio.HierarchyItem[], settings?: Domain.Studio.StudioHierarchySettings }>(`/api/studio/hierarchy/${hierarchyId}${extraParams}`, config);
    };


    /**
     * Get all hierarchies based on a query
     * @param query The query
     * @returns A list of hierarchies
     */
    public static getV2 = async (query: string): Promise<AxiosResponse<oDataResponse<Domain.Studio.HierarchyListItem[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<oDataResponse<Domain.Studio.HierarchyListItem[]>>(`/api/studio/hierarchy${query}`, config);
    };

    /**
     * Gets a dictionary with key as measure moment id and values as hierarchy definition ids used in that measure moment.
     */
    public static getUsedHierarchies = async (): Promise<AxiosResponse<{ measureMomentId: string, hiearchyDefinitionIds: string[] }[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<{ measureMomentId: string, hiearchyDefinitionIds: string[] }[]>(`/api/studio/hierarchy/definitions/used`, config);
    };

    /**
     * Creates a hierarchy.
     * @param measureMomentId MeasureMomentId on which will the hierarchy created.
     * @param hierarchyDefinitionId HierarchyDefinitionId based on what the hierarchy will be created.
     * @returns the Hierarchy Id.
     */
    public static createEmptyHierarchy = async (measureMomentId: string, hierarchyDefinitionId: string): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<string>('/api/studio/hierarchy', { measureMomentId: measureMomentId, hierarchyDefinitionId: hierarchyDefinitionId }, config);
    };

    /**
     * Delete a hierarchy
     * @param hierarchyId The unique identifier of the hierarchy.
     */
    public static deleteHierarchy = async (hierarchyId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.delete<void>(`/api/studio/hierarchy/${hierarchyId}`, config);
    };

    /**
     * Copies/imports a hierarchy from one measure moment to another.
     * @param hierarchyDefinitionId Defines the hierarchy definition Id of the hierarchy to be copied
     * @param fromMeasureMomentId Defines the measure moment Id of the hierarchy to be copied
     * @param toMeasureMomentId Defines the measure moment Id of the target hierarchy 
     * @returns the new Hierarchy Id
     */
    public static importHierarchy = async (hierarchyDefinitionId: string, fromMeasureMomentId: string, toMeasureMomentId: string): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<string>('/api/studio/hierarchy/copy', { hierarchyDefinitionId, fromMeasureMomentId, toMeasureMomentId }, config)
    };
}

export default HierarchyDataAccessor;