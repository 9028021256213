import styled from 'styled-components';
import { cssFontBold, palette, px } from '@liasincontrol/ui-basics';
import Styled from '../../SharedControlElements/index.styled';

const AccordionWrapper = styled(Styled.GridWrapper)`
    .dx-accordion-item-title {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        font-weight: 700;
        border-bottom: 1px solid #cccccc;

        &::before{
            font-size: inherit;
            line-height: inherit;
            color: inherit; 
        }

    } 

    .dx-accordion-item-body {
        font-size:  inherit;
        line-height: inherit;
    }
`;

const Title = styled.div<{
    textColor: string,
    textFontSize: string,
    blockTitle?: boolean,
    margin?: boolean,
}>`
    display: flex;
    align-items: center;
    color: ${(p) => p.textColor && p.textColor};
    font-weight: ${(p) => p.blockTitle ? 800 : 600}; 
    font-size: ${(p) => p.textFontSize && `${p.textFontSize}px`};
    margin-bottom: ${(p) => p.margin && `${px(8)}`};
`;

const Text = styled.div<{
    headerColor: string,
    headerBackgroundColor: string,
    textColor: string,
    textFontSize: string
}>`
    color: ${(p) => p.textColor && p.textColor};
    font-size: ${(p) => p.textFontSize && `${p.textFontSize}px`};

    ol li {
        margin-bottom: ${(p) => (p.textFontSize && `${p.textFontSize}px`)};
    }

    && p, ol li, ul li {
        color: ${(p) => (p.textColor && p.textColor)};
        font-size: ${(p) => (p.textFontSize && `${p.textFontSize}px`)};
    }


    table {
        border-collapse: collapse;
        width: 100%;
        table-layout: auto;

        thead > tr {
            background-color: ${(p) => (p.headerColor && p.headerColor)};            
            font-weight: 700;
            border-bottom: 2px solid #cccccc;

            p {
                color: ${(p) => (p.headerBackgroundColor && p.headerBackgroundColor)};
            }
        }

        td, th {
            outline: 0;
            border: 1px solid #cccccc;            
            padding: 5px;

            p {
                margin-bottom: 0 !important;
                padding: 3px;
            }
        }

    }

`;

const Item = styled.div<{ centered?: boolean }>`
    white-space: pre-wrap;
    padding: ${px(16)} 0 ${px(8)};
    border-bottom: ${px(2)} solid ${palette.grey4};
    text-align: ${(p) => p.centered && 'center'};
`;

const SingleValueWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const SingleValueLabel = styled.div<{
    color: string,
    fontSize: string,
    margin?: boolean,
}>`
    margin-left: 3px;
    color: ${(p) => p.color && p.color};
    font-size: ${(p) => p.fontSize && `${p.fontSize}px`};
`;

const Container = styled.div`
    margin: 0 ${px(4)};
    border-top: ${px(2)} solid ${palette.grey4};
`;

const Heading = styled.div<{
    color: string,
}>`
    ${cssFontBold(20, 28, 60, 'Roboto')};
    font-family: Roboto;
    color: ${(p) => p.color && p.color};
    margin-bottom: ${px(16)};
`;

const Footer = styled.div`
    padding: ${px(16)} 0 ${px(8)} ${px(16)};
    text-align: center;
    font-style: italic;
`;

const styledComponents = { AccordionWrapper, Container, Item, Heading, Footer, Title, Text, SingleValueWrapper, SingleValueLabel };
export default styledComponents;
