import { NumberValidationRestriction, NumberValidator } from './NumberValidator';
import { TextValidationRestriction, TextValidator } from './TextValidator';
import { DateValidationRestriction, DateValidator } from './DateValidator';
import { AttachmentValidationRestriction, AttachmentValidator } from './AttachmentValidator';
import { BasicValidator } from './BasicValidator';
import { BaseValidationRestriction } from './BaseValidator';
import * as Domain from '@liasincontrol/domain';

/**
 * Type safe list of all possible validator data types. Must be kept in sync with the definitions below.
 * The 'any' value is used for custom validators that need to store complex data and will NOT have an equivalent in the ValidatorsDictionary below.
 */
export type ValueType = string | Date | number | boolean | any;

/**
 * Type-safe dictionary of all possible validators. Types must be kept in sync with PossibleValidatorsDataType definition.
 */
export type ValidatorsDictionary = Record<string, TextValidator | DateValidator | NumberValidator | AttachmentValidator | BasicValidator<ValueType>>;

/**
 * Type-safe dictionary of all possible validator restrictions.
 */
export type RestrictionDictionary = BaseValidationRestriction | TextValidationRestriction | NumberValidationRestriction | DateValidationRestriction | AttachmentValidationRestriction;

/**
 * TS workaround for forced conversion issue when calling a validators from a mixed validator-type array. 
 */
export type PossibleValidatorsDataType = string & Date & number & boolean;

/**
 * Default data type for form state with all possible field types.
 */
export type AnyFormData = FormData<ValueType>;

/**
 * Default data type for form state with a single field type.
 */
export type FormData<T extends ValueType> = {
    /**
     * Values for all form fields.
     */
    values: Record<string, T>,

    /**
     * Values for all form complexfields.
     */
    complex?: Domain.Shared.ComplexField[],

    /**
     * Values for all form attachments.
     */
    attachments?: Domain.Shared.Attachment[],

    /**
     * Values for form/element's workflow status.
     */
    workflow?: Domain.Shared.AbstractElementWorkflowStatus,

    /**
     * Touched/untouched status for all form fields.
     */
    touched: Record<string, boolean>,

    /**
     * Validation errors for all form fields.
     */
    validationErrors: Record<string, ValidationErrorData[]>,

    /**
     * True if the entire form has been validated correctly.
     */
    isValid: boolean
};

/**
 * The default type for validation errors.
 */
export type ValidationErrorData = {
    /**
     * The error message.
     */
    error: string,
    /**
     * Flag which indicates if the error is generated by an external validator.
     */
    isExternal?: boolean,
};

/**
 * Form information object that can be passed between form & parent control.
 */
export type FormInfo<T extends ValueType> = {
    /**
     * Values for all form fields.
     */
    values: Record<string, T>,

    /**
     * Values for all form complexfields.
     */
    complex?: Domain.Shared.ComplexField[],

    /**
     * Values for all form attachments.
     */
    attachments?: Domain.Shared.Attachment[],

    /**
     * Values for form/element's workflow status.
     */
    workflow?: Domain.Shared.AbstractElementWorkflowStatus,

    /**
     * True if the form has been validated correctly.
     */
    isValid: boolean,

    /**
     * True if the form has been touched.
     */
    isTouched?: boolean
};

/**
 * Modes in which a form can be displayed.
 */
export enum FormMode { View, Edit, AddNew, Clone }
