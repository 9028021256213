//import ReactDOM from "react-dom/client";

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { store } from '@liasincontrol/redux-service';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { UserBack } from './app/plugins/userback';
import { locale } from 'devextreme/localization';

declare global {
  interface Window { Userback: UserBack }
}

locale('nl');

window.Userback = window.Userback || {};

const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
