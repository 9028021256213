import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { IDataItemProps } from '@liasincontrol/ui-basics';
import { ActionType, Actions } from '@liasincontrol/userrights-service';
import { EdgeToolbarContent } from '../index.styled';
import { PageTitleSettings } from './PageTitle';
import { PageIconSettings } from './PageIcon';
import { PagePublicationProfilesSettings } from './PagePublicationProfiles';
import { PageTemplateSettings } from './PageTemplate';
import { PageImageSettings } from './PageImage';
import { PageDescriptionSettings } from './PageDescription';

type Props = {
    readonly rootId: string,
    readonly sitemapNode: Domain.Publisher.SitemapNode,
    readonly selectedTemplateName: string,
    readonly selectedPublishProfiles: IDataItemProps<any>[],
    readonly availablePublishProfiles: IDataItemProps<any>[],
    readonly publicationId: string,
    readonly pageDesigns: Domain.Publisher.PageDesign[],
    readonly elementdefinitions: Record<string, Domain.Shared.ElementDefinition>,
    readonly hasAvailableTemplates: boolean,
    readonly attachment: { [x: string]: Domain.Shared.Attachment },
    readonly icons: Record<string, Domain.Shared.SvgIcon>,
    onChangeTemplate: (newTemplateId: string, newTemplateName: string, pageIds: string[], parentId?: string) => void,
    onChangePagePublish: (publishProfiles: IDataItemProps<any>[]) => void,
    canPerformAction?: (action: Actions, actionType: ActionType) => boolean,
    onRefresh: () => void,
    onLoadAttachment: (id: string) => Promise<Blob>,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onRemoveAttachment: (id: string) => void,
};

enum Sections {
    PageDescriptionSettings = "PageDescriptionSettings",
    PageTitleSettings = "PageTitleSettings",
    PageTemplateSettings = "PageTemplateSettings",
    PageIconSettings = "PageIconSettings",
    PageImageSettings = "PageImageSettings",
    PagePublicationProfilesSettings = "PagePublicationProfilesSettings",
}
/**
 * Represents a UI component that renders the current page general information.
 */
export const Information: React.FC<Props> = (props) => {
    const currentImageAttachment = { attachments: props.sitemapNode.image ? [props.attachment[props.sitemapNode.image]] : null, image: props.sitemapNode.image }
    const [edited, setEdited] = useState<Sections>();

    const onUpdatePageIcon = (iconId: string) => {
        const pageChanges = {
            name: props.sitemapNode.elementName,
            description: props.sitemapNode.description,
            iconId,
            attachments: currentImageAttachment.attachments,
            image: currentImageAttachment.image
        };

        return DataAccess.SiteMap
            .updatePage(props.publicationId, props.sitemapNode.elementId, pageChanges)
            .then(props.onRefresh);
    };

    const onUpdatePageTitle = (pageTitle: string) => {
        const pageChanges = {
            name: pageTitle,
            description: props.sitemapNode.description,
            iconId: props.sitemapNode.iconId,
            attachments: currentImageAttachment.attachments,
            image: currentImageAttachment.image
        };

        return DataAccess.SiteMap
            .updatePage(props.publicationId, props.sitemapNode.elementId, pageChanges)
            .then(() => {
                props.onRefresh();
                setEdited(undefined);
            });
    };

    const onUpdateDescription = (pageDescription: string) => {
        const pageChanges = {
            description: pageDescription,
            name: props.sitemapNode.elementName,
            iconId: props.sitemapNode.iconId,
            attachments: currentImageAttachment.attachments,
            image: currentImageAttachment.image
        };

        return DataAccess.SiteMap
            .updatePage(props.publicationId, props.sitemapNode.elementId, pageChanges)
            .then(() => {
                props.onRefresh();
                setEdited(undefined);
            });
    };

    const onSaveImage = (imageChanges) => {
        const pageChanges = {
            ...imageChanges,
            name: props.sitemapNode.elementName,
            description: props.sitemapNode.description,
            iconId: props.sitemapNode.iconId
        };
        return DataAccess.SiteMap
            .updatePage(props.publicationId, props.sitemapNode.elementId, pageChanges)
            .then(() => {
                props.onRefresh();
                setEdited(undefined);
            });
    };

    return (
        <EdgeToolbarContent>
            <PageTitleSettings
                publicationId={props.publicationId}
                sitemapNode={props.sitemapNode}
                updateTitle={onUpdatePageTitle}
                isEditing={() => setEdited(Sections.PageTitleSettings)}
                disabled={!props.hasAvailableTemplates || (!!edited && edited !== Sections.PageTitleSettings)}
            />
            <PageTemplateSettings
                disabled={!props.hasAvailableTemplates || (!!edited && edited !== Sections.PageTemplateSettings)}
                elementdefinitions={props.elementdefinitions}
                onChangeTemplate={props.onChangeTemplate}
                pageDesigns={props.pageDesigns}
                publicationId={props.publicationId}
                rootId={props.rootId}
                selectedTemplateName={props.selectedTemplateName}
                sitemapNode={props.sitemapNode}
            />
            <PageDescriptionSettings
                publicationId={props.publicationId}
                sitemapNode={props.sitemapNode}
                updateDescription={onUpdateDescription}
                isEditing={() => setEdited(Sections.PageDescriptionSettings)}
                disabled={!props.hasAvailableTemplates || (!!edited && edited !== Sections.PageDescriptionSettings)}
            />
            <PageIconSettings
                sitemapNode={props.sitemapNode}
                icons={props.icons}
                disabled={!props.hasAvailableTemplates || (!!edited && edited !== Sections.PageIconSettings)}
                onUpdateIcon={onUpdatePageIcon}
            />
            <PageImageSettings
                image={props.attachment[props.sitemapNode.image]}
                disabled={!props.hasAvailableTemplates || (!!edited && edited !== Sections.PageImageSettings)}
                sitemapNode={props.sitemapNode}
                onLoadAttachment={props.onLoadAttachment}
                onRemoveAttachment={props.onRemoveAttachment}
                onUploadAttachment={props.onUploadAttachment}
                onSaveImageSettings={onSaveImage}
                isEditing={() => setEdited(Sections.PageImageSettings)}
            />
            <PagePublicationProfilesSettings
                availablePublishProfiles={props.availablePublishProfiles}
                selectedPublishProfiles={props.selectedPublishProfiles}
                onChangePagePublish={props.onChangePagePublish}
                canPerformAction={props.canPerformAction}
                disabled={!props.hasAvailableTemplates || (!!edited && edited !== Sections.PagePublicationProfilesSettings)}
            />
        </EdgeToolbarContent>
    );
};
