import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { UserIdentity } from '@liasincontrol/auth-service';
import { ApiErrorReportingHelper, FormData, FormMode, ValueType } from '@liasincontrol/core-service';
import { Shared } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import { ActionSource, AjaxRequestStatus, ElementDefinitionsActionCreator, ModulesActionCreator, State, SvgIconActionCreator } from '@liasincontrol/redux-service';
import { Bar, Button, ErrorOverlay, palette, Section, WarningLook, WarningWrapper } from '@liasincontrol/ui-basics';
import { IndicatorSize, LoadIndicator } from '@liasincontrol/ui-devextreme';
import { Actions, ActionType, UserRightsService } from '@liasincontrol/userrights-service';
import { elementDefinitionIcons } from '../../../_shared/Icons';
import { ElementDefinitionForm } from '../../ElementDefinitionForm';
import { ElementDefinitionModal } from '../../ElementDefinitionModal';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the data of a selected element definition.
 */
const Index = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>((props) => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const { id: elementDefinitionId } = useParams<{ id: string }>();
    const [elementDefinitionFormDialog, setElementDefinitionFormDialog] = useState<{ showDialog: boolean, isSaving: boolean }>({ showDialog: false, isSaving: false });
    const [elementDefinition, setElementDefinition] = useState<Domain.Shared.ElementDefinition>(undefined);
    const [form, setForm] = useState<FormData<ValueType>>(initFormData(elementDefinition));
    const [elementDefinitionNames, setElementDefinitionNames] = useState<string[]>([]);
    const [externalError, setExternalError] = useState<{ id: string, message: string }>();

    useEffect(() => {
        if (!props.elementDefinitions?.items) return;
        const selectedElementDefinition = Object.values(props.elementDefinitions.items).find(item => item.id === elementDefinitionId);
        setElementDefinition(selectedElementDefinition);
        // Set a list of all element definitions names for handling validation inside the form:
        setElementDefinitionNames(Object.values(props.elementDefinitions.items).map((ed) => ed.name));
        setForm(initFormData(selectedElementDefinition));
    }, [props.elementDefinitions])

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
        return null;
    }

    if (!props.icons || props.icons.status === AjaxRequestStatus.NotSet) {
        props.fetchIcons();
    }

    const onUpdateElementDefinition = (elementDefinition: Domain.Dto.Shared.CreateElementDefinition, elementDefinitionid: string) => {
        setElementDefinitionFormDialog(state => ({ ...state, isSaving: true }));

        let keepOpen = false;
        Shared.ElementDefinitions.update(elementDefinitionid, elementDefinition).then(() => {
            props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
        }).catch((exception) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception);

            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.ElementDefinitionNameNotUniqueEdit)) {
                const message = Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.ElementDefinitionNameNotUniqueAdd];
                keepOpen = true;
                setExternalError({
                    id: Domain.FieldDefinitions.Shared.nameFieldDefinition.id,
                    message: message,
                });
            } else {
                setError(errorInfo);
            }
        }).finally(() => {
            setElementDefinitionFormDialog({ showDialog: keepOpen, isSaving: false });
        });
    };

    return (
        <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
            <Bar look="toolbar">
                <Bar start>
                    <Button
                        id="btn-edit-element-definition"
                        btnbase="primarybuttons" btntype="medium_transparent"
                        disabled={!UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Studio, ActionType.Update) ||
                            !elementDefinition}
                        onClick={() => setElementDefinitionFormDialog({ showDialog: true, isSaving: false })}
                    >
                        Bewerken
                    </Button>
                </Bar>
            </Bar>
            {elementDefinition?.deleted &&
                <WarningWrapper
                    look={WarningLook.dangerInverted}
                    icon={<WarningAmberIcon />}
                    className='mb-100 p-025'
                    messageText='Elementdefinities is uitgeschakeld.' />
            }

            <Section look='white'>
                {elementDefinition ? (
                    <ElementDefinitionForm
                        elementDefinitionNames={elementDefinitionNames}
                        elementDefinition={elementDefinition}
                        mode={FormMode.View}
                        form={form}
                        icons={props.icons.items}
                    />
                ) : (
                    <LoadIndicator variant={IndicatorSize.default} />
                )}
            </Section>

            {elementDefinitionFormDialog.showDialog && (
                <ElementDefinitionModal
                    elementDefinition={elementDefinition}
                    onCancel={() => setElementDefinitionFormDialog({ showDialog: false, isSaving: false })}
                    onSave={onUpdateElementDefinition}
                    disableSaveButton={elementDefinitionFormDialog.isSaving}
                    elementDefinitionNames={elementDefinitionNames}
                    moduleId={props.modules[Domain.SystemModuleDefinitions.Studio].id}
                    mode={FormMode.Edit}
                    externalError={externalError}
                    icons={props.icons.items}
                />
            )}
        </ErrorOverlay>
    );
});

const initFormData = (elementDefinition?: Domain.Shared.ElementDefinition): FormData<string> => {
    return {
        values: {
            [Domain.FieldDefinitions.Shared.nameFieldDefinition.id]: elementDefinition?.name || '',
            [Domain.FieldDefinitions.Shared.descriptionFieldDefinition.id]: elementDefinition?.description || '',
            [Domain.FieldDefinitions.Studio.ElementDefinition.iconFieldDefinition.id]: elementDefinition?.icon || elementDefinitionIcons[0].iconId,
            [Domain.FieldDefinitions.Studio.ElementDefinition.iconColorFieldDefinition.id]: elementDefinition?.color || palette.grey2,
        },
        touched: {},
        validationErrors: {},
        isValid: true,
    };
};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Studio],
        elementDefinitions: state.elementdefinitions[ActionSource.Studio],
        icons: state.icons,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Studio, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Studio, data: { moduleId: module?.id, includeDetailCards: true, includeDeleted: true } }));
        },
        fetchIcons: () => {
            dispatch(SvgIconActionCreator.set());
        }
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
