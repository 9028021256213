import React, { useState } from 'react';
import { Popover } from 'devextreme-react/popover';
import { Button, ContextComponents } from '@liasincontrol/ui-basics';

type Action<T> = {
    actionName: string;
    ariaLabel: string;
    displayName: string;
    hidden?: boolean;
    action: (item: T) => void;
}

interface IProps<T> {
    keyExpr: keyof T;
    item: T;
    actions: Action<T>[];
}

export const ContextMenu = <T,>(props: IProps<T>): JSX.Element => {
    const [popoverVisible, setPopoverVisible] = useState(false);

    return (
        <>
            <Button
                id={`btn-show-context-menu-${props.item[props.keyExpr]}`}
                btnbase="iconbuttons"
                btntype="small_transparent"
                onClick={(e) => {
                    e.stopPropagation();
                    setPopoverVisible(true);
                }}
                icon={<ContextComponents.ContextMenuIcon />}
            />
            <Popover
                target={`#btn-show-context-menu-${props.item[props.keyExpr] as string}`}
                position='left'
                visible={popoverVisible}
                onHiding={() => setPopoverVisible(false)}
                shading={true}
                shadingColor="rgba(0, 0, 0, 0.2)"
                animation={{ show: { duration: 0 }, hide: { duration: 0 } }}
            >
                <ContextComponents.ListWrap>
                    {props.actions.filter(a => !a.hidden).map(a => (
                        <ContextComponents.LiBtn
                            key={`key-${props.item[props.keyExpr] as string}-${a.actionName}`}
                            aria-label={a.ariaLabel}
                            id={`btn-${a.actionName}`}
                            onClick={() => {
                                a.action(props.item);
                                setPopoverVisible(false);
                            }}
                        >
                            <ContextComponents.ListActionButton>{a.displayName}</ContextComponents.ListActionButton>
                        </ContextComponents.LiBtn>
                    ))}
                </ContextComponents.ListWrap>
            </Popover>
        </>
    );
};
