import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import _, { Dictionary } from 'lodash';
import { FieldsHelper, JsonUtils } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ThumbnailImg, useComponentBounds } from '@liasincontrol/ui-basics';
import { DEFAULT_PALETTE, ChartLabels, getRealHeight, IChartLegend, ILsColumnProps, LsPieChart } from '@liasincontrol/ui-devextreme';
import { DataSourceControlsUtils, PublicationContext } from '../../../../../../helpers';
import Styled from './index.styled';
import SharedStyled from '../../SharedControlElements/index.styled';
import { ChartLegend } from '../../SharedControlElements/constants';

type Props = {
    element: Domain.Publisher.ElementNode,
    elementList: Dictionary<Domain.Publisher.Element>,
    publicationElement: Domain.Publisher.PublicationElement,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onLoadAttachment: (id: string) => Promise<Blob>,
    hasErrorInSettings?: (elementId: string, hasError: boolean, message?: string, keepError?: boolean) => void,
}

const PieChartTypes = {
    0: 'doughnut',
    1: 'pie',
};

type ControlSettings = {
    pieChart: Domain.Publisher.PieChartControl,
    chartType: 'doughnut' | 'donut' | 'pie',
    legend: IChartLegend,
    labels: ChartLabels,
}

/**
 * Represents a UI component that renders a pie chart control.
 */
const PieChartControl: React.FC<Props> = (props) => {
    const { element, elementList, onLoadAttachment, getElementDefinition } = props;

    const [dataSource, setDataSource] = useState<Domain.Publisher.DataSourceElement>();
    const [dataSourceData, setDataSourceData] = useState<{ data: [], fields: ILsColumnProps[] }>();
    const pieChartWrapperRef = useRef<HTMLDivElement>(null);
    const { width: parentWidth } = useComponentBounds(pieChartWrapperRef);
    const pubContext = useContext(PublicationContext);

    const pieChartElement = elementList?.[element.elementId];

    const controlSettings: ControlSettings = useMemo(() => {
        if (!pieChartElement) return;
        const definition = getElementDefinition(pieChartElement.elementDefinitionSystemId, pieChartElement.elementDefinitionId);
        const settings = new Domain.Publisher.PieChartControl();
        FieldsHelper.mapObject<Domain.Publisher.PieChartControl>(settings, definition.fields, pieChartElement.fields);
        settings.maxSlices = settings.maxSlices ? Number.parseInt(settings.maxSlices as any) : 0; //convert string to number
        const chartTypeOption = FieldsHelper.mapFieldOption<Domain.Publisher.PieChartControl>(settings, 'type', definition);
        const chartType = PieChartTypes[chartTypeOption?.value || 0];
        const chartLegendOption = FieldsHelper.mapFieldOption<Domain.Publisher.PieChartControl>(settings, 'legend', definition);
        const chartLegend = ChartLegend[chartLegendOption?.value || 0];
        const chartLabelsOption = FieldsHelper.mapFieldOption<Domain.Publisher.PieChartControl>(settings, 'showLabelsId', definition);
        const chartLabelValue = (chartLabelsOption?.value || 0) as ChartLabels;
        return {
            pieChart: settings,
            chartType: chartType,
            legend: chartLegend,
            labels: chartLabelValue
        };
    }, [pieChartElement]);

    useEffect(() => {
        const loadDataSourceAsync = async () => {
            if (controlSettings.pieChart?.dataSourceId) {
                const dsElement = await pubContext.loadDataSourceElement(controlSettings.pieChart.dataSourceId)
                setDataSource(dsElement);
            } else {
                setDataSource(undefined);
            }
        };
        loadDataSourceAsync();
    }, [controlSettings.pieChart?.dataSourceId]);

    useEffect(() => {
        props.hasErrorInSettings?.(element.elementId, false);
        if (!dataSource?.dataFileId || !dataSource?.schemaFileId || dataSource?.failedRefresh) {
            if (dataSource?.failedRefresh) props.hasErrorInSettings?.(element.elementId, true, 'Fout opgetreden bij het verversen.', true);
            setDataSourceData(undefined);
        } else {
            const loadBlobsAsync = async () => {
                try {
                    const responses = await Promise.all([
                        onLoadAttachment(dataSource.dataFileId),
                        onLoadAttachment(dataSource.schemaFileId)
                    ]);
                    const blobs = await Promise.all([
                        responses[0].text(),
                        responses[1].text()
                    ]);
                    setDataSourceData({
                        data: JSON.parse(blobs[0]),
                        fields: DataSourceControlsUtils.mapsDataSourceColumnFields(JSON.parse(blobs[1]))
                    });
                } catch (e) {
                    props.hasErrorInSettings?.(element.elementId, true, 'Databron fout.');
                }
            };
            loadBlobsAsync();
        }
    }, [dataSource?.id, dataSource?.dataFileId, dataSource?.schemaFileId, onLoadAttachment]);

    if (!(controlSettings && controlSettings.pieChart.argument && controlSettings.pieChart.value && dataSourceData?.data
        && DataSourceControlsUtils.checkDataSourceForSeriesItems(controlSettings.pieChart.value, controlSettings.pieChart.argument, dataSourceData?.fields))) {
        return <SharedStyled.ThumbnailWrapper><ThumbnailImg variant="pieChart" /></SharedStyled.ThumbnailWrapper>;
    }

    const valueFormat = JsonUtils.toJson(controlSettings.pieChart?.valueFormat);
    return (
        <>
            {controlSettings.pieChart.title && <SharedStyled.Title h3FontSize={props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}>{controlSettings.pieChart.title}</SharedStyled.Title>}
            <SharedStyled.GridWrapper
                primaryColor={props.publicationElement.primaryColor}
                primaryTextColor={props.publicationElement.primaryContrastColor}
                textColor={props.publicationElement.bodyFontColor}
                textFontSize={props.publicationElement.bodyFontSize}
                editMode={false}
            >
                <Styled.CenteredDiv ref={pieChartWrapperRef}>
                    <LsPieChart
                        data={dataSourceData.data}
                        filterValue={DataSourceControlsUtils.getDataSourceFilter(controlSettings.pieChart.filter)}
                        type={controlSettings.chartType}
                        labels={controlSettings.labels}
                        pieChartData={{
                            valueField: controlSettings.pieChart.value,
                            argumentField: controlSettings.pieChart.argument,
                            label: {
                                visible: controlSettings.labels !== ChartLabels.None,
                                format: valueFormat,
                                connector: {
                                    visible: true,
                                }
                            }
                        }}
                        palette={pubContext.colorPalette || DEFAULT_PALETTE}
                        primaryColor={props.publicationElement.primaryColor}
                        tooltipData={{
                            enabled: true,
                            customizeTooltip: DataSourceControlsUtils.setPieChartTooltipText,
                            format: valueFormat,
                            interactive: true
                        }}
                        legendData={{
                            verticalAlignment: controlSettings.legend.verticalAlignment,
                            horizontalAlignment: controlSettings.legend.horizontalAlignment,
                            visible: controlSettings.legend.visible
                        }}
                        redrawOnResize={true}
                        size={parentWidth ? { width: parentWidth, height: getRealHeight(parentWidth, maxAllowedHeight) } : undefined}
                        centerText={controlSettings.pieChart.customLabel}
                        textColor={props.publicationElement.bodyFontColor}
                        textFontSize={props.publicationElement.bodyFontSize}
                        combinedSlices={{
                            maxSlices: controlSettings.pieChart.maxSlices,
                            label: controlSettings.pieChart.combinedSlicesLabel
                        }}
                    />
                </Styled.CenteredDiv>
            </SharedStyled.GridWrapper>
        </>
    );
}
const maxAllowedHeight = 400;

export default React.memo(PieChartControl, (prevProps, nextProps) =>
    _.isEqual(prevProps.elementList[prevProps.element.elementId], nextProps.elementList[nextProps.element.elementId])
);