import React, { useState, useEffect, useMemo, useContext } from 'react';
import { loremIpsum } from 'lorem-ipsum';
import _ from 'lodash';
import { AppSettingsService } from '@liasincontrol/config-service';
import { DefinitionsHelper } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ILsColumnProps, createCustomDataSource, groupCellRender, decimalFormatter, dateTimeFormatter } from '@liasincontrol/ui-devextreme';
import { HtmlViewer } from '@liasincontrol/ui-basics';
import { DataSourceControlsUtils, PublicationContext } from '../../../../../../helpers';
import SharedStyled from '../../SharedControlElements/index.styled';
import { getIconSize } from '../../SharedControlElements/helper';

type Props = {
    element: Domain.Publisher.ElementNode,
    elementList: Record<string, Domain.Publisher.Element>,
    publicationElement: Domain.Publisher.PublicationElement,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onLoadAttachment: (id: string) => Promise<Blob>,
    hasErrorInSettings?: (elementId: string, hasError: boolean, message?: string, keepError?: boolean) => void,
    icons?: Record<string, Domain.Shared.SvgIcon>,
}

type ElementContent = { title?: string | React.JSX.Element, content?: string | React.JSX.Element, type: 'default' | 'element' | 'warning' };

/**
 * Represents a UI component that renders a text control tied to a datasource.
 */
const DataSourceTextControl: React.FC<Props> = (props) => {
    const { element, elementList, onLoadAttachment, getElementDefinition } = props;
    const [text, setText] = useState<ElementContent>();
    const [dataSource, setDataSource] = useState<Domain.Publisher.DataSourceElement>();
    const [dataSourceData, setDataSourceData] = useState<{ data: any[], fields: ILsColumnProps[] }>({ data: null, fields: null });
    const pubContext = useContext(PublicationContext);
    const dataSourceTextElement = elementList?.[element.elementId];

    const iconSize = useMemo(() => getIconSize(+props.publicationElement.bodyFontSize), [props.publicationElement.bodyFontSize]);
    const defaultText = useMemo(() => loremIpsum({ count: 30, units: 'words' }), []);

    const controlSettings = DefinitionsHelper.getControlSettings(dataSourceTextElement, new Domain.Publisher.DataSourceTextControl(), getElementDefinition);

    const [titleData, columnData] = useMemo(() => {
        const titleData = dataSourceData.fields?.find(item => item.dataField === controlSettings.title);
        const columnData = dataSourceData.fields?.find(item => item.dataField === controlSettings.column);
        return [titleData, columnData];
    }, [dataSourceData.fields, controlSettings.title, controlSettings.column]);

    useEffect(() => {
        const loadDataSourceAsync = async () => {
            if (controlSettings?.dataSourceId) {
                const dsElement = await pubContext.loadDataSourceElement(controlSettings.dataSourceId);
                setDataSource(dsElement);
            } else {
                setDataSource(undefined);
            }
        };
        loadDataSourceAsync();
    }, [controlSettings?.dataSourceId]);

    useEffect(() => {
        props.hasErrorInSettings?.(element.elementId, false);
        if (!dataSource?.dataFileId || dataSource?.failedRefresh) {
            if (dataSource?.failedRefresh) props.hasErrorInSettings?.(element.elementId, true, 'Fout opgetreden bij het verversen.', true);
            setDataSourceData({ data: null, fields: null });
        } else {
            const loadBlobsAsync = async () => {
                try {
                    const responses = await Promise.all([
                        onLoadAttachment(dataSource.dataFileId),
                        onLoadAttachment(dataSource.schemaFileId)
                    ]);
                    const blobs = await Promise.all([
                        responses[0].text(),
                        responses[1].text()
                    ]);
                    const data = JSON.parse(blobs[0]);
                    setDataSourceData({
                        data: data,
                        fields: DataSourceControlsUtils.mapsDataSourceColumnFields(
                            JSON.parse(blobs[1]),
                            AppSettingsService.getAppSettings().Publisher.Controls.DataTable.NumberOfVisibleColumns
                        )
                    });
                } catch (e) {
                    props.hasErrorInSettings?.(element.elementId, true, 'Databron fout.');
                }
            };
            loadBlobsAsync();
        }
    }, [element.elementId, dataSource?.id, dataSource?.dataFileId, onLoadAttachment]);

    useEffect(() => {
        if (!dataSourceData.data || (!titleData && !columnData)) {
            setText(undefined);
            return;
        }

        createCustomDataSource(dataSourceData.data, controlSettings.filter).then((data) => {
            if (data.length !== 1 || !!!data[0]) {
                setText({ content: 'Stel het filter in zodat er slechts één record overblijft. Kies daarna de gewenste kolom.', type: 'warning' });
                return;
            }

            const renderedContent: ElementContent = { type: 'default' };
            renderedContent.title = data[0][titleData?.dataField];

            if (columnData?.groupRenderMode) {
                renderedContent.content = groupCellRender(data[0], { ...columnData, groupRenderMode: 1 }, props.icons, iconSize);
                renderedContent.type = 'element';
            } else {
                renderedContent.content = data[0][columnData?.dataField];
                renderedContent.type = 'default';
            }
            setText((prev) => ({ ...prev, ...renderedContent }));
        });
    }, [dataSourceData, columnData, titleData, controlSettings?.filter]);

    if (!text?.title && !text?.content) {
        return <SharedStyled.GridWrapper
            primaryColor={props.publicationElement.primaryColor}
            primaryTextColor={props.publicationElement.primaryContrastColor}
            textColor={props.publicationElement.bodyFontColor}
            textFontSize={props.publicationElement.bodyFontSize}
            editMode={true}>
            {defaultText}
        </SharedStyled.GridWrapper>;
    }

    return (<>
        {text.title && <SharedStyled.Title h3FontSize={props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}>
            <HtmlViewer id={`${element.elementId}-title`} value={getFormattedValue(text.title.toString(), titleData?.dataType)} />
        </SharedStyled.Title>}
        {text.content && <SharedStyled.GridWrapper
            primaryColor={props.publicationElement.primaryColor}
            primaryTextColor={props.publicationElement.primaryContrastColor}
            textColor={text.type === 'warning' ? 'red' : props.publicationElement.bodyFontColor}
            textFontSize={props.publicationElement.bodyFontSize}
            editMode={true}
            className='dx-widget custom-text-input richtext-viewer'>
            {text.type === 'element'
                ? text.content
                : <HtmlViewer id={`${element.elementId}-text`} className='dx-item-content' value={getFormattedValue(text.content.toString(), columnData?.dataType)} />
            }
        </SharedStyled.GridWrapper>}
    </>);
};

const getFormattedValue = (text, dataType) => {
    if (!text) return;
    switch (dataType) {
        case "boolean":
            return text ? 'Ja' : 'Nee';
        case "number":
            return decimalFormatter(text);
        case "datetime":
            return dateTimeFormatter(text);
        case "html":
        case "string":
        default:
            return text;
    }
};

export default React.memo(DataSourceTextControl, (prevProps, nextProps) => {
    return _.isEqual(prevProps.elementList[prevProps.element.elementId], nextProps.elementList[nextProps.element.elementId]);
});
