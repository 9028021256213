import React from 'react';
import { defaultPageSizes } from '../..';
import DataGrid, { Column, ColumnChooser, Paging, RowDragging, Selection, Position, ColumnChooserSelection, Editing }
    from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import * as TypeFormatter from '../helper/formatters';

const minColumnWidth = 32;

export type FieldNameOf<T> = keyof T & string;

export interface GridColumn<T> {
    readonly title?: string;
    readonly name?: FieldNameOf<T>;
    readonly type?: "buttons" | "selection" | "adaptive" | "detailExpand" | "groupExpand" | "drag";
    readonly hidden?: boolean;
    readonly renderCustom?: (item: any) => React.ReactElement;
    readonly width?: number | string;
    readonly order?: number;
    readonly align?: `left` | `right` | `center`;
    readonly groupIndex?: number;
    readonly groupCellRender?: (item: any) => React.ReactElement;
    readonly dataType?: "string" | "number" | "boolean" | "object" | "date" | "datetime";
    readonly formatter?: "date" | "datetime" | "integer" | "decimal";
    readonly hideInColumnChooser?: boolean;
    readonly allowSorting?: boolean;
    readonly calculateSortValue?: string | ((rowData: any) => any);
    readonly calculateDisplayValue?: string | ((rowData: any) => any);
    readonly columns?: GridColumn<T>[];
    readonly allowEditing?: boolean;
    readonly defaultSortIndex?: number;
    readonly defaultSortOrder?: "asc" | "desc";
    readonly encodeHtml?: boolean;
}
interface GridPaging {
    readonly totalCount?: number;
    readonly currentPage?: number;
    readonly pageSize?: number;
}
interface GridScrolling {
    readonly mode?: "infinite" | "standard" | "virtual";
    readonly columnRenderingMode?: "standard" | "virtual";

    readonly preloadEnabled?: boolean;
    readonly renderAsync?: boolean;
    readonly rowRenderingMode?: "standard" | "virtual";
    readonly scrollByContent?: boolean;
    readonly scrollByThumb?: boolean;
    readonly showScrollbar?: "always" | "never" | "onHover" | "onScroll";
    readonly useNative?: boolean | "auto";
}
interface GridRowDragging {
    readonly allowReordering?: boolean,
    readonly autoScroll?: boolean,
    readonly showDragIcons?: boolean,
    readonly dragDirection?: 'both' | 'horizontal' | 'vertical',
    readonly dropFeedbackMode?: 'push' | 'indicate',
}
interface Props<T> {
    /**
     * id
     */
    readonly id?: string;

    /**
     * Data source
     */
    readonly dataSource: DataSource | T[];

    /**
     * Column chooser visible
     */
    readonly enableColumnChooser?: boolean;

    /**
     * Column auto width
     */
    readonly columnAutoWidth?: boolean;

    /**
     * Set focus on specific rows by keys
     */
    readonly focusedRowKeys?: string[];

    /**
     * Set bold on specific rows by keys
     */
    readonly boldedRowKeys?: string[];

    /**
     * Column settings
     */
    readonly columns?: Array<GridColumn<T>>;

    /**
     * Paging settings
     */
    readonly paging?: GridPaging;

    /**
     * Specifies the expanded groups.
     */
    readonly expandedGroups?: Array<string>;

    /**
     * Row clicked
     */
    readonly onClickRow?: (item: T) => void;

    /**
     * Selection mode
     */
    readonly selectionMode?: 'single' | 'multiple' | null;

    /**
     * Active selection
     */
    readonly selectedRowKeys?: (string | number)[];
    readonly onSelectionChanged?: (selection: (string | number)[]) => void;

    /**
     * Message to display when the grid has no data. 
     */
    readonly noDataMessage?: string;

    readonly hideTableHeader?: boolean;

    /**
     * Searching settings
     */
    readonly searching?: boolean;
    readonly searchPanelFullWidth?: boolean;
    readonly onSearching?: (searchValue: string) => void;

    /**
     * Row Drag & drop settings
     */
    readonly rowDragging?: GridRowDragging;
    readonly onReorder?: (items: any) => void;

    /**
     * Border setting
     */
    readonly showBorders?: boolean,
    readonly showColumnLines?: boolean,
    readonly showRowLines?: boolean,

    readonly keyExpr?: string | string[];
    readonly buttonColumnId?: string;

    readonly scrolling?: GridScrolling;

    readonly allowColumnResizing?: boolean;

    readonly onDataError?: (error: any) => void;

    /**
     * Export table
    */
    readonly export?: boolean;
    readonly onExporting?: (workbook: any) => void;

    /**
     * Configures editing.
     */
    readonly editable?: boolean;
    readonly editing?: {
        mode: 'row' | 'cell' | 'form' | 'popup' | 'batch',
        allowUpdating?: boolean,
        allowDeleting?: boolean,
        allowAdding?: boolean,
        onChangesChange?: (changes) => void,
    }
}

const formatters = {
    'integer': TypeFormatter.integerFormatter,
    'decimal': TypeFormatter.decimalFormatter,
    'date': TypeFormatter.dateFormatter,
    'datetime': TypeFormatter.dateTimeFormatter,
}

export const LsGrid = <T extends {}>(props: Props<T>) => {

    const getColumns = (column: GridColumn<T>) => {
        if (column.columns) {
            return (<Column key={`group-column-${column.title}`} caption={column.title} visible={!column.hidden}>
                {column.columns.filter(column => !column.hidden).map((column) => getColumns(column))}
            </Column>);
        }
        return getSimpleColumn(column);
    };

    const getSimpleColumn = (column: GridColumn<T>) => {
        return (<Column
            key={`column-${column.name}-${column.title}`}
            width={column.width}
            minWidth={minColumnWidth}
            alignment={column.align}
            caption={column.title}
            dataField={column.name}
            type={column.type}
            cellRender={column.renderCustom}
            allowSorting={column.allowSorting}
            groupIndex={column.groupIndex}
            sortIndex={column.defaultSortIndex}
            sortOrder={column.defaultSortOrder}
            groupCellRender={column.groupCellRender}
            dataType={column.dataType}
            showInColumnChooser={!column.hideInColumnChooser}
            calculateSortValue={column.calculateSortValue}
            calculateDisplayValue={column.calculateDisplayValue}
            format={column.formatter ? formatters[column.formatter] : undefined}
            allowEditing={column.allowEditing || false}
            visible={!column.hidden}
            encodeHtml={column.encodeHtml}
        />);
    };

    return (
        <DataGrid
            id={props.id}
            className={`liasDatagrid${!!props.onClickRow ? ' row-cursor-pointer' : ''}${props.searchPanelFullWidth ? ' full-width-searchpanel' : ''}`}
            columnAutoWidth={props.columnAutoWidth || false}
            dataSource={props.dataSource}
            showBorders={props.showBorders || false}
            showColumnLines={props.showColumnLines || false}
            showRowLines={props.showRowLines || false}
            scrolling={props.scrolling}
            keyExpr={props.keyExpr}
            showColumnHeaders={!props.hideTableHeader}
            remoteOperations={true}
            hoverStateEnabled={true}
            allowColumnResizing={props.allowColumnResizing}
            columnResizingMode='nextColumn'
            repaintChangesOnly={false}
            pager={{
                displayMode: 'compact',
                allowedPageSizes: defaultPageSizes,
                showNavigationButtons: true,
                showPageSizeSelector: true,
                visible: !!props.paging,
                showInfo: true,
            }}
            onCellClick={(e) => {
                if (e.rowType === 'data' && e.column.type !== 'buttons' && !!props.onClickRow) {
                    props.onClickRow(e.data);
                }
            }}
            onContentReady={(e) => {
                //https://js.devexpress.com/Roadmap/#HeaderFilter-and-ColumnChooser-Popup-Customization
                //https://supportcenter.devexpress.com/ticket/details/t676296/datagrid-how-to-change-an-initial-position-of-a-column-chooser
                const gridInstance = e.component as any;
                const columnChooserView = gridInstance.getView("columnChooserView");
                if (!columnChooserView._popupContainer) {

                    columnChooserView._initializePopupContainer();
                    // Set manually the column chooser popup container to fix the injection of the overlay div in the dom  (move from body to a child of the setted div)
                    columnChooserView._popupContainer.option("container", "#app");
                    columnChooserView._popupContainer.option("hideOnOutsideClick", true);
                    columnChooserView._popupContainer.option("hideOnParentScroll", true);

                    columnChooserView._popupContainer.option("height", "auto");
                    columnChooserView._popupContainer.option("maxHeight", "50vh");

                    columnChooserView._popupContainer.option("showCloseButton", false);
                    columnChooserView._popupContainer.option("showTitle", false);

                    // Need an overlay panel to avoid click propagation outside of column chooser
                    columnChooserView._popupContainer.option("shading", true);
                    columnChooserView._popupContainer.option("shadingColor", "rgba(0,0,0, 0.5)");

                    //    columnChooserView._popupContainer.option("wrapperAttr", { class: 'lias-context-menu' });
                    columnChooserView.render();
                }
            }}
            onOptionChanged={(e) => {
                if (props.enableColumnChooser && e.name === "columns") {
                    const path = e.fullName.split(".");
                    const optionName = path[path.length - 1];

                    const buttonColumnId = props.buttonColumnId || 'id';
                    if (optionName === "visible" && e.value === false) {
                        const cols = e.component.getVisibleColumns();
                        const count = cols.filter((c) => c.type !== "buttons").length;

                        if (!!cols.length && count === 0) {
                            e.component.columnOption(buttonColumnId, "visible", false);
                        }
                    }
                    if (e.value === true && !e.component.columnOption(buttonColumnId, "visible")) {
                        e.component.columnOption(buttonColumnId, "visible", true);
                    }
                }
            }}
            onRowPrepared={(e) => {
                if ((props.focusedRowKeys || props.boldedRowKeys) && e.rowType === "data") {
                    if (props.focusedRowKeys && props.focusedRowKeys.includes(e.key)) {
                        e.rowElement.className += " focused-row";
                    }
                    if (props.boldedRowKeys && props.boldedRowKeys.includes(e.key)) {
                        e.rowElement.className += " bolded-row";
                    }
                }
            }}
            searchPanel={{
                visible: !!props.searching,
                placeholder: 'Zoeken...',
                searchVisibleColumnsOnly: true,
            }}
            selectedRowKeys={props.selectedRowKeys}
            onSelectionChanged={(e) => props.onSelectionChanged?.(e.selectedRowKeys)}
            onDataErrorOccurred={(e) => props.onDataError?.(e.error)}
            noDataText={props.noDataMessage || 'Geen gegevens beschikbaar'}
        >
            {props.selectionMode && <Selection allowSelectAll={false} mode={props.selectionMode} />}
            {props.rowDragging && <RowDragging {...props.rowDragging} onReorder={(e) => { e.promise = props.onReorder?.(e); }} />}
            {props.editable && <Editing mode={props.editing?.mode}
                allowUpdating={props.editing?.allowUpdating || false}
                allowAdding={props.editing?.allowAdding || false}
                allowDeleting={props.editing?.allowDeleting || false}
                startEditAction='click'
                selectTextOnEditStart={true}
                onChangesChange={props.editing?.onChangesChange}
            />}
            {props.columns && props.columns.map(getColumns)}
            <ColumnChooser title="" enabled={props.enableColumnChooser} mode='select'>
                <Position my="right top" at="right bottom" of={`${props.id ? `#${props.id}` : ''} .dx-datagrid-column-chooser-button`} collision="fit" />
                <ColumnChooserSelection allowSelectAll={false} selectByClick={true} recursive={true} />
            </ColumnChooser>
            <Paging enabled={!!props.paging} defaultPageSize={props.paging?.pageSize} />
            {/* {props.export && <Export enabled={true} allowExportSelectedData={false} />} */}
        </DataGrid >
    );
};
