import { State, ActionSource, ElementDefinitionsActionCreator, ModulesActionCreator, UsersActionCreator, AjaxRequestStatus, MeasureMomentsActionCreator } from '@liasincontrol/redux-service';
import { connect } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { SystemModuleDefinitions } from '@liasincontrol/domain';

import TextBox from "devextreme-react/text-box";
import { Performance } from '@liasincontrol/data-service';
import { FieldsHelper } from '@liasincontrol/core-service';
import { PageTitle, Heading2, Wrapper, WrapperContent, PanelGrid, MultiSelectItem, Heading3 } from '@liasincontrol/ui-basics';
import { LsGrid, GridColumn } from '@liasincontrol/ui-devextreme';
import Styled from './index.styled';
import { IconHelper } from '../../helpers/IconHelper';
import { StatisticsHelper } from '../../helpers/StatisticsHelper';
import { SelectElement } from '@liasincontrol/ui-elements';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};
type HierarchyItemElement = Domain.Performance.HierarchyItemElement & { progress: string, policyId: string };

export const Monitor: React.FC<Props> = (props) => {
    const [hierarchyItems, setHierarchyItems] = useState<Domain.Performance.HierarchyItem[]>([]);
    const [hierarchyItemElements, setHierarchyItemElements] = useState<HierarchyItemElement[]>([]);
    const [filteredHierarchyItemElements, setFilteredHierarchyItemElements] = useState<HierarchyItemElement[]>([]);
    const [policyFilters, setPolicyFilters] = useState<MultiSelectItem[]>([]);
    const availableColumns = getColumnConfiguration();
    const [progressFilters, setProgressFilters] = useState<MultiSelectItem[]>(progressFilterOptions);
    const [typeFilters, setTypeFilters] = useState<MultiSelectItem[]>([]);

    const hierarchyItemsNoPolicy = useMemo(() => (hierarchyItemElements.filter(hierarchyItem => hierarchyItem.type !== Domain.SystemElementDefinitions.Performance.Policy)),
        [hierarchyItemElements]);
    const [measureMoments, setMeasureMoments] = useState<{
        values: Domain.Shared.MomentItem[],
        selectedMeasureMoment: Domain.Shared.MomentItem,
    }>({ values: [], selectedMeasureMoment: undefined });

    const fetchHierarchy = useCallback(() => {
        if (!measureMoments.selectedMeasureMoment || !measureMoments.selectedMeasureMoment.value) {
            return;
        }
        Performance.HierarchyDataAccessor.get(measureMoments.selectedMeasureMoment.value).then((result) => {
            setHierarchyItems(result.data.hierarchy);
        });
    }, [measureMoments.selectedMeasureMoment?.value]);

    useEffect(() => {
        if (!props.elementDefinitions?.items) return;
        const typeFiltersOptions = Object.keys(props.elementDefinitions.items).map((type) => {
            const item: Domain.Shared.ElementDefinition = props.elementDefinitions.items[type];
            const systemId = item.systemId as Domain.Performance.HierarchyItemElementType;
            return {
                id: systemId,
                value: false,
                label: item.name,
            };
        }).filter((item) => item && StatisticsHelper.availableTypes.indexOf(item.id) >= 0);
        setTypeFilters(typeFiltersOptions);
    }, [props.elementDefinitions]);

    useEffect(() => {
        if (!props.elementDefinitions?.items || !props.measureMoments.items) {
            return;
        }

        const moments = props.measureMoments.items.map((moment) => ({ value: moment.id, label: moment.name, closed: moment.status.toString() === Domain.Shared.MeasureMomentStatus[Domain.Shared.MeasureMomentStatus.Closed] }));
        const firstMoment = props.measureMoments.items.find((moment) => moment.status.toString() === Domain.Shared.MeasureMomentStatus[Domain.Shared.MeasureMomentStatus.Open]);
        setMeasureMoments({
            values: moments,
            selectedMeasureMoment: measureMoments.selectedMeasureMoment?.value ? measureMoments.selectedMeasureMoment :
                {
                    value: firstMoment?.id,
                    label: firstMoment?.name,
                    closed: false,
                }
        });
        fetchHierarchy();
    }, [props.elementDefinitions, props.measureMoments, fetchHierarchy]);

    useEffect(() => {
        const findPolicyIdOfPerformanceItem = (hierarchyItem: HierarchyItemElement, collection: HierarchyItemElement[]) => {
            let currentItem = hierarchyItem;
            while (currentItem.parentId) {
                if (currentItem?.parentId === '0')
                    return { ...hierarchyItem, policyId: currentItem.id };
                // eslint-disable-next-line no-loop-func
                const parentItem = collection.find(item => item.id === currentItem.parentId);
                if (!!parentItem.policyId)
                    return { ...hierarchyItem, policyId: parentItem.policyId };
                currentItem = parentItem;
            }
            return { ...hierarchyItem, policyId: undefined };
        };

        const data = hierarchyItems?.map((item: Domain.Performance.HierarchyItem) => {
            const elementItem = new Domain.Performance.HierarchyItemElement();
            FieldsHelper.mapObject<Domain.Performance.HierarchyItemElement>(elementItem, props.elementDefinitions.items[item.element.elementDefinitionId].fields, item.element.fields);
            elementItem.id = item.element.elementId;
            if (item.parentHierarchyItemId) {
                elementItem.parentId = item.parentHierarchyItemId;
            } else {
                elementItem.parentId = '0';
            }
            elementItem.type = props.elementDefinitions.items[item.element.elementDefinitionId].systemId as Domain.Performance.HierarchyItemElementType;
            const statusFieldDefinitionIds: string[] = [Domain.SystemFieldDefinitions.Performance.GoalStatus, Domain.SystemFieldDefinitions.Performance.AchievementStatus, Domain.SystemFieldDefinitions.Performance.ActivityStatus];
            const statusFieldDefinition = props.elementDefinitions.items[item.element.elementDefinitionId].fields.find(item => statusFieldDefinitionIds.indexOf(item.systemId) >= 0);
            const progressValue = statusFieldDefinition?.optionItems.find(option => option.id === item.element.fields[statusFieldDefinition.id])?.name;

            return { ...elementItem, progress: progressValue, policyId: '' };
        });
        const elementItems = data.map((item: HierarchyItemElement) => findPolicyIdOfPerformanceItem(item, data));
        const policies = elementItems.filter(hierarchyItem => hierarchyItem.type === Domain.SystemElementDefinitions.Performance.Policy);
        setPolicyFilters(() => {
            const policyFilters = policies.map(policy => ({
                id: policy.id,
                value: false,
                label: policy.name
            }));
            return policyFilters;
        });
        setHierarchyItemElements(elementItems);
    }, [hierarchyItems, props.elementDefinitions]);

    useEffect(() => {
        //const searchEmpty = !search;
        const noTypeSelected = !typeFilters.find(filter => filter.value);
        const noProgressSelected = !progressFilters.find(filter => filter.value);
        const noPolicySelected = !policyFilters.find(filter => filter.value);

        if (noTypeSelected && noProgressSelected && noPolicySelected) {
            setFilteredHierarchyItemElements([]);
            return;
        }

        const filteredElements = hierarchyItemsNoPolicy
            .filter(hierarchyItem => noProgressSelected || progressFilters.find(filter => filter.value && (filter.label === hierarchyItem.progress || (!hierarchyItem.progress && filter.label === 'Anders'))))
            .filter(hierarchyItem => noTypeSelected || typeFilters.find(filter => filter.value && filter.id === hierarchyItem.type))
            .filter(hierarchyItem => noPolicySelected || policyFilters.find(filter => filter.value && filter.id === hierarchyItem.policyId));

        setFilteredHierarchyItemElements(filteredElements);
    }, [typeFilters, progressFilters, policyFilters, hierarchyItemsNoPolicy])

    if (props.measureMoments.status === AjaxRequestStatus.NotSet) {
        props.fetchMeasureMoments();
        return null;
    }

    if (props.users.status === AjaxRequestStatus.NotSet) {
        props.fetchUsers();
        return null;
    }

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Performance]);
        return null;
    }

    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Monitor</Heading2>
                </PageTitle>
                <PanelGrid>
                    <Styled.CustomSection look="white" grid={true} colSpan={2} rowSpan={3} widget={true}>
                        <Heading3>Filters</Heading3>
                        <Styled.CustomHeading4>Momenten</Styled.CustomHeading4>
                        <SelectElement<Domain.Shared.MomentItem>
                            id='monitor-select-moment'
                            optionItems={measureMoments.values}
                            value={measureMoments.selectedMeasureMoment}
                            displayExpr='label'
                            clearable={false}
                            customSingleValue={(item) => <TextBox stylingMode='outlined' value={item?.label || measureMoments.selectedMeasureMoment?.label} />}
                            editorSettings={{
                                disabled: false,
                                onChange: (selectedItem) => {
                                    setMeasureMoments((prev) => ({
                                        ...prev,
                                        selectedMeasureMoment: selectedItem ? {
                                            value: selectedItem.value,
                                            label: selectedItem.label,
                                            closed: selectedItem.closed
                                        } : null
                                    }))
                                }
                            }}
                        />

                        <Styled.CustomHeading4>Actuele status</Styled.CustomHeading4>
                        <Styled.CustomMultiSelectList
                            onChange={(filter, value) => {
                                const index = progressFilters.findIndex(progressFilter => filter.id === progressFilter.id);
                                progressFilters[index] = { ...progressFilters[index], value: value };
                                const newState = [...progressFilters];
                                setProgressFilters(newState);
                            }}
                            listItems={progressFilters}
                            previewMax={{ height: 0, width: 0 }}
                            withBorder={true}
                        />
                        <Styled.CustomHeading4>Type</Styled.CustomHeading4>
                        <Styled.CustomMultiSelectList
                            onChange={(filter, value) => {
                                const index = typeFilters.findIndex(typeFilter => typeFilter.id === filter.id);
                                typeFilters[index] = { ...typeFilters[index], value: value };
                                const newState = [...typeFilters];
                                setTypeFilters(newState);
                            }}
                            listItems={typeFilters}
                            previewMax={{ height: 0, width: 0 }}
                            withBorder={true}
                        />
                        <Styled.CustomHeading4>Relatie naar beleid</Styled.CustomHeading4>
                        <Styled.CustomMultiSelectList
                            onChange={(filter, value) => {
                                const index = policyFilters.findIndex(policyFilter => policyFilter.id === filter.id);
                                policyFilters[index] = { ...policyFilters[index], value: value };
                                const newState = [...policyFilters];
                                setPolicyFilters(newState);
                            }}
                            listItems={policyFilters}
                            previewMax={{ height: 0, width: 0 }}
                            withBorder={true}
                        />
                    </Styled.CustomSection>
                    <Styled.CustomSection look="white" grid={true} colSpan={4} rowSpan={2} widget={true}>
                        <LsGrid
                            dataSource={filteredHierarchyItemElements}
                            columns={availableColumns}
                            enableColumnChooser={false}
                            searching={true}
                            showRowLines={true}
                        />
                    </Styled.CustomSection>
                </PanelGrid>
            </WrapperContent>
        </Wrapper>);
};

const mapStateToProps = (state: State) => {
    return {
        elementDefinitions: state.elementdefinitions[ActionSource.Performance],
        modules: state.modules[ActionSource.Performance],
        users: state.users,
        measureMoments: {
            items: state.measuremoments.items,
            status: state.measuremoments.status,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Performance, data: { moduleId: module?.id } }));
        },
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Performance, data: {} }));
        },
        fetchUsers: () => {
            dispatch(UsersActionCreator.set());
        },
        fetchMeasureMoments: () => {
            dispatch(MeasureMomentsActionCreator.set());
        },
    };
};

const getColumnConfiguration = (): GridColumn<HierarchyItemElement>[] => {
    return [
        {
            name: 'progress',
            title: ' ',
            allowSorting: false,
            width: '10%',
            renderCustom: (item: { data: HierarchyItemElement }) => IconHelper.getPerformanceProgressIcon(item.data.progress || 'Anders', 20)
        },
        {
            name: 'name',
            title: 'Naam'
        },
    ];
};

const progressFilterOptions = [
    {
        label: 'Anders',
        value: false,
        id: 'Anders-id'
    },
    {
        label: 'Op koers',
        value: false,
        id: 'Op-koers-id'
    },
    {
        label: 'Bijsturing',
        value: false,
        id: 'Bijsturing-id'
    },
    {
        label: '(Deels) Niet haalbaar',
        value: false,
        id: 'Deels-Niet-haalbaar-id'
    }
];

const Component = connect(mapStateToProps, mapDispatchToProps)(Monitor);
export { Component as index };