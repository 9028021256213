import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents the Templates data accessor.
 */
export class Templates {

    /**
     * Gets the content of a page template.
     * 
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageTemplateId Defines the unique identifier of the page template.
     */
    public static getContent = async (publicationId: string, pageTemplateId: string): Promise<AxiosResponse<{ layers: Domain.Publisher.Layer[] }>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<{ layers: Domain.Publisher.Layer[] }>(`/api/publication/${publicationId}/pagetemplate/${pageTemplateId}/content`, config);
    };

    /**
     * Create a new page template.
     * 
     * @param publicationId Defines the unique identifier of a publication.
     * @param templateElement Defines a template element.
     */
    public static createPageTemplate = async (publicationId: string, templateElement: Domain.Publisher.Element): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<string>(`/api/publication/${publicationId}/pagetemplate`, { pageTemplateElement: templateElement }, config);
    };


    /**
     * Update the specified page template.
     * 
     * @param publicationId Defines the unique identifier of a publication.
     * @param pageTemplateId Defines the unique identifier of a page template.
     * @param pageTemplateId Defines the fields to update.
     */
    public static updatePageTemplate = async (publicationId: string, pageTemplateId: string, fields: Record<string, string>): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`api/publication/${publicationId}/pagetemplate/${pageTemplateId}`, { fields }, config);
    };

    /**
     * Update the content of a page template.
     * 
     * @param publicationId Defines the unique identifier of a publication.
     * @param templateElement Defines a template element.
     * @param operations Defines the list of operations for the page.
     */
    public static savePageTemplate = async (publicationId: string, templateId: string, operations: Domain.Publisher.Operation[]): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`/api/publication/${publicationId}/pagetemplate/${templateId}/content`, {
            operations: operations,
        }, config);
    };

    /**
     * Deletes the specified page template.
     * @param publicationId Defines the unique identifier of a publication.
     * @param pageTemplateId Defines the unique identifier of a page template.
     */
    public static deletePageTemplate = async (publicationId: string, pageTemplateId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.delete<void>(`api/publication/${publicationId}/pagetemplate/${pageTemplateId}`, config);
    };

}
