import React, { useState, useEffect, useMemo, useContext } from 'react';
import _, { Dictionary } from 'lodash';
import { FieldsHelper, JsonUtils } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { CreateCustomDataSource, ILsColumnProps, LsAccordionDs } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { DataSourceControlsUtils, PublicationContext } from '../../../../../../helpers';
import Styled from './index.styled';
import SharedStyled from '../../SharedControlElements/index.styled';
import { getIconSize } from '../../SharedControlElements/helper';

type Props = {
    element: Domain.Publisher.ElementNode,
    selectedElementId: string,
    elementList: Dictionary<Domain.Publisher.Element>,
    publicationElement: Domain.Publisher.PublicationElement,
    getElementDefinition: (systemElementId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onLoadAttachment: (ElementId: string) => Promise<Blob>,
    hasErrorInSettings?: (elementId: string, hasError: boolean, message?: string, keepError?: boolean) => void,
    readonly: boolean,
    icons?: Record<string, Domain.Shared.SvgIcon>,
}

type DataSourceElement = Domain.Publisher.DataSourceElement;

const mockedData: { data: any[], fields: ILsColumnProps[], isDefault: boolean } = {
    data: Array(5).fill('').map((_, i) => ({ ElementId: i, title: `Titel ${i + 1}` })),
    fields: [
        { dataField: 'ElementId', visible: true },
        { dataField: 'title', visible: true },
    ],
    isDefault: true,
};

/**
 * Represents a UI component that renders an Accordion control with datasource.
*/
const AccordionDsDataControl: React.FC<Props> = (props) => {
    const { element, elementList, onLoadAttachment, getElementDefinition } = props;
    const [dataSource, setDataSource] = useState<DataSourceElement>();
    const [dataSourceData, setDataSourceData] = useState<{ data: any[], fields: ILsColumnProps[], isDefault: boolean }>(mockedData);
    const pubContext = useContext(PublicationContext);

    const controlSettings = useMemo(() => {
        const accordionDsElement = elementList?.[element.elementId];
        if (!accordionDsElement) return;
        const definition = getElementDefinition(accordionDsElement.elementDefinitionSystemId, accordionDsElement.elementDefinitionId);
        const controlSettings = new Domain.Publisher.AccordionDsControl();
        FieldsHelper.mapObject<Domain.Publisher.AccordionDsControl>(controlSettings, definition.fields, accordionDsElement.fields);
        return controlSettings;
    }, [elementList?.[element.elementId], getElementDefinition]);

    useEffect(() => {
        const loadDataSourceAsync = async () => {
            if (controlSettings?.dataSourceId) {
                const dsElement = await pubContext.loadDataSourceElement(controlSettings.dataSourceId)
                setDataSource(dsElement);
            } else {
                setDataSource(undefined);
            }
        };
        loadDataSourceAsync();
    }, [controlSettings?.dataSourceId]);

    useEffect(() => {
        props.hasErrorInSettings?.(element.elementId, false);
        if (!dataSource?.id || !dataSource?.dataFileId || !dataSource?.schemaFileId || dataSource?.failedRefresh) {
            if (dataSource?.failedRefresh) props.hasErrorInSettings?.(element.elementId, true, 'Fout opgetreden bij het verversen.', true);
            setDataSourceData(mockedData);
        } else {
            const loadBlobsAsync = async () => {
                try {
                    const responses = await Promise.all([
                        onLoadAttachment(dataSource.dataFileId),
                        onLoadAttachment(dataSource.schemaFileId)
                    ]);
                    const blobs = await Promise.all([
                        responses[0].text(),
                        responses[1].text()
                    ]);
                    const data = JSON.parse(blobs[0]);
                    const columns = JSON.parse(blobs[1]);
                    const fields = DataSourceControlsUtils.mapsDataSourceColumnFields(
                        columns,
                        AppSettingsService.getAppSettings().Publisher.Controls.DataTable.NumberOfVisibleColumns
                    );
                    setDataSourceData({
                        data,
                        fields,
                        isDefault: false
                    });
                } catch (e) {
                    props.hasErrorInSettings?.(element.elementId, true, 'Databron fout.');
                }
            };
            loadBlobsAsync();
        }
    }, [element.elementId, dataSource?.id, dataSource?.dataFileId, dataSource?.schemaFileId, onLoadAttachment]);

    const columns = dataSourceData?.isDefault ? dataSourceData?.fields : JsonUtils.toJson(controlSettings?.columnSettings, []);
    const filter = dataSourceData?.isDefault ? [] : DataSourceControlsUtils.getFilter(controlSettings.filter);
    const headerField = dataSourceData?.isDefault ? 'title' : controlSettings.headerField;
    const sortExpression = dataSourceData?.isDefault ? [] : DataSourceControlsUtils.extractSortExpressionFromColumns(columns);

    const customSource = useMemo(() =>
        CreateCustomDataSource(dataSourceData?.data, columns, pagingData, filter, sortExpression)
        , [dataSourceData?.data, columns, pagingData, filter, sortExpression]);

    const iconSize = getIconSize(+props.publicationElement.bodyFontSize);

    return (<>
        <SharedStyled.Title h3FontSize={props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}>{controlSettings.title}</SharedStyled.Title>
        <Styled.AccordionWrapper
            primaryColor={props.publicationElement.primaryColor}
            primaryTextColor={props.publicationElement.primaryContrastColor}
            textColor={props.publicationElement.bodyFontColor}
            textFontSize={props.publicationElement.bodyFontSize}
            editMode={false}
        >
            <LsAccordionDs
                dataSource={customSource}
                icons={props.icons}
                collapsible={true}
                multiple={true}
                columns={columns}
                headerField={headerField}
                iconSize={iconSize}
            />
        </Styled.AccordionWrapper>
    </>
    );
}

const pagingData = { enabled: false, currentPage: 0, pageSize: AppSettingsService.getAppSettings().Publisher.Controls.DataTable.PageSize, totalCount: 0 };

export default React.memo(AccordionDsDataControl, (prevProps, nextProps) => {
    return _.isEqual(prevProps.elementList[prevProps.element.elementId], nextProps.elementList[nextProps.element.elementId])
        && prevProps.readonly === nextProps.readonly && prevProps.icons === nextProps.icons;
});
