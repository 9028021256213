import React from 'react';
import { palette } from '@liasincontrol/ui-basics';
import Styled from './index.styled';

/**
 * Represent a type of doughnut gradient chart.
 */
type Props = {
    percentage: number,
    title: string,
    reverseGradient?: boolean
};

/**
 * Represents a UI component that renders a doughnut chart with a gradient.
 */
export const DoughnutGradient: React.FC<Props> = ({percentage, title, reverseGradient = false}) => {
    // Calculate the dash offset based on the percentage.
    const offset = percentage <= 100 ? ((1570 * (100 - percentage)) / 100) : 0;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="200" width="200" viewBox="0 0 600 600">
            <defs>
                <linearGradient id="Gradient1" gradientTransform="rotate(90)">
                    <stop offset="0%" stopColor={Styled.paletteGradient.darkRed}/>
                    <stop offset="50%" stopColor={Styled.paletteGradient.red}/>
                    <stop offset="90%" stopColor={Styled.paletteGradient.orange}/>
                </linearGradient>
                <linearGradient id="Gradient2" gradientTransform="rotate(90)">
                    <stop offset="0%" stopColor={Styled.paletteGradient.darkGreen}/>
                    <stop offset="50%" stopColor={Styled.paletteGradient.green}/>
                    <stop offset="90%" stopColor={Styled.paletteGradient.orange}/>
                </linearGradient>
                <pattern id="Pattern" x="0" y="0" width="600" height="600" patternUnits="userSpaceOnUse">
                    <g transform="rotate(0, 300, 300)">
                        <rect shapeRendering="crispEdges" x="0" y="0" width="300" height="600" fill="url(#Gradient1)"/>
                        <rect shapeRendering="crispEdges"  x="300" y="0" width="300" height="600" fill="url(#Gradient2)"/>
                    </g>
                </pattern>
                <pattern id="PatternReverse" x="0" y="0" width="600" height="600" patternUnits="userSpaceOnUse">
                    <g transform="rotate(0, 300, 300)">
                        <rect shapeRendering="crispEdges" x="300" y="0" width="300" height="600" fill="url(#Gradient1)"/>
                        <rect shapeRendering="crispEdges"  x="0" y="0" width="300" height="600" fill="url(#Gradient2)"/>
                    </g>
                </pattern>
            </defs>
            <Styled.CircleBG
                d='M 300 58 A 250 250 0 1 1 299.99 58'
            />

            {reverseGradient &&  percentage < 100 && (
                <Styled.CirclePath offset={offset} 
                    style={{stroke: 'url(#PatternReverse)'}} 
                    fill='transparent'
                    strokeDasharray="1570 1570"
                    strokeDashoffset="1570"
                    strokeWidth='60' 
                    d='M 300 58 A 250 250 0 1 1 299.99 58' 
                />
            )}
            {!reverseGradient &&  percentage < 100 && (
                <Styled.CirclePath offset={offset} 
                    style={{stroke: 'url(#Pattern)'}} 
                    fill='transparent'
                    strokeDasharray="1570 1570"
                    strokeDashoffset="1570"
                    strokeWidth='60' 
                    d='M 300 58 A 250 250 0 1 1 299.99 58' 
                />
            )}
            {reverseGradient &&  percentage >= 100 && (
                <Styled.CirclePathToEndColorReverse offset={offset} 
                    style={{stroke: 'url(#PatternReverse)'}} 
                    fill='transparent'
                    strokeDasharray="1570 1570"
                    strokeDashoffset="1570"
                    strokeWidth='60' 
                    d='M 300 58 A 250 250 0 1 1 299.99 58' 
                />
            )}
            {!reverseGradient &&  percentage >= 100 && (
                <Styled.CirclePathToEndColor offset={offset} 
                    style={{stroke: 'url(#Pattern)'}} 
                    fill='transparent'
                    strokeDasharray="1570 1570"
                    strokeDashoffset="1570"
                    strokeWidth='60' 
                    d='M 300 58 A 250 250 0 1 1 299.99 58' 
                />
            )}
            
            <text fill={palette.grey2} x="300" y="300" fontFamily="Nunito" fontSize="80" textAnchor="middle" width={100} >{title}</text>
            <text fill={palette.grey2} x="300" y="400" fontFamily="Nunito" fontSize="60" textAnchor="middle">{percentage}%</text>
        </svg>
    );
};
