import React from "react";
import { LsPivotTable } from "@liasincontrol/ui-devextreme";
import * as Domain from '@liasincontrol/domain';
import { Section } from '@liasincontrol/ui-basics';

export type BudgetDevGridProps = {
    data: Domain.Finance.BudgetDevelopment[];
}

export const BudgetDevelopmentGrid: React.FC<BudgetDevGridProps> = (props: BudgetDevGridProps) => {

    return (<Section look='white'>
        <LsPivotTable
            data={props.data}
            fields={fields}
            showColumnGrandTotals={false}
            showColumnTotals={false}
        />
    </Section>);
};

const fields = [
    {
        dataField: 'budgetYear',
        dataType: 'number',
        area: 'column',
    },
    {
        dataField: 'reserveOperation',
        dataType: 'string',
        area: 'column',
    },
    {
        dataField: 'costBenefit',
        dataType: 'string',
        area: 'column',
    },
    {
        dataField: 'measureMomentName',
        area: 'row',
        sortOrder: 'asc',
        customizeText: (e: { value: string }) => {
            //override the displayed name only, at this point the data is already sorted by the grid
            const lastindex: number = e.value.lastIndexOf('___');
            return e.value.substring(lastindex + 3);
        },
    },
    {
        dataField: 'amount',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: '#0,###.##'
    },
];