import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents a data accessor of budget overview data.
 */
export class BudgetOverview {

    /**
     * Gets the possible budget overview filter values.
     */
    public static getFilters = async (): Promise<AxiosResponse<Domain.Finance.BudgetOverviewFilterTree>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Finance.BudgetOverviewFilterTree >(`/api/finance/budget/filters`, config);
    };

    /**
     * Gets the Budget overview results.
     * 
     * @param baseYear Year on which to filter the budget results.
     * @param structureName Structure name on which to filter the budget results.
     * @param elementName Structure Element name on which to filter the budget results.
     * @param workflowStatus List of workflow statuses to filter the budget results.
     */
     public static get = async (baseYear: number, structureName: string, elementName: string, workflowStatus: string[]): Promise<AxiosResponse<Domain.Finance.BudgetOverviewData[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        
        return HttpClient.post<Domain.Finance.BudgetOverviewData[]>(`/api/finance/budget/overview`, {
            baseYear: baseYear,
            structure: structureName,
            structureElement: elementName,
            workflowStatus: workflowStatus
        }, config);
    };

    /**
     * Gets the Budget details results.
     * 
     * @param baseYear Year on which to filter the budget results.
     * @param structureName Structure name on which to filter the budget results.
     * @param elementName Structure Element name on which to filter the budget results.
     * @param workflowStatus List of workflow statuses to filter the budget results.
     */
     public static getDetails = async (baseYear: number, structureName: string, elementName: string, workflowStatus: string[]): Promise<AxiosResponse<Domain.Finance.BudgetDetailData[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<Domain.Finance.BudgetDetailData[]>(`/api/finance/budget/detail`, {
            baseYear: baseYear,
            structure: structureName,
            structureElement: elementName,
            workflowStatus: workflowStatus
        }, config);
    };
}

export default BudgetOverview;