//import { Config } from "./ConfigType";

interface Config {
    API: {
        PUB_API_URL: string,
    },
    IDENTITY: {
        IDENTITY_URL: string,
        ACCOUNT_URL: string
    },
    PLUGINS: {
        USERBACK_KEY: string,
        USERBACK_SRC: string
    }
}

/**
 * Service that handles retrieving the application-wide configuration object from the server.
 */
abstract class ConfigService {

    private static cachedConfig: Config;

    /**
     * Retrieves and caches the application configuration object.
     */
    public static async getConfigurations(): Promise<Config> {
        if (ConfigService.cachedConfig) {
            return Promise.resolve(ConfigService.cachedConfig);
        }

        return ConfigService.retrieveConfigurations()
            .then((config) => {
                ConfigService.cachedConfig = config;
                return config;
            });
    }

    /**
     * Retrieves the configuration settings for the entire application.
     */
    private static async retrieveConfigurations(): Promise<Config> {
        // while in development mode, there's no server to call to. Rather, serve the available environment variables
        if (process.env.NODE_ENV === 'development') {
            console.log('Using client-side environment settings only');
            return Promise.resolve(ConfigService.getDevConfiguration());
        }

        return fetch('/config', {
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json();
        }).catch((err) => {
            console.error(err);
            return undefined;
        });
    }

    /**
     * Default development configuration, that can be set using frontend environment variables.
     */
    private static getDevConfiguration = (): Config => {
        return {
            API: {
                PUB_API_URL: process.env.REACT_APP_PUB_API_URL || '',
            },
            IDENTITY: {
                IDENTITY_URL: process.env.REACT_APP_IDENTITY_URL || '',
                ACCOUNT_URL: process.env.REACT_APP_ACCOUNT_URL || ''
            },
            PLUGINS: {
                USERBACK_KEY: process.env.REACT_APP_USERBACK_KEY || '',
                USERBACK_SRC: process.env.REACT_APP_USERBACK_SRC || '',
            }
        };
    };
}

export { ConfigService, Config };