import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { SortDescriptor } from 'devextreme/data';
import _ from 'lodash';
import he from 'he';
import { ILsColumnProps, ILsPagingProps } from '../..';

/**
 * Create a general datasource used by datasource based components, with devextreme.
 */
export const CreateCustomDataSource = (data: Array<{}>, columns: ILsColumnProps[], paging?: ILsPagingProps, filterValue?: string | Array<any> | Function, sortExpression?: SortDescriptor<ILsColumnProps> | SortDescriptor<ILsColumnProps>[]): DataSource => {
   
    const source = new DataSource({
        store: new CustomStore({
            load: () => processDataSourceData(data, columns),
            loadMode: 'raw',

        }),
    });

    if (filterValue && filterValue !== null && !_.isEmpty(filterValue)) {
        source.filter(filterValue);
    }

    if (paging) {
        source.paginate(true);
        source.pageSize(paging.pageSize);
        source.requireTotalCount(true);
    }

    if (sortExpression && sortExpression !== null && !_.isEmpty(sortExpression)) {
        source.sort(sortExpression);
    }

    return source;
}

function decodeHtmlEntities(input) {
    return he.decode(input);
}

export const processDataSourceData = (data: Array<{}>, columns: ILsColumnProps[]) => {
    const result = data.map((item) => {
        let resultItem = item;
        for (const fieldName in item) {
            const columnSettings = getColumnSettings(fieldName, columns);

            if (columnSettings && columnSettings.dataType === 'string') {
                resultItem = {
                    ...resultItem,
                    [fieldName]: decodeHtmlEntities(item[fieldName]),
                };
            }
        }
        return resultItem;
    });

    return result;
};

function getColumnSettings(fieldName: string, columns?: ILsColumnProps[]): ILsColumnProps {
    let columnSettings = null;
    columnSettings = columns?.find?.(cs => cs.dataField === fieldName);
    if (!columnSettings && fieldName.endsWith("Name")) {
        const baseFieldName = fieldName.substring(0, fieldName.length - 4);
        columnSettings = columns?.find?.(cs => cs.dataField === baseFieldName);
    }
    return columnSettings;
}

export const encodeSpecialChars = (text: string): string => {
    // eslint-disable-next-line no-control-regex
    return text.replace(/[^\u0000-\u007F]/g, (char: string) => {
        return `&#${char.charCodeAt(0)};`;
    });
};


export const specialCharactersNormalizedArray = (value) => {
    if (Array.isArray(value)) {
        return value.map((element) => {
            return (typeof element === 'string' ? specialCharactersNormalizedArray(element) : element);
        });
    } else {
        return encodeSpecialChars(value);
    }
};

export const decodeHtmlEntitiesInObject = (obj) => {
    for (const prop in obj) {
        if (typeof obj[prop] === 'string') {
            obj[prop] = he.decode(obj[prop]);
        }
    }
    return obj;
}