import React, { useEffect, useRef } from 'react';
import FilterBuilder from 'devextreme-react/filter-builder';
import { dxElement } from 'devextreme/core/element';
import dxFilterBuilder, { dxFilterBuilderField } from 'devextreme/ui/filter_builder';

export type FilterValueType = string | Array<any> | Function;

export type LsFilterBuilderField = {
    caption?: string,
    dataField?: string,
    dataType?: 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime',
    filterOperations?: Array<'=' | '<>' | '<' | '<=' | '>' | '>=' | 'contains' | 'endswith' | 'isblank' | 'isnotblank' | 'notcontains' | 'startswith' | 'between' | string>,
};

export type LsFilterBuilderOnChangeHandler = {
    component?: dxFilterBuilder,
    element?: dxElement,
    model?: any,
    value?: FilterValueType,
    previousValue?: FilterValueType,
};
export interface ILsFilterBuilderProps {
    /**
     * Defines the id of the field builder element.
     */
    readonly id: string;
    /**
     * Available columns for sort
     */
    readonly fields: dxFilterBuilderField[] | undefined;
    /**
     * the current filter value
     */
    readonly value: FilterValueType;
    /**
     * the current filter value
     */
    readonly disabled?: boolean;
    /**
     * the current locale(localization) identifier.
     */
    readonly locale?: string;
    /**
     * on filter value change event
     */
    readonly onValueChanged: (object: LsFilterBuilderOnChangeHandler) => void;
}

export const LsFilterBuilder: React.FC<ILsFilterBuilderProps> = (props) => {
    const builder = useRef(null);

    useEffect(() => {
        try {
            builder.current.instance.option('value', props.value);
        } catch (error) {
            // TODO: as mentioned in item #10937 this behavior will be replaced by another PBI
            builder.current.instance.option('value', []);
        }
    }, [props.value]);

    return (
        <FilterBuilder
            ref={builder}
            id={props.id}
            fields={props.fields}
            onValueChanged={props.onValueChanged}
            disabled={props.disabled}
        >
        </FilterBuilder>
    );
};
