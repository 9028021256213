import { AxiosResponse } from 'axios';
import * as Domain from '@liasincontrol/domain';
import HttpClient from '@liasincontrol/http-service';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents the sitemap data accessor.
 */
export class SiteMap {
    /**
     * Add child page to sitemap under a specified parent.
     * 
     * @param publicationId Defines the publication id.
     * @param parentId Defines the parent page.
     * @param pageName Defines the added page name.
     */
    public static addPage = async (publicationId: string, parentId: string, pageName: string): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<string>(`/api/publication/${publicationId}/sitemap/page`, {
            name: pageName,
            parentId: parentId
        }, config);
    };

    /**
     * Delete a page from sitemap.
     * 
     * @param publicationId Defines the publication id.
     * @param pageId Defines the pageId to be removed.
     */
    public static deletePage = async (publicationId: string, pageId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.delete<void>(`/api/publication/${publicationId}/sitemap/page/${pageId}`, config);
    };

    /**
     * Deepclone a page from sitemap.
     * 
     * @param publicationId Defines the publication id.
     * @param pageId Defines the source pageId.
     */
    public static clonePage = async (publicationId: string, pageId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<void>(`/api/publication/${publicationId}/sitemap/page/${pageId}/clone`, {}, config);
    };

    /**
     * Update a page name from sitemap.
     * 
     * @param publicationId Defines the publication id.
     * @param pageId Defines the source page id.
     * @param name Defines the source page name.
     * @param iconId Defines the icon id
     */
    public static updatePage = async (publicationId: string, pageId: string, pageChanges: any): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const { name, iconId, focalPoint, description, image, attachments } = pageChanges;

        return HttpClient.put<void>(`/api/publication/${publicationId}/sitemap/page/${pageId}`, {
            name,
            iconId,
            focalPoint,
            description,
            image,
            attachments
        }, config);
    };

    /**
     * Gets the list of available publishing profiles.
     */
    public static getAvailablePublishProfiles = async (): Promise<AxiosResponse<Domain.Publisher.PublishProfile[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.PublishProfile[]>('/api/profile', config);
    };

    /**
     * Gets the list of selected publishing profiles for a page.
     * 
     * @param pageId Defines the source page id.
     */
    public static getSelectedPagePublishProfiles = async (pageId: string): Promise<AxiosResponse<Domain.Publisher.PublishProfile[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.PublishProfile[]>(`/api/profile/page/${pageId}`, config);
    };

    /**
     * Gets the list of selected control publishing profiles for a page.
     * 
     * @param pageId Defines the source page id.
     */
    public static getSelectedPageControlPublishProfiles = async (pageId: string): Promise<AxiosResponse<Domain.Publisher.ControlPublishProfile[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.ControlPublishProfile[]>(`/api/profile/page/${pageId}/control`, config);
    };

    /**
     * Set the publishing profile items for a page.
     * 
     * @param publishProfileIds Defines the publishing profile ids.
     * @param publicationId Defines the publication id.
     * @param pageId Defines the page id.
     */
    public static setPageToPublish = async (publishProfileIds: string[], publicationId: string, pageId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<void>(`/api/publication/${publicationId}/page/${pageId}`, {
            publishProfileIds: publishProfileIds,
        }, config);
    };

    /**
     * Set the publishing profile items for a control.
     * 
     * @param publishProfileIds Defines the publishing profile ids.
     * @param publicationId Defines the publication id.
     * @param pageId Defines the page id.
     * @param controlId Defines the control id.
     */
    public static setControlToPublish = async (publishProfileIds: string[], publicationId: string, pageId: string, controlId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<void>(`/api/publication/${publicationId}/page/${pageId}/control/${controlId}`, {
            publishProfileIds: publishProfileIds,
        }, config);
    };

    /**
     * Set a pagetemplate to a page from sitemap.
     * 
     * @param publicationId Defines the publication id.
     * @param pageIds Defines the source pageIds.
     * @param pageTemplateId Defines the template id.
     */
    public static setPageTemplate = async (publicationId: string, pageIds: string[], pageTemplateId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`/api/publication/${publicationId}/sitemap/page/pagetemplate`, {
            pageIds: pageIds,
            pageTemplateId: pageTemplateId,
        }, config);
    };

    /**
     * Move a page before another in sitemap.
     * 
     * @param publicationId Defines the publication id.
     * @param otherPageId Defines the pageId follows the placed page.
     * @param thisPageId Defines the pageId.
     */
    public static movePageBefore = async (publicationId: string, otherPageId: string, thisPageId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<void>(`/api/publication/${publicationId}/sitemap/move-page-before`, {
            otherPageId: otherPageId,
            thisPageId: thisPageId,
        }, config);
    };

    /**
     * Move a page at the and of the list.
     * 
     * @param publicationId Defines the publication id.
     * @param newParentPageId Defines the placed page's parent.
     * @param pageId Defines the pageId.
     */
    public static movePageTo = async (publicationId: string, newParentPageId: string, pageId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<void>(`/api/publication/${publicationId}/sitemap/move-page-to`, {
            newParentPageId: newParentPageId,
            pageId: pageId
        }, config);
    };
}

export default SiteMap;