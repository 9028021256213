import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import _, { Dictionary } from 'lodash';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import * as Domain from '@liasincontrol/domain';
import { SystemElementDefinitions, SystemModuleDefinitions } from '@liasincontrol/domain';
import { UserIdentity } from '@liasincontrol/auth-service';
import { Actions, ActionType, UserRightsService } from '@liasincontrol/userrights-service';
import { State, ActionSource, ModulesActionCreator, ElementDefinitionsActionCreator, AttachmentsActionCreator, AjaxRequestStatus, MeasureMomentsActionCreator, SvgIconActionCreator } from '@liasincontrol/redux-service';
import { Publisher as DataAccess, Performance as PerformanceDataAccess, Studio as StudioDataAccess } from '@liasincontrol/data-service';
import { ApiErrorReportingHelper, DefinitionsHelper, FieldsHelper, OperationsHelper, AttachmentsHelper, ValidationUtils } from '@liasincontrol/core-service';
import { Device, Bar, Button, ErrorOverlay, IDataItemProps } from '@liasincontrol/ui-basics';
import { IndicatorSize, LoadIndicator } from '@liasincontrol/ui-devextreme';
import Renderer from '../../../../../components/Renderer';
import { SitemapEditor } from './SitemapEditor';
import { TreeUtils } from '../../../../../helpers';
import { BlankPublication } from './BlankPublication';
import { usePublicationSettings } from '../../../../../helpers/PublicationContext';
import Helper from '../../../../_shared/PublicationItem/PublicationInformation/index.helper';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

export type SitemapNode = Domain.Publisher.SitemapNode & { markDelete: boolean, markCloned: boolean };

/**
 * Represents a list of changes on a sitemap node.
 */
export type HierarchyChanges = {
    editing?: Dictionary<string>,
    cloning?: string[],
    deleting?: string[],
};

/**
 * Represents a UI component that renders the sitemap page of a publication.
 */
const Index: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const { id, pageid } = useParams<{ id: string, pageid: string }>();
    const { pathname } = useLocation();
    const [publicationContent, setPublicationContent] = useState<{
        root: Domain.Publisher.ElementNode,
        elements: Dictionary<Domain.Publisher.Element>,
        pageId: string,
        pageDesignId: string
    }>();
    const [isBlank, setBlank] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const [sitemap, setSitemap] = useState<Domain.Publisher.Sitemap>();
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [childTemplates, setChildTemplates] = useState<Record<string, { id: string, name: string }>>({});
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [editorError, setEditorError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const publication = usePublicationSettings();
    const publicationElementDefinition = useMemo(() => Object.values(props.elementdefinitions[ActionSource.Publication]?.items).find((definition) => definition.systemId === SystemElementDefinitions.Pub.Publication), [props.elementdefinitions[ActionSource.Publication]]);
    const [pageDesigns, setPageDesigns] = useState<Domain.Publisher.PageDesign[]>([]);
    const [publicationElement, setPublicationElement] = useState<Domain.Publisher.PublicationElement>();
    const [templateName, setTemplateName] = useState<string>('');
    const isHomepage = useMemo(() => !pageid || publication?.rootPageId === pageid, [pageid, publication?.rootPageId]);
    const [selectedPublishProfiles, setSelectedPublishProfiles] = useState<IDataItemProps<string>[]>();
    const [availablePublishProfiles, setAvailablePublishProfiles] = useState<IDataItemProps<string>[]>();
    const [hasAvailableTemplates, setHasAvailableTemplates] = useState<boolean>(false);
    const [hierarchies, setHierarchies] = useState<Domain.Shared.HierarchyMap>(
        {
            [ActionSource.Performance]: {},
            [ActionSource.Studio]: {},
        });

    const sitemapNode = useMemo(() => {
        if (isHomepage) {
            return sitemap?.node;
        }
        if (!sitemap) {
            return null;
        }
        return TreeUtils.findChildren(sitemap!.node, pageid);
    }, [isHomepage, sitemap?.node, pageid]);

    const pageImageAttachment = useMemo(() => {
        if (!sitemapNode?.image || !props.attachments || !props.attachments[sitemapNode.image]) {
            return {};
        }
        const attachmentFile = {
            [sitemapNode.image]: AttachmentsHelper.mapFileToAttachment(props.attachments[sitemapNode.image], sitemapNode.image),
        };
        return attachmentFile;
    }, [props.attachments, sitemapNode?.image]);

    useEffect(() => {
        if (!publication || publication.publication.elementId !== id)
            return;
        if (!pageid) {
            navigate(`${pathname}/${publication.rootPageId}`);
        }
    }, [publication, id, pathname, navigate]);

    //TODO: To be removed after adding Page Design column; story 10663
    useEffect(() => {
        if (publicationElementDefinition && publication) {
            const settings = new Domain.Publisher.PublicationElement();
            FieldsHelper.mapObject<Domain.Publisher.PublicationElement>(settings, publicationElementDefinition.fields, publication.publication.fields);
            setPublicationElement(settings);

            if (settings.isClosed) {
                setError({ message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed] });
            }
        }
    }, [publication, publicationElementDefinition]);

    //TODO: To be removed after adding Page Design column; story 10663
    useEffect(() => {
        if (publicationElement) {
            DataAccess.SiteDesigns.getPageDesigns(publicationElement.siteDesignId).then((response) => {
                setPageDesigns(response.data);
            });
        }
    }, [publicationElement]);

    useEffect(() => {
        if (!publication) {
            return;
        }

        DataAccess.Publications.getPageSitemap(publication.publication.elementId, publication.rootPageId, 4)
            .then((response) => {
                setSitemap(response.data);
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
            });

        DataAccess.Publications.getTemplates(publication.publication.elementId, '?$count=true').then((response) => {
            setHasAvailableTemplates(!!(response.data['@count']));
        });
    }, [publication, lastRefresh]);

    useEffect(() => {
        if (!publication || !sitemap) {
            return;
        }

        const templateElementDefinition = DefinitionsHelper.findElementDefinition(props.elementdefinitions[ActionSource.Publication].items, SystemElementDefinitions.Pub.PageTemplate);
        if (sitemapNode && !ValidationUtils.isEmptyGuid(sitemapNode.pageTemplateId) && templateElementDefinition?.fields) {

            DataAccess.Publications.getTemplateDetails(publication.publication.elementId, sitemapNode.pageTemplateId).then((response) => {
                const templateElement = new Domain.Publisher.TemplateElement();
                // fill the settings with the data from the list of element fields based on field definitions:
                FieldsHelper.mapObject<Domain.Publisher.TemplateElement>(templateElement, templateElementDefinition.fields, response.data.fields);

                setTemplateName(templateElement.name);
            });
        }
    }, [publication, sitemap, pageid, props.elementdefinitions[ActionSource.Publication]]);

    useEffect(() => {
        if (!pageid || !publication) {
            return;
        }

        DataAccess.Publications.getPageOperations(publication.publication.elementId, pageid)
            .then((response) => {

                const rootElement = OperationsHelper.getElementStructure(response.data.layers);
                const elements = OperationsHelper.getElementList(response.data.layers);
                const pageDesignId = response.data.layers.find((layer) => layer.kind === Domain.Publisher.LayerKind.PageDesign).originId;

                setPublicationContent({
                    root: rootElement,
                    elements: elements,
                    pageId: pageid,
                    pageDesignId: pageDesignId
                });
                setBlank(false);
            }).catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true);

                if (errorInfo?.details?.type && errorInfo?.details?.type.includes(Domain.Shared.ApiKnownErrorTypes.PageNotLinkedToTemplate)) {
                    setError(undefined);
                    setBlank(true);
                } else {
                    setError(errorInfo);
                }
            });
    }, [pageid, lastRefresh, publication]);

    // Before redering, apply the patches over the publication content:
    const patchedElements = useMemo(() => {
        return publicationContent
            ? Helper.getPatchedElements(publicationContent.elements, props.patches[publicationContent.pageId])
            : {};
    }, [publicationContent, props.patches]);

    useEffect(() => {
        DataAccess.SiteMap.getAvailablePublishProfiles().then((response) => {
            setAvailablePublishProfiles(response.data.map((item) => ({ value: item.profileId, label: item.profileName })));
        });

    }, []);

    useEffect(() => {
        if (!pageid) {
            return;
        }

        DataAccess.SiteMap.getSelectedPagePublishProfiles(pageid).then((response) => {
            setSelectedPublishProfiles(response.data.map((item) => ({ value: item.profileId, label: item.profileName })));
        });
    }, [pageid]);

    // initialize the dependent data...
    if (props.measureMoments.status === AjaxRequestStatus.NotSet) {
        props.fetchMeasureMoments();
        return null;
    }

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementdefinitions[ActionSource.Publication] || props.elementdefinitions[ActionSource.Publication].status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(ActionSource.Publication, props.modules[SystemModuleDefinitions.Publisher]);
        return null;
    }

    if (!props.elementdefinitions[ActionSource.Performance] || props.elementdefinitions[ActionSource.Performance].status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(ActionSource.Performance, props.modules[SystemModuleDefinitions.Performance]);
        return null;
    }

    if (!props.icons || props.icons.status === AjaxRequestStatus.NotSet) {
        props.fetchIcons();
    }

    if (!sitemap) {
        return null;
    }

    const mappedElementDefinition = Object.keys(props.elementdefinitions).reduce((collection, key) => {
        return {
            ...collection,
            [key]: props.elementdefinitions[key].items
        };
    }, {});

    /**
     * Represents an event handler that triggers the loading process of an attachment.
     * 
     * @param attachmentId Defines the attachment id.
     */
    const loadAttachment = async (attachmentId: string, attachmentNames?: Record<string, string>): Promise<Blob> => {
        return AttachmentsHelper.loadExistingAttachment(attachmentId, props.attachments, props.setAttachment, attachmentNames);
    };

    /**
     * Represents an event handler that triggers the removing process of an attachment.
     * 
     * @param attachmentId Defines the attachment id.
     */
    const removeAttachment = (attachmentId: string) => {
        props.removeAttachment(attachmentId);
    };

    /**
     * Represents an event handler that triggers the uploading process of an attachment.
     * 
     * @param file Defines the file.
     * @param abortSignal Defines the cancel token.
     */
    const uploadAttachment = async (file: File, abortSignal: AbortSignal): Promise<string> =>
        AttachmentsHelper.uploadAttachment(file, abortSignal, (attachmentId, attachment) => {
            props.setAttachment(attachmentId, attachment);
        });


    /**
     * Represents an event handler that triggers the loading process of a complete performance hierarchy.
     * 
     * @param measureMomentId Defines the measure moment id.
     */
    const loadPerformanceHierarchy = (measureMomentId: string): void => {
        if (measureMomentId && !Object.keys(hierarchies[ActionSource.Performance]).includes(measureMomentId)) {
            PerformanceDataAccess.HierarchyDataAccessor.get(measureMomentId, true, true).then((result) => {
                setHierarchies((prevState) => ({
                    ...prevState,
                    [ActionSource.Performance]: {
                        [measureMomentId]: {
                            performance: result.data.hierarchy,
                        }
                    }
                }));
            });
        }
    }

    /**
     * Represents an event handler that triggers the loading process of a complete studio hierarchy.
     * 
     * @param hierarchyId Defines the hierarchy id.
     * @param measureMomentId Defines the measure moment id.
     * @param hierarchyDefinitionId Defines the hierarchy definition id.
     */
    const loadStudioHierarchy = (hierarchyId: string, hierarchyDefinitionId: string, measureMomentId: string): void => {
        if (hierarchyId && hierarchyDefinitionId && measureMomentId) {
            StudioDataAccess.HierarchyDataAccessor.get(hierarchyId, true, true)
                .then((result) => {
                    setHierarchies((prevState) => ({
                        ...prevState,
                        [ActionSource.Studio]: {
                            [measureMomentId]: {
                                ...prevState[measureMomentId],
                                [hierarchyDefinitionId]: result.data.hierarchy,
                            }
                        }
                    }) as Domain.Shared.HierarchyMap);
                });
        }
    }
    /**
     * Represents an event handler that creates a new page.
     * 
     * @param ownerId Defines the parent id.
     * @param name Defines the new pages name.
     */
    const createPage = (ownerId: string, name: string): void => {
        if (editorError) setEditorError(undefined);

        DataAccess.SiteMap.addPage(publication.publication.elementId, ownerId, name)
            .then(async (response) => {
                // Set the child's templates if exists.
                const newPageId = response.data;

                if (childTemplates[ownerId]) {
                    await DataAccess.SiteMap.setPageTemplate(publication.publication.elementId, [newPageId], childTemplates[ownerId].id);
                }

                setLastRefresh(Date.now());
            }).catch((err) => {
                setEditorError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err));
            });
    };

    /**
     * Represents an event handler that changes template for selected pages.
     * 
     * @param newTemplateId Defines the selected template id.
     * @param pageIds Defines the list of pages changes applied.
     */
    const templateChange = (newTemplateId: string, newTemplateName: string, pageIds: string[], parentId?: string): void => {
        if (editorError) setEditorError(undefined);

        if (parentId) {
            const templates = _.cloneDeep(childTemplates);
            templates[parentId] = { id: newTemplateId, name: newTemplateName };
            setChildTemplates(templates);
        }

        DataAccess.SiteMap.setPageTemplate(publication.publication.elementId, pageIds, newTemplateId)
            .then(() => {
                setLastRefresh(Date.now());
            }).catch((err) => {
                setEditorError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
            });
    };

    /**
     * Represents an event handler that changes the assigned publishing profiles on a page.
     * 
     * @param publishProfiles Defines a collection of publishing profiles to be assigned on a page.
     */
    const pagePublishChanged = (publishProfiles: IDataItemProps<string>[]): void => {
        DataAccess.SiteMap.setPageToPublish(publishProfiles.map(pp => pp.value.toString()), id, pageid)
            .then(() => {
                setSelectedPublishProfiles(publishProfiles);
            }).catch((err) => {
                setEditorError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
            });
    };

    const findIconId = (pageId: string): string | undefined => {
        const node = [sitemap.node, ...sitemap.node.children]
            .flatten(item => item.children)
            .find(child => child.elementId === pageId);

        return node?.iconId;
    }

    /**
     * Represents an event handler that triggers the parallel saving process for each change detected.
     * This is fired when user hit the "Save" button above the hierarchy.
     * 
     * @param changes Defines the changings by change type.
     */
    const changeHierarchy = (changes: HierarchyChanges): void => {
        if (editorError) setEditorError(undefined);

        let allActions = (Object.keys(changes.editing)?.length || 0) + (changes.deleting?.length || 0) + (changes.cloning?.length || 0);

        // Serial execution for requested changes
        Object.keys(changes.editing).reduce((p, editedPage) => {
            return p.then(() => {
                const pageChanges = { name: changes.editing[editedPage], iconId: findIconId(editedPage) };

                return DataAccess.SiteMap.updatePage(publication.publication.elementId, editedPage, pageChanges).then(() => {
                    allActions--;
                });
            });
        }, Promise.resolve()).then(() => {
            if (allActions === 0) setLastRefresh(Date.now());
        }).catch((err) => {
            setEditorError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
        });

        changes.cloning.reduce((p, clonedPage) => {
            return p.then(() => {
                return DataAccess.SiteMap.clonePage(publication.publication.elementId, clonedPage).then(() => {
                    allActions--;
                });
            });
        }, Promise.resolve()).then(() => {
            if (allActions === 0) setLastRefresh(Date.now());
        }).catch((err) => {
            setEditorError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
        });

        changes.deleting.reduce((p, deletedPage) => {
            return p.then(() => {
                return DataAccess.SiteMap.deletePage(publication.publication.elementId, deletedPage).then(() => {
                    allActions--;
                });
            });
        }, Promise.resolve()).then(() => {
            if (allActions === 0) setLastRefresh(Date.now());
        }).catch((err) => {
            setEditorError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
        });


    };

    /**
     * Represents an event handler that triggers the replacing process under the selected node.
     * 
     * @param ownerId Defines the owner of the hierarchy.
     * @param movedPageId Defines the pageId replaced.
     * @param beforePageId Defines the pageId before the moved page will be placed.
     */
    const move = (ownerId: string, movedPageId: string, beforePageId?: string): void => {
        if (editorError) setEditorError(undefined);

        const action = beforePageId ? DataAccess.SiteMap.movePageBefore(publication.publication.elementId, beforePageId, movedPageId) : DataAccess.SiteMap.movePageTo(publication.publication.elementId, ownerId, movedPageId);

        action
            .then(() => {
                setLastRefresh(Date.now());
            }).catch((err) => {
                setEditorError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
            });
    };

    const getPublicationRendererElement = (patchedElements: Dictionary<Domain.Publisher.Element>, readonly: boolean): JSX.Element => {
        if (!sitemap || !publication) {
            return null;
        }

        return (
            <Renderer
                readonly={readonly}
                elementdefinitions={mappedElementDefinition}
                sitemap={sitemap}
                pageDesignId={publicationContent.pageDesignId}
                publication={publication}
                publicationRoot={publicationContent.root}
                publicationElements={patchedElements}
                hierarchies={hierarchies}
                onLoadAttachment={loadAttachment}
                loadPerformanceHierarchy={loadPerformanceHierarchy}
                loadStudioHierarchy={loadStudioHierarchy}
                icons={props.icons.items}
            />
        );
    };

    const canPerformAction = (action: Actions, actionType: ActionType): boolean => {
        return UserRightsService.getInstance().canPerformAction(props.userIdentity, action, actionType);
    };

    return (
        <>
            <Bar look="toolbar">
                <Bar start>
                    {!error && (
                        <Button
                            id={`btn-edit-${publication?.publication?.elementId}`}
                            disabled={!UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Sitemap, ActionType.Update)}
                            btnbase="primarybuttons"
                            btntype="medium_transparent"
                            onClick={() => setEditing(true)} >
                            Bewerken
                        </Button>
                    )}
                </Bar>
            </Bar>
            <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                <Device device="tablet">
                    {isBlank
                        ? <BlankPublication
                            publicationId={publication.publication.elementId}
                            pageDesigns={pageDesigns}
                            hasAvailableTemplates={hasAvailableTemplates}
                            elementDefinitions={props.elementdefinitions[ActionSource.Publication]?.items}
                            hasBackButton={isHomepage}
                            onChangeTemplate={(templateId: string, templateName: string) => templateChange(templateId, templateName, [pageid])}
                            onBack={() => navigate(-1)}
                        />
                        : (publicationContent && publicationContent.pageId === pageid)
                            ? getPublicationRendererElement(patchedElements, true)
                            : <LoadIndicator variant={IndicatorSize.extralarge} align='center' />
                    }
                </Device>
            </ErrorOverlay>

            {editing && publication && sitemap && !error ?
                <SitemapEditor
                    elementdefinitions={props.elementdefinitions[ActionSource.Publication].items}
                    publicationId={publication.publication.elementId}
                    sitemapNode={sitemapNode}
                    selectedTemplateName={templateName}
                    selectedPublishProfiles={selectedPublishProfiles}
                    availablePublishProfiles={availablePublishProfiles}
                    rootNode={sitemap.node}
                    childTemplate={childTemplates[sitemapNode?.elementId]}
                    error={editorError}
                    attachment={pageImageAttachment}
                    onCreate={createPage}
                    onChangeRequest={changeHierarchy}
                    onChangeTemplate={templateChange}
                    onChangePagePublish={pagePublishChanged}
                    onLoadAttachment={loadAttachment}
                    onUploadAttachment={uploadAttachment}
                    onRemoveAttachment={removeAttachment}
                    onNavigate={(toPageId: string) => {
                        navigate(`/publisher/admin/publication/${id}/sitemap/${toPageId}`);
                    }}
                    onMove={move}
                    onSaveChanges={() => setEditing(false)}
                    onCancelChanges={() => setEditing(false)}
                    onResetError={() => setEditorError(undefined)}
                    canPerformAction={canPerformAction}
                    pageDesigns={pageDesigns}
                    hasAvailableTemplates={hasAvailableTemplates}
                    onRefresh={() => setLastRefresh(Date.now())}
                    icons={props.icons.items}
                >
                    {isBlank
                        ? <BlankPublication
                            publicationId={publication.publication.elementId}
                            pageDesigns={pageDesigns}
                            elementDefinitions={props.elementdefinitions[ActionSource.Publication]?.items}
                            hasBackButton={isHomepage}
                            onChangeTemplate={(templateId: string, templateName: string) => templateChange(templateId, templateName, [pageid])}
                            onBack={() => navigate(-1)}
                            hasAvailableTemplates={hasAvailableTemplates}
                        />
                        : (publicationContent && publicationContent.pageId === pageid)
                            ? getPublicationRendererElement(patchedElements, true)
                            : !error ? <LoadIndicator variant={IndicatorSize.extralarge} align='center' /> : null
                    }
                </SitemapEditor> : null}
        </>
    );
};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Publication],
        elementdefinitions: state.elementdefinitions,
        patches: state.publisher.patches,
        attachments: state.attachments,
        measureMoments: {
            items: state.measuremoments.items,
            status: state.measuremoments.status,
        },
        icons: state.icons
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchElementDefinitions: (actionSource: ActionSource, module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: actionSource, data: { moduleId: module?.id } }));
        },
        setAttachment: (attachmentId: string, attachment: File) => {
            dispatch(AttachmentsActionCreator.set({ source: ActionSource.Publication, data: { attachmentId, attachment } }))
        },
        removeAttachment: (attachmentId: string) => {
            dispatch(AttachmentsActionCreator.remove({ source: ActionSource.Publication, data: { attachmentId } }));
        },
        fetchMeasureMoments: () => {
            dispatch(MeasureMomentsActionCreator.set());
        },
        fetchIcons: () => {
            dispatch(SvgIconActionCreator.set());
        }
    };

};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
