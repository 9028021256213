/**
 * Represents a row of Budget Detail data with the structure elements unfolded. It is required to send the Budget Detail data into the Grid
 */
 class FlattenedBudgetDetailsData {
    code1: string;

    name1: string;

    kind1: string;

    code2: string;
    
    name2: string;
    
    kind2: string;

    code3: string;

    name3: string;

    kind3: string;

    code4: string;

    name4: string;

    kind4: string;

    code5: string;

    name5: string;

    kind5: string;

    code6: string;

    name6: string;

    kind6: string;

    code7: string;

    name7: string;

    kind7: string;

    accountClass: string;
    
    pastYear2Realisation: number;
    
    pastYear1Budget: number;
    
    currentActualBudget: number;
    
    currentRealization: number;
    
    currentObligations: number;
    
    currentBudgetRemaining: number;
    
    currentBudgetExhausted: number;
    
    futureYear1Budget: number;
    
    futureYear2Budget: number;
    
    futureYear3Budget: number;
    
    futureYear4Budget: number;
}

export { FlattenedBudgetDetailsData };